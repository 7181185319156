<template>
	<div class="dashboard dashboard-user">
		<!-- Header -->
		<div class="flex items-center justify-between mb-8 w-full">
			<div></div>
			<div>
				<!-- <button type="button" class="twn-button" @click="ExportReport" :disabled="loadingReport || isLoading || true">
					<span v-if="!loadingReport">
						Export CSV
					</span>
					<span v-else>
						Chargement...
					</span>
				</button> -->
			</div>
		</div>

		<!-- Dashboard -->
		<div>
			<!-- Mailing and activity data -->
			<CategoryBlock title="Statistiques du stagiaire">
				<div class="dashboard-kpis">
					<KPIBlock
						:isLoading="isLoading"
						title="Mail d'invitation"
						:date="trackingData && trackingData.mailingDashboardData.accessDate"
					/>

					<KPIBlock
						:isLoading="isLoading"
						title="Première connexion sur l’application"
						:date="trackingData && trackingData.activityDashboardData.firstActiveDate"
					/>

					<KPIBlock
						:isLoading="isLoading"
						title="Dernière connexion sur l’application"
						:date="trackingData && trackingData.activityDashboardData.lastActiveDate"
					/>
				</div>

				<div class="dashboard-kpis">
					<KPIBlock
						:isLoading="isLoading"
						title="Nombre de connexions"
						:number="trackingData && trackingData.activityDashboardData.activeCount"
					/>

					<KPIBlock
						:isLoading="isLoading"
						title="Durée totale de connexion"
						:time="trackingData && trackingData.activityDashboardData.activeTime.sum"
					/>

					<KPIBlock
						:isLoading="isLoading"
						title="Durée moyenne de connexion"
						:time="trackingData && trackingData.activityDashboardData.activeTime.average"
					/>
				</div>
			</CategoryBlock>

			<!-- Progress and times -->
			<CategoryBlock
				v-for="group in (trackingData && trackingData.user && trackingData.user.groupsById || [])"
				:key="group.id"
				title="Statistiques de promotion"
				:subtitle="`${group.title} - ${formatDate(group.startDate)} - ${formatDate(group.endDate)}`"
			>
				<div class="dashboard-kpis">
					<KPIBlock
						:isLoading="isLoading"
						title="Date d’ouverture"
						:date="group.startDate"
					/>

					<KPIBlock
						:isLoading="isLoading"
						title="Première connexion"
						:date="group.firstActiveDate"
					/>

					<KPIBlock
						:isLoading="isLoading"
						title="Dernière connexion"
						:date="group.lastActiveDate"
					/>
				</div>

				<div class="dashboard-kpis">
					<KPIBlock
						:isLoading="isLoading"
						title="Nombre de connexions"
						:number="group.activeCount"
					/>

					<KPIBlock
						:isLoading="isLoading"
						title="Durée totale de connexion"
						:time="group.activeTime.sum"
					/>

					<KPIBlock
						:isLoading="isLoading"
						title="Durée moyenne de connexion"
						:time="group.activeTime.average"
					/>
				</div>

				<div class="dashboard-kpis">
					<KPIBlock
						:isLoading="isLoading"
						title="Rappels faits / Ouverts"
						:raw="`${group.sequenceDoneCount}/${group.sequenceCount}`"
					/>

					<KPIBlock
						:isLoading="isLoading"
						title="Taux de réussite (moyenne des meilleurs scores)"
						:percentage="group.successRate"
					/>
				</div>

				<!-- Sequence KPIs table -->
				<SubCategoryBlock class="dashboard-user-sequences">
					<table>
						<thead>
							<tr>
								<th>Rappel</th>
								<th>J+</th>
								<th>Status</th>
								<th>Tentatives</th>
								<th>Session moy.</th>
								<th>1er score</th>
								<th>Meill. score</th>
							</tr>
						</thead>
						<tbody>
							<tr
								v-if="isLoading || !group.sequencesData"
								class="animate-dashboard-pulse"
							>
								<td>-</td>
								<td>-</td>
								<td>-</td>
								<td>-</td>
								<td>-</td>
								<td>-</td>
								<td>-</td>
								<td>-</td>
							</tr>
							<tr
								v-for="(sequence, id, index) in group.sequencesData"
								:key="sequence.id"
							>
								<td :title="sequence.id">
									{{ index + 1 }}
								</td>
								<td>
									{{ sequence.offsetDays }}
								</td>
								<td :class="sequence.status.class">
									{{ sequence.status.text }}
								</td>
								<td>
									{{ sequence.sessionCount }}
								</td>
								<td>
									{{ formatTime(sequence.sessionAverageTime) }}
								</td>
								<td>
									{{ formatPercentage(sequence.sessionFirstAverageSuccessRate) }}
								</td>
								<td>
									{{ formatPercentage(sequence.sessionAverageSuccessRate) }}
								</td>
							</tr>
						</tbody>
					</table>
				</SubCategoryBlock>
			</CategoryBlock>
		</div>
	</div>
</template>

<script>
	import moment from 'moment'

	import trackingReport from '@/utils/trackingReport'
	
	import trackingTemplate from "@/constants/userTrackingReportTemplate"
	
	import CategoryBlock from "./block/Category"
	import SubCategoryBlock from "./block/SubCategory"
	import KPIBlock from "./block/KPI"

	const oneHour = (1 * 60 * 60 * 1000)

	function leadingZero(number, extended = false) {
		return (number < 10 && (extended || number > 0) ? '0' + number : number)
	}

	function getTimeParts(time) {
		let remainingTime = (time / oneHour)
		const hours = Math.floor(remainingTime)

		remainingTime = (remainingTime - hours) * 60
		const minutes = Math.floor(remainingTime)

		remainingTime = (remainingTime - minutes) * 60
		const seconds = Math.round(remainingTime)

		return {
			hours,
			minutes,
			seconds,
		}
	}
	
	export default {
		name: 'UserDashboard',
		props: {
			user: {
				type: Object,
				required: true,
			},
		},
		components: {
			CategoryBlock,
			SubCategoryBlock,
			KPIBlock,
		},
		data() {
			return {
				currentPage: 0,
				isLoading: false,
				trackingData: null,
				loadingReport: false,
				loadingPDF: false,
			}
		},
		computed: {
			isSuperAdmin() {
				const userInfo = this.$store.state.Auth.userInfo

				return (userInfo && userInfo.role == 'superadmin')
			},
		},
		watch: {
			user: {
				handler() {
					this.loadData()
				},
				immediate: true,
			},
		},
		methods: {
			formatDate(dt) {
				return moment(new Date(dt)).locale('fr').format('L')
			},
			formatTime(time) {
				if (time != null && time > 0) {
					const timeParts = getTimeParts(time)

					return (timeParts.hours > 0 ? timeParts.hours + 'h ' : '') + (timeParts.minutes > 0 || timeParts.seconds <= 0 ? leadingZero(timeParts.minutes) + ' min ' : '') + (timeParts.hours <= 0 && timeParts.seconds > 0 ? leadingZero(timeParts.seconds) + 's' :  '')
				}

				return '-'
			},
			formatPercentage(percentage) {
				if (percentage != null && percentage != undefined && percentage > -1) {
					const value = (percentage * 100)

					if (value > 0 && value < 1) {
						return value.toFixed(2) + ' %'
					}

					return Math.round(value) + ' %'
				}

				return '-'
			},
			async loadData(force = false) {
				if (!this.user || !this.user.id) {
					return
				}

				if (this.isLoading) {
					return
				}

				this.isLoading = true

				// Load tracking data
				const payload = {
					id: this.user.id,
				}

				this.trackingData = await trackingReport.loadData(this.$store, trackingTemplate, payload, force)

				this.isLoading = false
			},
			async ExportReport() {
				this.loadingReport = true

				try {
					// Set contextual data
					const payload = {
						id: this.user.id,
					}

					// Create report file
					await trackingReport.export(`TB_${[this.user.firstName, this.user.name].join(' ')}`, this.$store, trackingTemplate, payload)
				} catch (err) {
					// eslint-disable-next-line no-console
					console.error(err)
				}

				this.loadingReport = false
			},
		}
	}
</script>

<style lang="scss">
	.dashboard-user {
		.dashboard-category {
			@apply relative;

			& > p:nth-child(1) {
				@apply mb-4;
			}

			& > p:nth-child(2) {
				@apply absolute w-full text-center left-0;
				top: 1.75rem;
			}
		}

		.dashboard-subcategory-logs {
			& > p:first-of-type, & > img:first-of-type {
				@apply inline-block mb-1 mr-2;
				height: 1.75rem;
			}

			& > table:first-of-type {
				tr > th:first-child {
					min-width: 8rem;
				}
			}
		}

		table {
            @apply w-full;

            tr {
                th, td {
                    @apply pl-5;
                }
            }

            th {
                @apply bg-dashboard-blue-dark text-white pt-3 pb-2;
            }

            td {
                @apply pt-2 pb-1;
                border-bottom: 1px solid #EEEEEE;

                &:first-child {
                    @apply font-principal-bold;
                }
            }
        }

		.dashboard-subcategory-scores {
			@apply flex flex-wrap;

			& > div:nth-child(odd) {
				@apply pr-4;
			}

			& > div:nth-child(even) {
				@apply pl-4;
			}

			& > div > div {
				@apply relative;

				& > span {
					@apply absolute text-xs text-white;
					top: 2px;
				}
			}

			progress {
				@apply rounded-full overflow-hidden border-2 border-dashboard-black appearance-none;
				height: 20px;
			}

			::-webkit-progress-bar {
				background-color: #EEEEEE;
			}
			::-moz-progress-bar {
				background-color: #EEEEEE;
			}
			::-webkit-progress-value {
				@apply bg-dashboard-green;
			}
		}
	}
</style>