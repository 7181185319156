<template>
  <div class="mt-4">
    <p class="text-2xl mb-4">{{ promotion.users.length }} stagiaires inscrits</p>
    <table>
      <thead>
        <tr>
          <th>Nom</th>
          <th>Prénom</th>
          <th>Email</th>
          <th>Rôle</th>
          <th>Statut</th>
          <th>Rappels faits</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="intern in promotion.users"
          :key="intern.id"
        >
          <template v-if="interns[intern.id]">
            <td>
              <router-link :to="{name: 'users-edit', params: {itemID: intern.id}}">
                {{interns[intern.id].first_name}}
              </router-link>
            </td>
            <td>
              <router-link :to="{name: 'users-edit', params: {itemID: intern.id}}">
                {{interns[intern.id].name}}
              </router-link>
            </td>
            <td>
              <router-link :to="{name: 'users-edit', params: {itemID: intern.id}}">
                {{interns[intern.id].email}}
              </router-link>
            </td>
            <td>
              <router-link :to="{name: 'users-edit', params: {itemID: intern.id}}">
                {{interns[intern.id].roleText}}
              </router-link>
            </td>
            <td>
              <router-link :to="{name: 'users-edit', params: {itemID: intern.id}}">
                {{interns[intern.id].statusText}}
              </router-link>
            </td>
            <td>
              <router-link :to="{name: 'users-edit', params: {itemID: intern.id}}">
                {{interns[intern.id].sequenceDoneText}}
              </router-link>
            </td>
          </template>

          <td
            v-if="canAddIntern && userInfo.role !== 'instructor'"
            class="flex justify-center text-xl font-bold items-center cursor-pointer col-span-1 pt-1"
            @click="onRemoveInternClick(intern)"
          >
            <div class="border border-gray-light rounded-full text-center w-8 h-8">-</div>
          </td>
        </tr>
      </tbody>
    </table>

    <div v-if="isAddingIntern" class="col-span-3">
      <v-select
      class="w-full"
      :options="internsList"
      :filterable="true"
      label="name"
      placeholder="Nom/Prénom de l'utilisateur"
      @input="onInternSelect"
      v-focus
      />
    </div>

    <div
      v-if="canAddIntern && userInfo.role !== 'instructor'"
      class="flex justify-center text-xl font-bold items-center cursor-pointer mt-8"
      @click="onAddInternClick"
    >
      <div class="shadow-twn rounded-full text-center w-8 h-8">+</div>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
  props: {
    promotion: {
      type: Object,
      required: true,
    },
    canAddIntern: {
      type: Boolean,
      default: true,
    },
    course: {
      type: Object,
    },
  },
  directives: {
    focus: {
      inserted: function (el) {
        el.focus()
        
        // todo: improve "is focusable" check
        if (el.tagName != 'input') {
          el.querySelector('input').focus()
        }
      },
    }
  },
  data() {
    return {
      isAddingIntern: false
    }
  },
  computed: {
    ...mapState('Utils', ['userList']),
    ...mapState('Auth', ['userInfo']),
    courseSequenceById() {
      if (!this.course || !this.course.modules)
        return []

      return this.course.modules.reduce((dict, module) => {
        module.sequences.forEach((sequence) => {
          dict[sequence.id] = true
        })

        return dict
      }, [])
    },
    internsList() {
      if (!this.userList)
        return []

      return this.userList.filter((user) => {
        return !this.promotion.users.some((intern) => (user.id == intern.id))
      }).map((user) => {
        return {
          id: user.id,
          name: ((user.first_name || '') + ' ' + (user.name || '')),
        }
      })
    },
    interns() {
      if (!this.userList)
        return {}

      return this.userList.reduce((dict, user) => {
        dict[user.id] = user

        switch (user.role) {
          case 'user':
            user.roleText = "Utilisateur"
            break
          case 'organisationadmin':
            user.roleText = "OfficeAdmin"
            break
          case 'superadmin':
            user.roleText = "SuperAdmin"
            break
          case 'afpols':
            user.roleText = "Gestionnaire"
            break
          case 'instructor':
            user.roleText = "Formateur"
            break
        }

        user.statusText = (user.logs_aggregate.aggregate.count > 0 ? 'actif' : 'inactif')

        const uniqSequenceDoneById = user.sequences_done.reduce((dict, log) => {
          if (this.courseSequenceById[log.data_key]) {
            dict[log.data_key] = true
          }
          return dict
        }, {})
        user.sequenceDoneText = Object.keys(uniqSequenceDoneById).length

        return dict
      }, {})
    },
  },
  methods: {
    onAddInternClick() {
      this.isAddingIntern = !this.isAddingIntern
    },
    onInternSelect(intern) {
      this.$emit('add-intern', intern)

      this.isAddingIntern = false
    },
    onRemoveInternClick(intern) {
      this.$emit('remove-intern', intern)
    },
  },
};
</script>

<style lang="scss" scoped>
  table {
    @apply w-full;

    tr {
      th {
        @apply pl-5;
      }
    }

    tbody tr {
      border-bottom: 1px solid #EEEEEE;
    }

    th {
      @apply bg-dashboard-blue-dark text-white pt-3 pb-2;
    }

    td {
      a {
        @apply block w-full h-10 pl-5 pt-2;
      }
    }
  }
</style>