<template>
  <div>
      <List
        listing="course"
        orderBy="name: asc"
        :previousPage="previousPage"
        :fields="fields"
        :where="whereClause"
        modify-button-text="Éditer le parcours"
        :with-delete-button="false"
        searchText="Rechercher un parcours"
      >
        <template v-slot:buttons>
            <div class="md:flex flex-row justify-around hidden md:w-1/3">
                <button type="button" class="twn-button danger whitespace-no-wrap overflow-hidden text-xs mr-2" @click="addCourse" v-if="userInfo.role !== 'instructor'">Ajouter un parcours</button>
            </div>
        </template>
      </List>
  </div>
</template>

<script>
import List from '@/components/List'
import { mapState } from 'vuex';
export default {
    components: {
        List,
    },
    props:{
        previousPage: {
            type: Number,
            required: false,
            default: null
        }
    },
    data(){
        return{
            fields: [
                {
                    key: 'modules[0].name',
                    label: 'ID',
                    sortable: true,
                    db: `modules {
                        id
                        name
                    }`
                },
                {
                    key:'name',
                    label: 'Nom',
                    sortable: true,
                },
                {
                    key:'instructors',
                    label: 'Formateurs',
                    sortable: true,
                    db: `instructors: course_instructors{
                            user {
                                name
                            }
                        }`,
                    nested: 'name'
                },
                {
                    key:'sequence_count',
                    label: 'Rappels',
                    sortable: true,
                    db: `sequence_count: modules {
                        sequences_aggregate {
                            aggregate {
                                count
                            }
                        }
                    }`,
                    nested: 'count'
                },
                {
                    key:'node_count',
                    label: 'Activités',
                    sortable: true,
                    db: `node_count: modules {
                        sequences {
                            scenarios {
                                scenario {
                                    nodes_aggregate {
                                        aggregate {
                                            count
                                        }
                                    }
                                }
                            }
                        }
                    }`,
                    formatter: (modules) => {
                        return (modules[0] && modules[0].sequences || []).reduce((count, sequence) => {
                            count += sequence.scenarios.reduce((nodeCount, scenarioData) => {
                                nodeCount += scenarioData.scenario.nodes_aggregate.aggregate.count
                                return nodeCount
                            }, 0)

                            return count
                        }, 0)
                    },
                },
                {
                    key:'nb_promotion',
                    label: 'Promos',
                    sortable: true,
                    db: `nb_promotion: promotions_aggregate{
                        aggregate{
                        count
                        }
                    }`,
                    nested: 'count'
                },
                {
                    key:'active_user_count',
                    label: 'Stag. act.',
                    sortable: true,
                    db: `active_user_count: promotions {
                        users_aggregate(where: {user: {logs_aggregate: {count: {predicate: {_gte: 1}, filter: {logType: {slug: {_neq: "mail"}}}}}}}) {
                            aggregate {
                                count
                            }
                        }
                    }`,
                    formatter: (promotions) => {
                        return (promotions || []).reduce((count, promotion) => {
                            count += promotion.users_aggregate.aggregate.count

                            return count
                        }, 0)
                    },
                },
            ],
            // filters: [
            //     {
            //         key:'type',
            //         label: 'Parcours'
            //     },
            // ]
        }
    },
    computed: {
        ...mapState('Auth', [ 'userInfo' ]),
        whereClause() {
            let where = null
            if (this.userInfo.role === 'instructor') where = `course_instructors: { instructor_id: { _eq: "${this.userInfo.id}" } }`
            return where
        }
        
    },
    methods:{
        addCourse(){
            this.$router.push({name: 'course-create'})
        }
    }
}
</script>

<style>

</style>