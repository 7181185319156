<template>
	<div class="dashboard-kpi shadow-light" :class="classes">
		<div v-if="help" class="dashboard-kpi-tooltip">
			<span :class="(Math.round(help.length / 30) <= 2 ? 'w-48' : 'w-56')">{{help}}</span>
			<p>?</p>
		</div>

		<p v-if="valueOnTop" :class="(valueColor ? 'text-' + valueColor : null)">
			{{ (formatedAnimatedValue === null ? formatedValue : formatedAnimatedValue) }}
		</p>
		
		<h1 v-if="title">{{title}}</h1>
		<h2 v-if="subtitle">{{subtitle}}</h2>

		<p v-if="!valueOnTop" :class="(valueColor ? 'text-' + valueColor : null)">
			{{ (formatedAnimatedValue === null ? formatedValue : formatedAnimatedValue) }}
		</p>
	</div>
</template>

<script>
	const oneHour = (1 * 60 * 60 * 1000)

	function leadingZero(number, extended = false) {
		return (number < 10 && (extended || number > 0) ? '0' + number : number)
	}

	function getTimeParts(time) {
		let remainingTime = (time / oneHour)
		const hours = Math.floor(remainingTime)

		remainingTime = (remainingTime - hours) * 60
		const minutes = Math.floor(remainingTime)

		remainingTime = (remainingTime - minutes) * 60
		const seconds = Math.round(remainingTime)

		return {
			hours,
			minutes,
			seconds,
		}
	}

	export default {
		name: 'KPIBlock',
		props: {
			isLoading: {
				type: Boolean,
				required: true
			},
			title: {
				type: String,
				required: true
			},
			subtitle: {
				type: String,
				default: null
			},
			help: {
				type: String,
				default: null
			},
			number: {
				type: Number,
				default: null
			},
			percentage: {
				type: Number,
				default: null
			},
			time: {
				type: Number,
				default: null
			},
			date: {
				type: Date,
				default: null
			},
			raw: {
				type: String,
				default: null
			},
			theme: {
				type: String,
				default: null
			},
			backgroundColor: {
				type: String,
				default: null
			},
			textColor: {
				type: String,
				default: null
			},
			valueColor: {
				type: String,
				default: null
			}
		},
		data() {
			return {
				animationInterval: null,
				animatedValue: null,
			}
		},
		computed: {
			classes() {
				let classes = []

				if (this.backgroundColor) {
					classes.push(this.backgroundColor)
					classes.push(this.textColor || 'text-white')
				} else if (this.textColor) {
					classes.push(this.textColor)
				}

				if (this.theme) {
					classes.push(this.theme)
				}

				if (this.isLoading) {
					classes.push('loading')
				}

				return classes.join(' ')
			},
			formatedValue() {
				if (this.raw !== null) {
					return this.raw
				}

				if (this.time != null) {
					const timeParts = getTimeParts(this.time)

					return (timeParts.hours > 0 ? timeParts.hours + 'h ' : '') + (timeParts.minutes > 0 || timeParts.seconds <= 0 ? leadingZero(timeParts.minutes) + 'm ' : '') + (timeParts.hours <= 0 && timeParts.seconds > 0 ? leadingZero(timeParts.seconds) + 's' :  '')
				} else if (this.date) {
					return [leadingZero(this.date.getDate()), leadingZero(this.date.getMonth() + 1), this.date.getFullYear()].join('/')
				} else if (this.percentage != null) {
					const value = (this.percentage * 100)

					if (value > 0 && value < 1) {
						return value.toFixed(2) + ' %'
					}

					return Math.round(value) + ' %'
				}

				return (this.number != null ? this.number : '-')
			},
			formatedAnimatedValue() {
				if (this.animatedValue === null) {
					return null
				}

				if (this.time != null) {
					const finalTimeParts = getTimeParts(this.time)
					const timeParts = getTimeParts(this.animatedValue)

					return (finalTimeParts.hours > 0 ? timeParts.hours + 'h ' : '') + (finalTimeParts.minutes > 0 || finalTimeParts.seconds <= 0 ? leadingZero(timeParts.minutes, true) + 'm ' : '') + (finalTimeParts.hours <= 0 && finalTimeParts.seconds > 0 ? leadingZero(timeParts.seconds, true) + 's' :  '')
				} else if (this.percentage != null) {
					if (this.animatedValue > 0 && this.animatedValue < 1) {
						return this.animatedValue.toFixed(2) + ' %'
					}

					return Math.round(this.animatedValue) + ' %'
				}

				return this.animatedValue
			},
			valueOnTop() {
				return (['wide-block'].indexOf(this.theme) < 0)
			},
		},
		watch: {
			number(value, oldValue = 0) {
				this.updateValueAnimated(value, oldValue)
			},
			percentage(value, oldValue = 0) {
				this.updateValueAnimated(value * 100, oldValue * 100, (1 + Math.random()))
			},
			time(value, oldValue) {
				this.updateValueAnimated(value, oldValue)
			},
		},
		methods: {
			updateValueAnimated(value, oldValue, initialIncrement = null) {
				if (value === null || value === false) {
					return
				}

				const delta = Math.abs(value - oldValue)

				let delay = (1000 / (delta || 1))
				let increment = (initialIncrement || 1)

				while (delay < 10) {
					increment += 1
					delay = (1000 / (delta / increment))
				}

				if (delay > 50) {
					delay = 50
				}

				let stopCondition = () => (this.animatedValue >= value)

				if (value < oldValue) {
					stopCondition = () => (this.animatedValue <= value)
				}

				this.animatedValue = oldValue
				this.animationInterval = setInterval(this.generateValueAnimation(stopCondition, (value > oldValue ? increment : increment * -1)), delay)
			},
			generateValueAnimation(stopCondition, increment) {
				return () => {
					if (stopCondition()) {
						clearInterval(this.animationInterval)
						this.animationInterval = null
						this.animatedValue = null
						return
					}

					this.animatedValue += increment
				}
			}
		},
	}
</script>

<style lang="scss">
	.dashboard-kpi {
		@apply relative bg-white text-center rounded-dashboard p-4;

		h1 {
			@apply uppercase text-sm;
		}

		h2 {
			@apply italic text-sm;
		}

		p {
			@apply text-3xl;
		}

		&.loading > p {
			@apply animate-dashboard-pulse;
		}

		&.wide-block {
			@apply text-left px-8;

			h1 {
				@apply font-principal-medium uppercase text-base;
			}

			p {
				@apply text-xl mt-2;
			}
		}

		&-tooltip {
			@apply absolute top-0 right-0 p-2 cursor-help;

			p {
				@apply w-4 h-4 bg-gray rounded-full text-xs text-dashboard-gray-lighter font-principal-medium;
			}

			span {
				@apply hidden;
			}

			&:hover span {
				@apply absolute block bg-gray rounded-lg text-xs text-white font-principal-medium bottom-8 right-4 py-2 px-4 mb-1 transform translate-x-1/2;

				&:after {
					@apply border border-gray;
					content: "";
					position: absolute;
					top: 100%;
					left: 50%;
					height: 0;
					width: 0;
					border-bottom-color: transparent;
					border-right-color: transparent;
					border-left-color: transparent;
					border-width: 0.5rem;
					margin-left: -0.5rem;
				}
			}
		}
	}
</style>