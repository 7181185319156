import gql from 'graphql-tag'

const GC_GET_PROMOTION_TYPE_LIST = gql`
query GetPromotionTypeList {
    promotion_type {
      id
      name
      description
      slug
    }
  }
`

const GC_GET_PROMOTION_LIST = gql`
query GetPromotionList {
    promotion {
      id
      title
      promotion_type_id
    }
  }
`

const GC_GET_PROMOTION_BY_ID = gql`
query PromotionByID($id: uuid!) {
  promotion_by_pk(id: $id) {
    id
    title
    start_date
    end_date
    isOpen
    course_id
    promotion_type_id
    salesforce_id
    sequences {
      sequence_id
      start_date
      end_date
      user_id
    }
    users {
      user_id
    }
    contentLogs(order_by: {created_at: desc}) {
      created_at
      user {
        name
        first_name
      }
    }
  }
}
`

const GC_ADD_PROMOTION_ONE = gql`
mutation AddPromotion($title: String!, $start_date: date!, $end_date: date!, $course_id: uuid!, $promotion_type_id: uuid!, $sequences: [promotion_sequence_insert_input!]!, $users: [promotion_user_insert_input!]!, $isOpen: Boolean) {
  insert_promotion_one(object: {
    title: $title,
    start_date: $start_date,
    end_date: $end_date,
    course_id: $course_id,
    isOpen: $isOpen,
    promotion_type_id: $promotion_type_id,
    sequences: {
      data: $sequences
    },
    users: {
      data: $users
    }
  }) {
    id
  }
}
`

/*
  1) Update promotion base data
  2) TODO : Delete sequences that are no longer associated with this promotion
  3) TODO : Insert newly associated sequences or update existing ones
  4) Delete users that are no longer associated with this promotion
  5) Insert newly associated users
*/
const GC_UPDATE_PROMOTION_BY_ID = gql`
mutation UpdatePromotion(
		$id: uuid!,
		$title: String!,
		$start_date: date!,
		$end_date: date!,
		$isOpen: Boolean!,
		$course_id: uuid!,
		$promotion_type_id: uuid!,
		### $sequences_ids: [uuid!]!,
		$sequences: [promotion_sequence_insert_input!]!,
		$users_ids: [uuid!]!,
		$users: [promotion_user_insert_input!]!,
	) {
  update_promotion_by_pk(pk_columns: { id: $id },
  _set: {
    title: $title,
    start_date: $start_date,
    end_date: $end_date,
    isOpen: $isOpen,
    course_id: $course_id,
    promotion_type_id: $promotion_type_id,
  }) {
    id
  }

  delete_promotion_sequence(where: {
    ### sequence_id: {_nin: $sequences_ids},
    promotion_id: {_eq: $id}
  }) {
    affected_rows
  }
  insert_promotion_sequence(
  	objects: $sequences,
  	on_conflict: {
  		constraint: promotion_sequence_promotion_id_sequence_id_user_id_key,
  		update_columns: [start_date, end_date]
  	}
  ) {
    affected_rows
  }

  delete_promotion_user(where: {
    user_id: {_nin: $users_ids},
    promotion_id: {_eq: $id}
  }) {
    affected_rows
  }
  insert_promotion_user(
  	objects: $users,
  	on_conflict: {
  		constraint: promotion_user_pkey,
  		update_columns: []
  	}
  ) {
    affected_rows
  }
}
`

const GC_DELETE_PROMOTION_BY_ID = gql`
mutation DeletePromotion($id: uuid!) {
  delete_promotion_by_pk(id: $id) {
    id
    title
  }
}
`

export {
  GC_GET_PROMOTION_TYPE_LIST,
  GC_GET_PROMOTION_LIST,
  GC_GET_PROMOTION_BY_ID,
  GC_ADD_PROMOTION_ONE,
  GC_UPDATE_PROMOTION_BY_ID,
  GC_DELETE_PROMOTION_BY_ID,
}