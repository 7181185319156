<template>
  <div>
    <List
      listing="promotion"
      :fields="fields"
      order-by="end_date: desc, start_date: asc"
      :where="whereClause"
      search-text="Rechercher une promotion"
      :pageSize="pageSize"
      edit-route-name="promotions-edit"
    >
      <template v-slot:buttons>
        <div class="md:flex flex-row justify-around hidden md:w-1/3">
          <button
            v-if="userInfo.role !== 'instructor'"
            type="button"
            class="twn-button whitespace-no-wrap overflow-hidden text-xs mr-2"
            @click="onAddPromotionClick"
          >
            Ajouter une nouvelle promotion
          </button>
        </div>
      </template>
    </List>
  </div>
</template>

<script>
import List from "@/components/List";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    List,
  },
  props: {
    pageSize:{
      type: Number,
      default: 30,
      required: false
    }
  },
  data: () => ({
    fields: [
      {
        key: "title",
        label: "Nom",
        sortable: true,
      },
      {
        key: "cursus_name",
        label: "Produit",
        sortable: true,
      },
      {
        key: 'course',
        db: `course {
          name
        }`,
        nested: 'name',
        label: "Parcours",
        sortable: true,
      },
      {
        key: 'type',
        db: `type {
          name
        }`,
        nested: 'name',
        label: 'Type',
        sortable: true,
      },
      {
        key: 'start_date',
        label: "Début",
        sortable: true,
        formatter: (_value, _key, item) => {
          const start = (item.start_date ? item.start_date.split('-').reverse().join('/') : '')

          return start 
        }
      },
      {
        key: 'course_open_sequences',
        label: 'Rappels ouverts',
        sortable: true,
        db: `course_open_sequences: course {
          modules {
            sequences {
              offsetDays
            }
          }
        }`,
        formatter: (course, _, item) => {
          if (!course || !course.modules[0]) {
            return '-'
          }
          const openedSequences = course.modules[0].sequences.filter((sequence) => {
            const sequenceStartDate = new Date(item.start_date)
            sequenceStartDate.setDate(sequenceStartDate.getDate() + sequence.offsetDays)
            return (Date.now() >= sequenceStartDate.getTime())
          })

          return `${openedSequences.length}/${course.modules[0].sequences.length}`
        },
      },
      {
        key: 'active_user_count',
        label: 'Stagiaires actifs',
        sortable: true,
        db: `active_user_count: users_aggregate(where: {user: {logs_aggregate: {count: {predicate: {_gte: 1}, filter: {logType: {slug: {_neq: "mail"}}}}}}}) {
          aggregate {
            count
          }
        }
        users_aggregate {
          aggregate {
            count
          }
        }
        `,
        formatter: (active_user_count, _, item) => {
          return `${active_user_count}/${item.users_aggregate.aggregate.count}`
        },
        nested: 'count',
      },
      {
        key: 'status',
        label: 'Statut',
        sortable: true,
        db: `status: isOpen`,
        formatter: (value) => value ? 'Ouverte' : 'Fermée',
      },
      {
        key: 'salesforce_status',
        label: 'Statut Salesforce',
        sortable: true,
      }
      // {
      //   key: 'end_date',
      //   label: 'Statut',
      //   sortable: true,
      //   formatter: (value, key, item) => {
      //     const now = new Date()
      //     const start = new Date(item.start_date)
      //     const end = new Date(item.end_date)

      //     return (now < start ? 'À venir' : (now > end ? 'Passé' : 'En cours'))
      //   },
      // }
    ],
  }),
  computed: {
    ...mapState('Auth', [ 'userInfo' ]),
    whereClause() {
        let where = null
        if (this.userInfo.role === 'instructor') where = `course: { course_instructors: { instructor_id: { _eq: "${this.userInfo.id}" } } }`
        return where
    }
        
  },

  methods: {
    ...mapActions("EditItem", ["clearCurrentID"]),
    onAddPromotionClick() {
      this.clearCurrentID();
      this.$router.push({ name: "promotions-create" });
    },
  },
};
</script>