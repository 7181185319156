<template>
  <div id="nav" class="bg-gray-background text-principal-gray p-4">
    <div class="my-4 logo-container h-6">
      <div class="flex flex-row items-center">
        <img class="logo" src="@/assets/images/logo-navbar.png" alt="" />
      </div>
    </div>
    <div class="nav-links w-full flex-grow" @click="toggleMobile">
      <router-link
        title="Tableau de bord"
        :to="{ name: 'dashboard' }"
        class="items-center navbar__nav-link"
      >
        <div>
          <svg
            version="1.1"
            class="twn-icon fill-current"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 100"
            style="enable-background: new 0 0 100 100"
            xml:space="preserve"
          >
            <g>
              <path
                class="st0"
                d="M79.3,36.5c2.2-3.2,3.7-5.4,3.9-5.8c0.3-0.4,0.2-0.9-0.1-1.3c-0.3-0.3-0.9-0.4-1.3-0.1
                                c-1.4,1-35,23.8-38.1,26.9c-1.7,1.7-2.6,3.9-2.6,6.3s0.9,4.6,2.6,6.3c1.7,1.7,4,2.6,6.3,2.6s4.6-0.9,6.3-2.6
                                c2.2-2.2,14.6-20.2,21.8-30.7c5.7,6.6,8.8,15,8.8,24c0,4.5-0.8,9-2.4,13.2c-0.2,0.5,0.1,1.1,0.6,1.3c0.1,0,0.2,0.1,0.4,0.1
                                c0.4,0,0.8-0.2,0.9-0.6c1.7-4.5,2.6-9.2,2.6-14C89,52.6,85.5,43.5,79.3,36.5z M54.9,67.5c-2.7,2.7-7.1,2.7-9.8,0
                                c-1.3-1.3-2-3-2-4.9s0.7-3.6,2-4.9c2.1-2.1,20.9-15.1,33.5-23.7c-0.4,0.6-0.8,1.2-1.3,1.9c0,0,0,0,0,0c-0.1,0.1-0.2,0.2-0.2,0.4
                                C68.6,48.7,57,65.5,54.9,67.5z"
              />
              <path
                class="st0"
                d="M70.3,31.2c0.5,0.3,1.1,0.2,1.4-0.3c0.3-0.5,0.2-1.1-0.3-1.4C65,25.4,57.6,23.2,50,23.2c-21.5,0-39,17.5-39,39
                                c0,4.9,0.9,9.6,2.7,14c0.2,0.4,0.5,0.6,0.9,0.6c0.1,0,0.3,0,0.4-0.1c0.5-0.2,0.8-0.8,0.6-1.3c-1.7-4.1-2.5-8.6-2.5-13.2
                                c0-20.4,16.6-37,37-37C57.2,25.2,64.2,27.3,70.3,31.2z"
              />
              <path
                class="st0"
                d="M49,32.6v1.7c0,0.6,0.4,1,1,1s1-0.4,1-1v-1.7c0-0.6-0.4-1-1-1S49,32,49,32.6z"
              />
              <path
                class="st0"
                d="M80,61.6h-1.7c-0.6,0-1,0.4-1,1s0.4,1,1,1H80c0.6,0,1-0.4,1-1S80.6,61.6,80,61.6z"
              />
              <path
                class="st0"
                d="M21.8,61.6H20c-0.6,0-1,0.4-1,1s0.4,1,1,1h1.7c0.6,0,1-0.4,1-1S22.3,61.6,21.8,61.6z"
              />
            </g>
          </svg>
          <span
            :class="isItemIsThePageWhereUserIs('dashboard') ? 'font-black' : ''"
            >Tableau de bord</span
          >
        </div>
      </router-link>
      <router-link
        title="Utilisateurs"
        class="items-center navbar__nav-link"
        :to="{ name: 'users-list' }"
      >
        <div>
          <svg
            class="twn-icon fill-current"
            data-name="Calque 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
          >
            <defs></defs>
            <path
              class="cls-1"
              d="M51.24,49.49A12.28,12.28,0,1,1,63.52,37.21,12.3,12.3,0,0,1,51.24,49.49Zm0-22.56A10.28,10.28,0,1,0,61.52,37.21,10.29,10.29,0,0,0,51.24,26.93Z"
            />
            <path
              class="cls-1"
              d="M68,72.47H66V64.73a8,8,0,0,0-8-8H44.47a8,8,0,0,0-8,8v7.74h-2V64.73a10,10,0,0,1,10-10H58a10,10,0,0,1,10,10Z"
            />
            <path
              class="cls-1"
              d="M77.07,53.38a9.49,9.49,0,1,1,9.49-9.49A9.5,9.5,0,0,1,77.07,53.38Zm0-17a7.49,7.49,0,1,0,7.49,7.49A7.5,7.5,0,0,0,77.07,36.4Z"
            />
            <path
              class="cls-1"
              d="M89,72.47a1,1,0,0,1-1-1V66.4a5.79,5.79,0,0,0-5.78-5.79H72a1,1,0,0,1,0-2H82.17A7.8,7.8,0,0,1,90,66.4v5.07A1,1,0,0,1,89,72.47Z"
            />
            <path
              class="cls-1"
              d="M25.06,53.38a9.49,9.49,0,1,1,9.48-9.49A9.5,9.5,0,0,1,25.06,53.38Zm0-17a7.49,7.49,0,1,0,7.48,7.49A7.5,7.5,0,0,0,25.06,36.4Z"
            />
            <path
              class="cls-1"
              d="M13.17,72.47a1,1,0,0,1-1-1V66.4A7.81,7.81,0,0,1,20,58.61H30.15a1,1,0,0,1,0,2H20a5.8,5.8,0,0,0-5.79,5.79v5.07A1,1,0,0,1,13.17,72.47Z"
            />
          </svg>
          <span
            :class="
              isItemIsThePageWhereUserIs('users-list') ? 'font-black' : ''
            "
            >Utilisateurs</span
          >
        </div>
      </router-link>
      <router-link
        title="Promotions"
        class="items-center navbar__nav-link"
        :to="{ name: 'promotions-list' }"
      >
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="twn-icon fill-current"
            viewBox="0 0 78.11 79.45"
          >
            <defs></defs>
            <path
              class="a"
              d="M77.7,28.25,39.65.19a1,1,0,0,0-1.19,0L.41,28.25a1,1,0,0,0,0,1.61L10.77,37.5v32a1,1,0,0,0,.43.82,1,1,0,0,0,.92.12l4.75-1.74L24,79a1,1,0,0,0,.83.43.93.93,0,0,0,.29,0,1,1,0,0,0,.71-1V64.91A23.22,23.22,0,0,0,62.28,45.82V41.23L77.7,29.86a1,1,0,0,0,0-1.61ZM24.24,45.82a.5.5,0,0,0-.07.08A.86.86,0,0,0,24,46a.36.36,0,0,0,0,.09.55.55,0,0,0-.08.15,1.29,1.29,0,0,0-.06.25s0,.07,0,.11v28.6l-5.76-8.35a1,1,0,0,0-.82-.43,1,1,0,0,0-.34.06L12.77,68V37.48l17-12.5,11.37,8.4Zm36,0A21.21,21.21,0,0,1,25.83,62.41V48.6l12.63,9.31a1,1,0,0,0,1.19,0L60.28,42.7Zm-21.23,10L26.52,46.63l16.9-12.44a1,1,0,0,0,0-1.61L30.36,22.93a1,1,0,0,0-1.18,0L11.76,35.75l-9.07-6.7L39.05,2.24,75.42,29.05Z"
            />
          </svg>
          <span
            :class="
              isItemIsThePageWhereUserIs('promotions-list') ? 'font-black' : ''
            "
            >Promotions</span
          >
        </div>
      </router-link>
      <router-link
        title="Parcours"
        class="items-center navbar__nav-link"
        :to="{ name: 'course-list' }"
      >
        <div>
          <svg
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 512 512"
            style="enable-background: new 0 0 512 512"
            xml:space="preserve"
            class="twn-icon fill-current"
          >
            <g>
              <g>
                <g>
                  <path
                    d="M426.667,0c-47.052,0-85.333,38.281-85.333,85.333c0,40.814,60.469,123.419,74.637,142.22
				c-0.004,6.073,0,12.036-0.147,17.509c-0.156,5.885,4.49,10.792,10.385,10.948c0.094,0,0.188,0,0.292,0
				c5.75,0,10.5-4.594,10.656-10.385c0.147-5.616,0.143-11.746,0.147-17.992C451.378,208.961,512,126.195,512,85.333
				C512,38.281,473.719,0,426.667,0z M426.667,206.146c-23.448-32.26-64-93.656-64-120.813c0-35.292,28.708-64,64-64
				s64,28.708,64,64C490.667,112.49,450.115,173.885,426.667,206.146z"
                  />
                  <path
                    d="M426.667,64c-11.76,0-21.333,9.573-21.333,21.333c0,11.76,9.573,21.333,21.333,21.333S448,97.094,448,85.333
				C448,73.573,438.427,64,426.667,64z"
                  />
                  <path
                    d="M128,234.667c-35.292,0-64,28.708-64,64c0,35.292,28.708,64,64,64s64-28.708,64-64
				C192,263.375,163.292,234.667,128,234.667z M128,341.333c-23.531,0-42.667-19.135-42.667-42.667S104.469,256,128,256
				s42.667,19.135,42.667,42.667S151.531,341.333,128,341.333z"
                  />
                  <path
                    d="M289.573,384.438c-5.521-2.146-11.667,0.594-13.802,6.094c-4.781,12.354-10.76,26.76-18.823,39.958
				c-3.073,5.031-1.479,11.594,3.542,14.667c1.74,1.063,3.656,1.563,5.552,1.563c3.594,0,7.104-1.813,9.115-5.104
				c8.938-14.646,15.385-30.146,20.51-43.375C297.792,392.75,295.063,386.573,289.573,384.438z"
                  />
                  <path
                    d="M229.427,462.281c-10.688,8.469-22.833,14.844-37.146,19.5c-5.604,1.823-8.667,7.844-6.844,13.448
				c1.469,4.5,5.646,7.365,10.146,7.365c1.094,0,2.208-0.167,3.302-0.521c16.531-5.375,31.26-13.135,43.792-23.063
				c4.615-3.667,5.396-10.375,1.74-14.99C240.729,459.417,234.031,458.594,229.427,462.281z"
                  />
                  <path
                    d="M326.5,307.427c-17.604,9.99-27.385,27.333-33.219,39.5c-2.552,5.313-0.313,11.688,5,14.229
				c1.49,0.719,3.063,1.052,4.604,1.052c3.979,0,7.792-2.229,9.625-6.052c7.406-15.448,14.969-24.75,24.531-30.188
				c5.115-2.906,6.917-9.417,4-14.542C338.146,306.302,331.656,304.5,326.5,307.427z"
                  />
                  <path
                    d="M422.708,281.021c-4.844-3.281-11.5-2.021-14.813,2.854c-3.51,5.188-10.698,12.323-32.438,14.271
				c-5.865,0.531-10.198,5.708-9.667,11.583c0.5,5.542,5.156,9.708,10.615,9.708c0.323,0,0.646-0.01,0.969-0.042
				c23.094-2.073,38.854-9.781,48.188-23.563C428.865,290.958,427.583,284.323,422.708,281.021z"
                  />
                  <path
                    d="M153.275,490.805C186.932,454.913,256,372.341,256,298.667c0-71.771-56.229-128-128-128s-128,56.229-128,128
				c0,95.26,115.75,205.76,120.677,210.417c2.052,1.948,4.688,2.917,7.323,2.917c7.771,0,15.156-0.271,22.156-0.802
				c5.875-0.448,10.281-5.563,9.833-11.438C159.669,495.556,156.948,492.277,153.275,490.805z M128,486.292
				c-25.198-25.708-106.667-114.5-106.667-187.625C21.333,238.854,68.188,192,128,192s106.667,46.854,106.667,106.667
				C234.667,371.792,153.198,460.583,128,486.292z"
                  />
                </g>
              </g>
            </g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
          </svg>
          <span
            :class="
              isItemIsThePageWhereUserIs('course-list') ? 'font-black' : ''
            "
            >Parcours</span
          >
        </div>
      </router-link>
      <router-link
        title="Offices"
        class="d-none items-center navbar__nav-link"
        :to="{ name: 'organisations-list' }"
        v-if="isUserSuperAdmin"
      >
        <div>
          <svg
            class="twn-icon fill-current"
            data-name="Calque 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
          >
            <defs></defs>
            <path
              class="cls-1"
              d="M63.38,74.1a1,1,0,0,0,1-1V49.39a1,1,0,0,0-2,0V73.1A1,1,0,0,0,63.38,74.1Z"
            />
            <path
              class="cls-1"
              d="M71.38,74.1a1,1,0,0,0,1-1V52.44a1,1,0,0,0-2,0V73.1A1,1,0,0,0,71.38,74.1Z"
            />
            <path
              class="cls-1"
              d="M28.73,74.1H46.62a1,1,0,1,0,0-2H28.73a1,1,0,0,0,0,2Z"
            />
            <path
              class="cls-1"
              d="M28.73,62.55H46.62a1,1,0,0,0,0-2H28.73a1,1,0,0,0,0,2Z"
            />
            <path
              class="cls-1"
              d="M28.73,51H46.62a1,1,0,1,0,0-2H28.73a1,1,0,0,0,0,2Z"
            />
            <path
              class="cls-1"
              d="M28.73,39.45H46.62a1,1,0,0,0,0-2H28.73a1,1,0,0,0,0,2Z"
            />
            <path
              class="cls-1"
              d="M28.73,27.9H46.62a1,1,0,0,0,0-2H28.73a1,1,0,1,0,0,2Z"
            />
            <path
              class="cls-1"
              d="M91.38,83.65h-11V44.09a1,1,0,0,0-.62-.93L56,33.42V15.35a1,1,0,0,0-1-1H20.62a1,1,0,0,0-1,1v68.3h-11a1,1,0,1,0,0,2H91.38a1,1,0,0,0,0-2ZM54,34.09V83.65H21.62V16.35H54ZM78.38,83.65H56V35.58l22.38,9.18Z"
            />
          </svg>
          <span
            :class="
              isItemIsThePageWhereUserIs('organisations-list')
                ? 'font-black'
                : ''
            "
            >Offices</span
          >
        </div>
      </router-link>

      <router-link
        title="Scénarios"
        v-if="isUserSuperAdmin || isUserInstructor || isUserAfpols"
        :to="{ name: 'scenario-list' }"
        class="items-center navbar__nav-link"
      >
        <div>
          <svg
            class="twn-icon fill-current"
            data-name="Calque 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
          >
            <defs></defs>
            <path
              class="cls-1"
              d="M69.48,19.5h-7v-7a1,1,0,0,0-1-1h-46a1,1,0,0,0-1,1v65a1,1,0,0,0,1,1h7v7a1,1,0,0,0,1,1h34a13,13,0,0,0,13-13v-53A1,1,0,0,0,69.48,19.5Zm-53,57v-63h44V66.37a2.14,2.14,0,0,1-2.13,2.13H53.48a1,1,0,0,0-1,1v4.87a2.14,2.14,0,0,1-2.13,2.13Zm52-3a11,11,0,0,1-11,11h-33v-6H50.35a4.13,4.13,0,0,0,4.13-4.13V70.5h3.87a4.13,4.13,0,0,0,4.13-4.13V21.5h6Z"
            />
            <path
              class="cls-1"
              d="M25.52,28.5h26a1,1,0,0,0,0-2h-26a1,1,0,1,0,0,2Z"
            />
            <path
              class="cls-1"
              d="M25.52,38.5h26a1,1,0,0,0,0-2h-26a1,1,0,1,0,0,2Z"
            />
            <path
              class="cls-1"
              d="M52.48,47.5a1,1,0,0,0-1-1h-26a1,1,0,1,0,0,2h26A1,1,0,0,0,52.48,47.5Z"
            />
            <path
              class="cls-1"
              d="M82,19.5a3.5,3.5,0,0,0-3.5,3.5V80A3.5,3.5,0,0,0,81,83.34V87.5a1,1,0,0,0,2,0V83.34A3.5,3.5,0,0,0,85.48,80V23A3.5,3.5,0,0,0,82,19.5ZM83.48,80a1.5,1.5,0,0,1-3,0V38.5h3Zm0-43.5h-3V23a1.5,1.5,0,0,1,3,0Z"
            />
          </svg>
          <span
            :class="
              isItemIsThePageWhereUserIs('users-list') ? 'font-black' : ''
            "
            >Scénarios</span
          >
        </div>
      </router-link>
      <router-link
        title="Mini-jeux"
        v-if="isUserSuperAdmin || isUserInstructor || isUserAfpols"
        to="/games/list"
        class="items-center navbar__nav-link"
      >
        <div>
          <svg
            class="twn-icon fill-current"
            data-name="Calque 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
          >
            <defs></defs>
            <path
              class="cls-1"
              d="M50,57a7,7,0,1,0-7-7A7,7,0,0,0,50,57Zm0-12a5,5,0,1,1-5,5A5,5,0,0,1,50,45Z"
            />
            <path
              class="cls-1"
              d="M69.32,30.68a1,1,0,0,0,.7.3,1,1,0,0,0,.71-.3L76,25.41l5.27,5.27A1,1,0,0,0,82,31a1,1,0,0,0,.7-1.71L77.41,24l5.27-5.27a1,1,0,0,0-1.41-1.41L76,22.59l-5.27-5.27a1,1,0,0,0-1.41,1.41L74.59,24l-5.27,5.27A1,1,0,0,0,69.32,30.68Z"
            />
            <path
              class="cls-1"
              d="M30.68,69.32a1,1,0,0,0-1.41,0L24,74.59l-5.27-5.27a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.41L22.59,76l-5.27,5.27a1,1,0,0,0,0,1.41,1,1,0,0,0,.7.3,1,1,0,0,0,.71-.3L24,77.41l5.27,5.27A1,1,0,0,0,30,83a1,1,0,0,0,.7-.3,1,1,0,0,0,0-1.41L25.41,76l5.27-5.27A1,1,0,0,0,30.68,69.32Z"
            />
            <path
              class="cls-1"
              d="M85.49,62H64V38H85.49a1,1,0,0,0,0-2H64V14.51a1,1,0,0,0-2,0V36H38V14.51a1,1,0,1,0-2,0V36H14.51a1,1,0,1,0,0,2H36V62H14.51a1,1,0,0,0,0,2H36V85.49a1,1,0,0,0,2,0V64H62V85.49a1,1,0,1,0,2,0V64H85.49a1,1,0,1,0,0-2ZM62,62H38V38H62Z"
            />
          </svg>
          <span
            :class="
              isItemIsThePageWhereUserIs('users-list') ? 'font-black' : ''
            "
            >Mini-jeux</span
          >
        </div>
      </router-link>
      <router-link
        title="Médias"
        v-if="isUserSuperAdmin || isUserInstructor || isUserAfpols"
        to="/media/list"
        class="items-center navbar__nav-link"
      >
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="twn-icon fill-current p-1"
            viewBox="0 0 77.48 77.67"
          >
            <defs></defs>
            <path
              class="a"
              d="M43.74,17.14a1,1,0,0,0,1-1V7.76a1,1,0,1,0-2,0v8.38A1,1,0,0,0,43.74,17.14Z"
            />
            <path
              class="a"
              d="M49.19,21.6a1,1,0,0,0,1-1V3.3a1,1,0,1,0-2,0V20.6A1,1,0,0,0,49.19,21.6Z"
            />
            <path
              class="a"
              d="M54.65,17.14a1,1,0,0,0,1-1V7.76a1,1,0,1,0-2,0v8.38A1,1,0,0,0,54.65,17.14Z"
            />
            <path
              class="a"
              d="M60.11,21.6a1,1,0,0,0,1-1V3.3a1,1,0,1,0-2,0V20.6A1,1,0,0,0,60.11,21.6Z"
            />
            <path
              class="a"
              d="M65.56,23.9a1,1,0,0,0,1-1V1a1,1,0,0,0-2,0V22.9A1,1,0,0,0,65.56,23.9Z"
            />
            <path
              class="a"
              d="M71,2.3a1,1,0,0,0-1,1V20.6a1,1,0,1,0,2,0V3.3A1,1,0,0,0,71,2.3Z"
            />
            <path
              class="a"
              d="M76.48,6.76a1,1,0,0,0-1,1v8.38a1,1,0,0,0,2,0V7.76A1,1,0,0,0,76.48,6.76Z"
            />
            <path
              class="a"
              d="M25.77,34.63a1,1,0,0,0-.5-.87L1.5,20.08a1,1,0,0,0-1,0A1,1,0,0,0,0,21V48.3a1,1,0,0,0,.5.87,1,1,0,0,0,.5.13,1,1,0,0,0,.5-.13L25.27,35.5A1,1,0,0,0,25.77,34.63ZM2,46.58V22.68l20.77,12Z"
            />
            <path
              class="a"
              d="M70.8,51.48H36.22a1,1,0,0,0-1,1V76.67a1,1,0,0,0,1,1H70.8a1,1,0,0,0,1-1V52.48A1,1,0,0,0,70.8,51.48Zm-1,24.19H37.22V67.25l7.34-7.34,7.67,7.67a1,1,0,0,0,1.41,0L56.24,65l7.19,7.19a1,1,0,0,0,1.41,0l1.46-1.46,3.5,3.5Zm0-4.27L67,68.6a1,1,0,0,0-1.41,0l-1.46,1.45-7.19-7.18a1,1,0,0,0-1.41,0l-2.6,2.59-7.66-7.67a1,1,0,0,0-1.42,0l-6.63,6.63V53.48H69.8Z"
            />
            <path
              class="a"
              d="M64.12,63.64a4.3,4.3,0,1,0-4.3-4.3A4.3,4.3,0,0,0,64.12,63.64Zm0-6.6a2.3,2.3,0,1,1-2.3,2.3A2.3,2.3,0,0,1,64.12,57Z"
            />
            <path
              class="a"
              d="M28.76,63.28A26.3,26.3,0,0,1,14.69,49.73a1,1,0,0,0-1.82.83A28.35,28.35,0,0,0,28,65.13a1,1,0,0,0,.37.07,1,1,0,0,0,.38-1.92Z"
            />
            <path
              class="a"
              d="M65.13,38.83a26.34,26.34,0,0,1-1,7.14,1,1,0,0,0,.69,1.23,1.26,1.26,0,0,0,.27,0,1,1,0,0,0,1-.73,28.74,28.74,0,0,0,1-7.68,28.17,28.17,0,0,0-2-10.45,1,1,0,0,0-1.86.73A26.43,26.43,0,0,1,65.13,38.83Z"
            />
            <path
              class="a"
              d="M17.75,22.22a1,1,0,0,0,.77-.35,26.3,26.3,0,0,1,17.07-9.25,1,1,0,1,0-.23-2A28.25,28.25,0,0,0,17,20.58,1,1,0,0,0,17.11,22,1,1,0,0,0,17.75,22.22Z"
            />
          </svg>
          <span
            :class="
              isItemIsThePageWhereUserIs('users-list') ? 'font-black' : ''
            "
            >Médias</span
          >
        </div>
      </router-link>
      <!-- </div> -->
      <!-- </transition> -->
      <!-- </div> -->
    </div>
    <div
      @click="toggleNav"
      class="
        arrows
        bootstrap
        md:visible
        md:block
        text-center
        cursor-pointer
        w-full
      "
    >
      <b-icon-arrow-up class="hidden h1 up-arrow m-auto"></b-icon-arrow-up>
      <b-icon-arrow-right class="right-arrow h1 m-0"></b-icon-arrow-right>
      <div class="left-arrow hidden justify-between items-center">
        <span
          class="uppercase ml-auto mr-auto font-semibold opacity-75 ease-linear"
          >Réduire</span
        >
        <b-icon-arrow-left class="left-arrow h1 mr-3"></b-icon-arrow-left>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import tailwindConf from "../../tailwind.config";

export default {
  name: "Navbar",
  data() {
    return {
      window: {
        width: 0,
        height: 0,
      },
      publicPath: process.env.BASE_URL,
      openedItem: "medias",
    };
  },
  computed: {
    ...mapState("Auth", ["userInfo"]),
    isUserSuperAdmin() {
      return this.userInfo && this.userInfo.role == "superadmin";
    },
    isUserInstructor() {
      return this.userInfo && this.userInfo.role == "instructor";
    },
    isUserAfpols() {
      return this.userInfo && this.userInfo.role == "afpols";
    },
  },
  methods: {
    ...mapActions("Header", ["toggleNav", "openNav", "closeNav"]),
    toggleMobile(e) {
      if (this.window.width <= parseInt(tailwindConf.theme.screens.sm, 10)) {
        this.toggleNav(e);
      }
    },
    open() {
      this.openNav();
    },
    close() {
      this.closeNav();
      this.openedItem = null;
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    onItemClick(itemName) {
      this.openedItem = this.openedItem === itemName ? null : itemName;
    },
    isItemIsThePageWhereUserIs(item) {
      return this.$route.matched.some(({ name }) => name === item);
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
<style lang="scss">
#nav {
  @apply border-line-left left-0 right-auto overflow-y-auto flex flex-col items-center;
  border-right-width: 1px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.15);
  transition: all 0.3s cubic-bezier(0.685, 0.0473, 0.346, 1);
  transform: translate3d(0px, -100vh, 0) !important;
  overflow-x: hidden;
  // width: $navbarLength;
  width: 100%;
  z-index: 99;

  > .arrows {
    @apply block;
    .up-arrow {
      @apply block;
    }
    .right-arrow {
      @apply hidden;
    }
  }

  .logo {
    @apply w-full;
    max-width: 3.5rem;
  }

  &.nav-open {
    transform: translate3d(0px, 0, 0) !important;
    .logo {
      transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    }
  }

  > div:not(.arrows) {
    @apply flex flex-col;

    &.logo-container {
      @apply self-start;
    }
    &.nav-links {
      @apply text-xs tracking-tighter leading-loose;
      opacity: 0.9;

      .navbar__nav-link {
        div {
          @apply flex items-center;
        }
        &:hover {
          @apply text-principal-selected no-underline cursor-pointer;
        }
      }

      div,
      a {
        .twn-icon {
          @apply mb-0 transition duration-100 ease-linear;
          width: 40px;
          height: 40px;
        }
        span {
          @apply transition duration-100 ease-linear tracking-normal text-base absolute;
          @screen md {
            @apply text-sm;
          }
          left: 70px;
        }
        .navbar__nav-link-children {
          a {
            @apply mt-4;
          }
          span {
            @apply text-xs;
          }
        }
      }

      > * {
        @apply my-1 p-1 rounded;
        @screen xs {
          @apply my-3;
        }
      }
    }
  }

  @screen md {
    @apply flex flex-col;
    overflow-y: none;
    width: $navbarLength;

    transform: translate3d(0px, 0, 0) !important;

    .arrows {
      .up-arrow {
        @apply hidden;
      }
    }
    .right-arrow {
      @apply hidden m-0;
    }
    .left-arrow {
      @apply flex visible m-0;
    }
    .nav-links {
      span {
        @apply opacity-100;
      }
    }
    &:not(.nav-open) {
      // @apply justify-between;
      // .arrows{
      //     @apply mt-0;
      // }
      .left-arrow {
        @apply hidden;
      }
      .right-arrow {
        @apply block visible;
      }
      width: 5rem !important;
      // .logo{
      //     @apply w-full;
      // }
      .nav-links {
        // .twn-icon {
        //     margin: 0 !important;
        // }
        span {
          @apply opacity-0;

          // @apply hidden;
        }
      }
    }
  }
}
</style>