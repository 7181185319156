import utils from "@/utils/trackingReportTemplate"

// const now = Date.now()
// const oneDay = (1 * 60 * 60 * 1000 * 24)
// const oneMonth = (oneDay * 30)
// const threeMonth = (oneMonth * 3)
// const oneYear = (oneDay * 365)

export default {
	createdDate(user) {
		return new Date(user.created_at)
	},
	mailingLogs(user) {
		// Create a map of type => log from the user mailer logs
		return user.logs.reduce((dict, log) => {
			if (log.type.slug == 'mail' && log.data.type) {
				dict[log.data.type] = log
			}

			return dict
		}, {})
	},
	activeTimeData(user) {
		// Init computed data
		let computedTimeData = {
			times: [],
			sum: 0,
			max: 0,
			average: 0,
			weekIds: {},
			monthIds: {},
			yearIds: {},
			firstSessionTimestamp: 0,
			lastSessionTimestamp: 0,
			timesBySequence: {},
			timeBySequence: {},
		}

		const sessionStops = {}

		// Get all event date times
		const times = user.logs.reduce((times, log) => {
			// Exclude "passive" logs
			if (['mail'].indexOf(log.type.slug) > -1) {
				return times
			}
			
			// Add log creation time
			const logTime = (new Date(log.created_at)).getTime()
			times.push({
				logTime,
				sequenceId: log.data && log.data.sequence_id,
			})

			// Register all log time who should always trigger a new session
			if (['login', 'logout', 'sequence_end'].indexOf(log.type.slug) > -1) {
				sessionStops[logTime] = true
			}

			return times
		}, []).sort()

		// Get all data by spliting user logs in sessions
		const oneHour = (1 * 60 * 60 * 1000) // in 
		const minimalSessionTime = (1 * 60 * 1000) // 1 min

		let lastLogTime = 0
		let currentSession = null

		computedTimeData.firstSessionTimestamp = (times && times.length > 0 && times[0].logTime)

		times.forEach(({ logTime, sequenceId }) => {
			const deltaTime = (logTime - lastLogTime)

			// Check if we need to create a new session
			if (
				!currentSession // Initial state
				|| deltaTime > oneHour // Two logs are too far apart to be of the same session
				|| sessionStops[logTime] // This log should always trigger a new session
				|| (sequenceId && currentSession.sequenceId && currentSession.sequenceId != sequenceId) // The log sequence id doesn't match the current one
			) {
				// Start a new session with the minimal time (1 min)
				currentSession = {
					startTime: logTime,
					duration: minimalSessionTime,
					sequenceId,
				}
				computedTimeData.times.push(currentSession)
			} else {
				// Increment current session time
				currentSession.duration += deltaTime

				// Update session sequence id, if possible/needed
				if (!currentSession.sequenceId) {
					currentSession.sequenceId = sequenceId
				}
			}

			// Update last session timestamp
			computedTimeData.lastSessionTimestamp = logTime

			// Register log week, month and year as active
			const logDate = new Date(logTime)
			
			const weekId = utils.weekId(logDate)
			const monthId = utils.monthId(logDate)
			const yearId = utils.yearId(logDate)

			if (computedTimeData.weekIds[weekId] === undefined) {
				computedTimeData.weekIds[weekId] = true
			}
			if (computedTimeData.monthIds[monthId] === undefined) {
				computedTimeData.monthIds[monthId] = true
			}
			if (computedTimeData.yearIds[yearId] === undefined) {
				computedTimeData.yearIds[yearId] = true
			}

			lastLogTime = logTime
		})

		// Compute sum, max and other data
		computedTimeData.times.forEach((session) => {
			// Update sum
			computedTimeData.sum += session.duration

			// Update max
			if (session.duration > computedTimeData.max) {
				computedTimeData.max = session.duration
			}

			// Update sequence data
			if (session.sequenceId) {
				if (!computedTimeData.timesBySequence[session.sequenceId]) {
					computedTimeData.timesBySequence[session.sequenceId] = []
				}

				computedTimeData.timesBySequence[session.sequenceId].push(session)

				if (!computedTimeData.timeBySequence[session.sequenceId]) {
					computedTimeData.timeBySequence[session.sequenceId] = 0
				}

				computedTimeData.timeBySequence[session.sequenceId] += session.duration
			}
		})

		// Compute average time
		if (computedTimeData.times.length > 0) {
			computedTimeData.average = (computedTimeData.sum / computedTimeData.times.length)
		}

		return computedTimeData
	},
	state(user, data) {
		// Check if the user group is not disabled and still active (start/end date)
		const hasGroup = (user.groups.length > 0)
		let isGroupActive = (hasGroup && user.groups[0].data.isOpen)

		if (isGroupActive) {
			const startDate = new Date(user.groups[0].data.start_date)
			const endDate = new Date(user.groups[0].data.end_date)

			isGroupActive = (data.rangeEndDate.getTime() > startDate.getTime() && data.rangeStartDate.getTime() < endDate.getTime())
		}

		if (user.activeTimeData.lastSessionTimestamp > 0) {
			return 'active'
		} else if (user.disabled || (hasGroup && !isGroupActive)) {
			return 'disabled'
		} else if (hasGroup && isGroupActive && (!user.cognito_id || !user.cognito_confirmed)) {
			return 'inactive'
		} else if (user.cognito_id) {
			if (user.cognito_confirmed) {
				return (user.activeTimeData.lastSessionTimestamp > 0 ? 'active' : 'pending')
			}

			return 'pending'
		}

		return 'exist'
	},
	logByTypeSlug(user) {
		return utils.groupBy(user.logs, 'type', 'slug')
	},
	gameLogsBySequenceId(user) {
		return utils.groupBy((user.logByTypeSlug['game_end'] || []), 'data', 'sequence_id')
	},
	successRatePerSequence(user) {
		const courseSequenceIds = Object.keys(user.gameLogsBySequenceId)

		// Create a dictionary of average games success rate by sequence id
		return courseSequenceIds.reduce((dict, sequenceId) => {
			// Get best success rate per game id in this sequence
			const successRatePerGameId = (user.gameLogsBySequenceId[sequenceId] || []).reduce((dict, log) => {
				if (log.data) {
					const successRate = (dict[log.data_key] || -1)

					// Only keep best success rate from all attempt
					if (log.data.value > successRate) {
						dict[log.data_key] = log.data.value
					}
				}

				return dict
			}, {})

			// Get average game success rate for this sequence, if possible
			if (Object.keys(successRatePerGameId).length > 0) {
				dict[sequenceId] = utils.groupAverage(successRatePerGameId, (successRate) => successRate)
			}

			return dict
		}, {})
	},
	successRatePerGroup(user, data) {
		return user.groups.reduce((successRatePerGroup, userGroup) => {
			const group = data.groupsById[userGroup.id]

			if (!group) {
				return successRatePerGroup
			}

			const course = group.course

			if (!course) {
				return successRatePerGroup
			}

			const sequencesSuccessRates = course.modules.reduce((list, module) => {
				module.sequences.forEach((sequence) => {
					if (user.successRatePerSequence[sequence.id]) {
						list.push(user.successRatePerSequence[sequence.id])
					}
				})
				return list
			}, [])

			// Add success rate average
			successRatePerGroup[group.id] = utils.averageValues(sequencesSuccessRates, null, true)

			return successRatePerGroup
		}, {})
	},
	successRate(user) {
		// If we don't have any data we must not consider this data has valid when computing users average
		if (Object.keys(user.successRatePerSequence).length <= 0)
			return undefined

		// Make an average of user success rate per game
		return utils.groupAverage(user.successRatePerSequence, (successRate) => successRate)
	},
	progressPerGroup(user, data) {
		return user.groups.reduce((progressPerGroup, userGroup) => {
			const group = data.groupsById[userGroup.id]

			if (!group) {
				return progressPerGroup
			}

			const course = group.course

			if (!course) {
				return progressPerGroup
			}

			course.sequenceById = course.modules.reduce((dict, module) => {
				module.sequences.forEach((sequence) => {
					dict[sequence.id] = sequence
				})
				return dict
			}, {})

			// Get sequence done in this course
			const courseSequenceDoneById = (user.logByTypeSlug['sequence_end'] || []).reduce((dict, log) => {
				if (course.sequenceById[log.data_key]) {
					dict[log.data_key] = true
				}
				return dict
			}, {})

			// Get sequence available in this course
			const courseSequenceCount = Object.keys(course.sequenceById).length

			// Add progress percentage
			progressPerGroup[group.id] = Object.keys(courseSequenceDoneById).length / (courseSequenceCount || 1)

			return progressPerGroup
		}, {})
	},
	groupsById(user) {
		return user.groups.reduce((dict, group) => {
			dict[group.id] = group
			return dict
		}, {})
	},
	accessMailDate(user) {
		const mailDateFromLog = (mailType) => {
			if (!user.mailingLogs[mailType]) {
				return null
			}

			return new Date(user.mailingLogs[mailType].created_at)
		}

		return (mailDateFromLog('access-account') || mailDateFromLog('access-before') || mailDateFromLog('access-late') || mailDateFromLog('access-active'))
	},
	timesPerGroup(user, data) {
		return user.groups.reduce((timesPerGroup, userGroup) => {
			const group = data.groupsById[userGroup.id]

			if (!group) {
				return timesPerGroup
			}

			const course = group.course

			if (!course) {
				return timesPerGroup
			}

			// Concat all group sequence times
			timesPerGroup[group.id] = []

			course.modules.forEach((module) => {
				module.sequences.forEach((sequence) => {
					if (user.activeTimeData.timesBySequence[sequence.id]) {
						timesPerGroup[group.id] = timesPerGroup[group.id].concat(user.activeTimeData.timesBySequence[sequence.id])
					}
				})
			})

			return timesPerGroup
		}, {})
	},
}