<template>
	<div class="dashboard-subcategory shadow-light">
		<div v-if="help" class="dashboard-subcategory-tooltip">
			<span :class="(Math.round(help.length / 30) <= 2 ? 'w-48' : 'w-56')">{{help}}</span>
			<p>?</p>
		</div>

		<p v-if="title" class="text-xl mb-2">{{title}}</p>
		<slot></slot>
	</div>
</template>

<script>
	export default {
		name: 'SubCategoryBlock',
		props: {
			title: {
				type: String,
				default: null,
			},
			help: {
				type: String,
				default: null
			},
		}
	}
</script>

<style lang="scss">
	.dashboard-subcategory {
		@apply relative bg-white rounded-dashboard-sm p-8;

		& > p {
			@apply text-lg text-black uppercase mb-2;
		}

		&-tooltip {
			@apply absolute top-0 right-0 p-2 cursor-help;

			p {
				@apply w-4 h-4 bg-gray rounded-full text-xs text-center text-dashboard-gray-lighter font-principal-medium;
			}

			span {
				@apply hidden;
			}

			&:hover span {
				@apply absolute block bg-gray rounded-lg text-xs text-center text-white font-principal-medium bottom-8 right-4 py-2 px-4 mb-1 transform translate-x-1/2;

				&:after {
					@apply border border-gray;
					content: "";
					position: absolute;
					top: 100%;
					left: 50%;
					height: 0;
					width: 0;
					border-bottom-color: transparent;
					border-right-color: transparent;
					border-left-color: transparent;
					border-width: 0.5rem;
					margin-left: -0.5rem;
				}
			}
		}
	}
</style>