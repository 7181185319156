<template>
    <div class="dashboard">
        <MainDashboard />
    </div>
</template>

<script>
    import MainDashboard from '@/components/dashboard/Main'
    
    export default {
        name: 'Dashboard',
        components: {
            MainDashboard,
        },
    }
</script>

<style lang="scss" scoped></style>