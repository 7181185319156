import utils from "@/utils/trackingReportTemplate"

function getActiveUserCountData(data, payload) {
	// Create dictionary of active user ids per years and months
	let activeData = {
		years: {},
		months: {}
	}

	// Get count of active users per years and months
	data.users.forEach((user) => {
		const monthIds = Object.keys(user.activeTimeData.monthIds)
		const yearIds = Object.keys(user.activeTimeData.yearIds)

		monthIds.forEach((monthId) => {
			if (!activeData.months[monthId])
				activeData.months[monthId] = 0

			activeData.months[monthId] += 1
		})

		yearIds.forEach((yearId) => {
			if (!activeData.years[yearId])
				activeData.years[yearId] = 0

			activeData.years[yearId] += 1
		})
	}, {})

	// Make sure all months and years are filled with a value
	const startDate = data.firstCreatedUserDate
	const endDate = new Date()

	utils.fillDictionaryWithDefaultValue(activeData.years, 0, utils.generateNextYearIdCallback(startDate, endDate))
	utils.fillDictionaryWithDefaultValue(activeData.months, 0, utils.generateNextMonthIdCallback(startDate, endDate))

	return activeData
}

function getUserActiveTimeData(data) {
	// Get/Filter user time data for the current course
	return {
		sum: utils.sumValues(data.confirmedUsers, (user) => {
			return data.course.modules[0].sequences.reduce((sum, sequence) => {
				sum += (user.activeTimeData.timeBySequence[sequence.id] || 0)
				return sum
			}, 0)
		}),
		// average: utils.averageField(data.confirmedUsers, 'activeTimeData', 'average', true),
		count: data.confirmedUsers.reduce((count, user) => {
			count += data.course.modules[0].sequences.reduce((sequenceSessionCount, sequence) => {
				sequenceSessionCount += (user.activeTimeData.timesBySequence[sequence.id]?.length || 0)
				return sequenceSessionCount
			}, 0)
			return count
		}, 0),
	}
}

export default function userLoginsDashboardData(data, payload) {
	return {
		activeCount: getActiveUserCountData(data, payload),
		activeTime: getUserActiveTimeData(data)
	}
}