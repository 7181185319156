import axios from 'axios'

import apollo from '@/apolloClient'

import {
    GC_GET_USER_LIST,
    GC_GET_USER_BY_ID,
    GC_ADD_USER_ONE,
    GC_UPDATE_USER_BY_ID,
    GC_DELETE_USER_BY_ID,
} from '@/graphql/user'

const authURL = process.env.VUE_APP_API_URL + '/auth'

export default {
    namespaced: true,
    state: {
        list: [],
        items: {},
    },
    mutations: {
        SET_USER_LIST(state, users) {
            state.list = (users || []);
        },
        SET_USER(state, user) {
            if (!user || !user.id)
                return

            state.items[user.id] = user
        },
    },
    actions: {
        // Return default user data object
        getDefault() {
            return {
                name: '',
                first_name: '',
                email: '',
                role: 'user',
                gender: '',
                age: '',
            }
        },
        async getList({ commit }) {
            const response = await apollo.query({ query: GC_GET_USER_LIST })

            commit('SET_USER_LIST', response.data.user)
        },
        async getByID({ commit }, id) {
            let response = await apollo.query({
                query: GC_GET_USER_BY_ID,
                variables: { id }
            })

            if (!response.data.users_by_pk) {
                return
            }

            commit('SET_USER', response.data.users_by_pk)
        },
        async save(context, data) {
            let response = null
            let result = {}

            // Update or add the user
            if (data.user.id) {
                // Remove email for update
                delete data.user.email
                delete data.user.cognito_id
                delete data.user.salesforce_id

                // Update remote data
                response = await apollo.mutate({
                    mutation: GC_UPDATE_USER_BY_ID,
                    variables: data.user
                })

                result.success = true
            } else {
                // try {
                //     // Add user to auth api
                //     response = await axios.post(
                //         authURL + '/adminregister',
                //         {
                //             email: data.user.email,
                //         },
                //         {
                //             headers: {
                //                 'Content-Type': 'application/json',
                //                 'Authorization': 'Bearer ' + context.rootGetters['Auth/userToken']
                //             }
                //         }
                //     )
                // } catch (error) {
                //     return {
                //         error
                //     }
                // }

                // data.user.id = response.data.message

                // Add remote data
                response = await apollo.mutate({
                    mutation: GC_ADD_USER_ONE,
                    variables: data.user
                })

                result.id = response.data.insert_users_one.id
            }

            return result
        },
        async delete(context, pkId, cognitoId) {
            try {
                // Add user to auth api
                const cognitoPromise = axios.post(
                    authURL + '/admindelete',
                    {
                        username: cognitoId,
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + context.rootGetters['Auth/userToken']
                        }
                    }
                )

                const apolloPromise = apollo.mutate({
                    mutation: GC_DELETE_USER_BY_ID,
                    variables: { id: pkId }
                })


                await Promise.all([cognitoPromise, apolloPromise])

                return (await apolloPromise).data.delete_users_by_pk
                // return response
            } catch (error) {
                return {
                    error
                }
            }

        },
        async sendWelcomeEmail(context, { user }) {
            try {
                // Add user to auth api
                let response = await axios.post(
                    authURL + '/adminregister',
                    {
                        email: user.email,
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + context.rootGetters['Auth/userToken']
                        }
                    }
                )
                return response
            } catch (error) {
                return {
                    error
                }
            }
        },
    }
}