import utils from "@/utils/trackingReportTemplate"

import extraUsersData from "./extraUsersData"
// import extraCoursesData from "../common/extraCoursesData"

import usersDashboardData from "./usersDashboardData"
import userActivityDashboardData from "./userActivityDashboardData"

// import usageSheet from "../common/usageSheet"
// import progressionSheet from "../common/progressionSheet"
// import usersSheet from "../common/usersSheet"

// One day in ms
const oneDay = (1 * 60 * 60 * 1000 * 24)

export default {
	id: 'main',
	types: {
		float: '#0.00',
		percentage: '0.00%',
		duration: {
			format: '[h]"h" mm"min"',
			transform: (time) => (time / oneDay)
		},
		date: {
			format: 'dd/MM/yyyy "à" HH"h"mm',
			transform: (time) => (time ? ((time / (86400 * 1000)) + 25569) : false) // Excel datetime value format
		}
	},
	remoteData: {
		course: {
			module: 'Logs',
			action: 'GetCourseTrackingData',
		},
		users: {
			module: 'Logs',
			action: 'GetCourseUsersTrackingData',
			payload: {
				roles: ['user'],
				logTypes: ['login', 'logout', 'mail', 'activity', 'scenario', 'complete_scenario', 'course', 'module', 'sequence_end', 'game_end']
			},
			groupByUniq: 'id'
		},
	},
	extraData: {
		course: {
			sequenceById(course) {
				return utils.groupByUniq(course.modules[0].sequences, 'id')
			},
		},
		firstCreatedUserDate(data) {
			if (!data.users || data.users.length <= 0)
				return (new Date())

			let firstCreatedTime = (new Date(data.users[0].created_at)).getTime()

			data.users.forEach((user) => {
				const createdTime = (new Date(user.created_at)).getTime()

				if (createdTime < firstCreatedTime) {
					firstCreatedTime = createdTime
				}
			})

			return (new Date(firstCreatedTime))
		},
		users: extraUsersData,
		confirmedUsers(data) {
			return data.users.filter((user) => {
				return user.cognito_confirmed
			})
		},
		usersDashboardData,
		userActivityDashboardData,
		userProgressData(data) {
			return {
				average: utils.averageField(data.confirmedUsers, 'progress'),
			}
		},
		userSuccessRateData(data) {
			return {
				average: utils.averageField(data.confirmedUsers, 'successRate'),
			}
		},
		sequenceData(data) {
			return utils.groupMap(data.course.sequenceById, (sequence) => {
				const activeTimeCount = utils.sumValues(data.confirmedUsers, (user) => {
					return (user.activeTimeData.timesBySequence[sequence.id]?.length || 0)
				})
				const activeTimeSum = utils.sumValues(data.confirmedUsers, (user) => {
					return (user.activeTimeData.timeBySequence[sequence.id] || 0)
				})

				return {
					activeTime: {
						count: activeTimeCount,
						average: activeTimeSum / (activeTimeCount || 1),
					},
					firstSuccessRate: {
						average: utils.averageField(data.confirmedUsers, 'firstSuccessRatePerSequence', sequence.id),
					},
					successRate: {
						average: utils.averageField(data.confirmedUsers, 'successRatePerSequence', sequence.id),
					},
				}
			})
		},
	},
	sheets: [
		// usageSheet,
		// progressionSheet,
		// usersSheet,
	]
}