<template>
	<div class="gap-fill">
		<div class="flex flex-row relative justify-center items-center mb-5">
			<div class="flex items-center flex-1 flex-col m-5 ml-10 mr-10">
				<div class="top-block w-full">
					<span class="font-principal-bold text-sm uppercase">Texte</span>
					<div class="editor border-line-top rounded-sm">
						<editor-content
							ref="editor-content"
							class="editor__content"
							:editor="editor"
							@delete-answer="DeleteAnswer"
						/>
					</div>
				</div>
				<div class="bottom-block w-full flex flex-column mt-5">
					<b-table-lite
						class="table w-11/12 mt-5"
						borderless
						:items="answers"
						:fields="fields"
					>
						<template v-slot:cell(index)="data">
							<div
								class="bg-principal-selected mt-1 number rounded-full w-5 h-5 flex-center text-xs"
							>
								{{ data.index + 1 }}
							</div>
						</template>
						<template v-slot:cell(text)="data">
							<div class="flex">
								<input
									class="words border-line-top rounded-sm mr-4"
									type="text"
									:value="data.item.text.split(';')[0]"
									@input="updateHoleText({ data, e: $event })"
								/>
								<div
									@click="DeleteAnswer(data.index)"
									class="cursor-pointer"
								>
									<b-icon-x></b-icon-x>
								</div>

							</div>
						</template>
						<!-- <template v-slot:cell(authorized_values)="data">
							<v-select
								taggable
								multiple
								@input="setAValue($event, data.index)"
								:value="getValues(data.index)"
								class="style-chooser w-full text-xs"
							></v-select>
						</template> -->
					</b-table-lite>
					
					<editor-menu-bar :editor="editor" v-slot="{ commands }">
						<div
							@click="addAnswer(commands.hole)"
							class="plus my-auto m-auto rounded-full w-10 h-10 flex justify-center items-center cursor-pointer"
						>
							+
						</div>
					</editor-menu-bar>
					<div class="my-4">
						Correction:
						<b-form-textarea v-model="correction"></b-form-textarea>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { Editor, EditorContent, EditorMenuBar } from "tiptap"
import Hole from "@/tiptap/Hole.js"

export default {
	name: "TexteTrou",
	components: {
		EditorContent,
		EditorMenuBar
	},
	props: {
		game: {
			type: Object,
			required: true,
			default: null
		}
	},
	beforeDestroy() {
		this.editor.destroy()
	},
	mounted() {
		this.editor = new Editor({
			extensions: [
				// custom extension
				new Hole()
			],
			onBlur: this.updateText,
			onUpdate: this.checkForDeleteAnswer
		})
		// console.log(object);
		if (this.question && this.question.text != "") {
			this.editor.setContent(this.question.text)
			this.editorJSON = this.editor.getJSON()
		} else {
			this.$emit("add-questions", [
				{
					answers: [],
					text: "",
					correction: ""
				}
			])
		}
	},
	data() {
		return {
			editor: null,
			editorJSON: [],
			fields: [
				{
					key: "index",
					label: "",
					sortable: false
				},
				{
					key: "text",
					label: "Mots",
					sortable: false
				},
				// {
				// 	key: "authorized_values",
				// 	label: "Valeurs acceptées",
				// 	sortable: false
				// }
			],
			holes: []
		}
	},
	computed: {
		question: {
			get() {
				if (!this.game || (this.game && this.game.questions.length == 0))
					return null
				return JSON.parse(JSON.stringify(this.game.questions[0]))
			},
			set(value) {
				this.$emit("update-question", 0, value)
			}
		},
		answers: {
			get() {
				if (!this.game || (this.game && this.game.questions.length == 0))
					return []
				return this.question.answers
			},
			set(value) {
				this.$emit("update-question", 0, {
					answers: value
				})
			}
		},
		correction:{
			get(){
				return this.game.correction
			},
			set(value){
				this.$emit('update-correction', value)
			}
		},
	},
	methods: {
		addAnswer(command) {
			console.log('command:', command)
			let newAnswers = [...this.answers]
			newAnswers.push({
				text: "",
				is_correct: false
			})
			this.$emit("update-question", 0, {
				answers: newAnswers
			})
			command({ holeid: newAnswers.length })
		},
		checkForDeleteAnswer() {
			this.editorJSON = this.editor.getJSON()
			let tmp = []
			this.editorJSON.content.forEach((line) => {
				if (line.content) {
					line.content.forEach((el) => {
						if (el.type === "hole") {
							tmp.push(el.attrs.holeid)
						}
					})
				}
			})
			this.holes = [...tmp]
		},
		updateText(e) {
			if (this.question) {
				this.$emit("update-question", 0, {
					text: e.event.target.innerHTML
				})
			}
		},
		updateHoleText(data) {
			let answers = [...this.answers]
			let i = data.data.index
			if (answers[i]) {
				let splittedAnswer = answers[i].text.split(";")
				splittedAnswer[0] = data.e.target.value
				let joinedVal = splittedAnswer.join(";")
				answers[i].text = joinedVal
				this.answers = answers
			}
		},
		setAValue(e, index) {
			let answers = [...this.answers]
			if (answers[index]) {
				let splittedAnswer = answers[index].text.split(";")
				let newArr = [splittedAnswer[0], ...e]
				let joinedVal = newArr.join(";")
				answers[index].text = joinedVal
				this.answers = answers
			}
		},
		getValues(index) {
			if (this.answers && this.answers[index] && this.answers[index].text) {
				let arr = this.answers[index].text.split(";")
				if (arr) {
					let set = new Set(arr.filter((o, i) => i !== 0))
					return [...set]
				}
			}
			return []
		},
		DeleteAnswer(index) {
			if (this.answers[index]) {
				let newAnswers = [...this.answers]
				// console.log('newAnswers:', newAnswers)
				newAnswers.splice(index, 1)
				this.answers = newAnswers

				this.editorJSON.content.forEach((line) => {
					if (line.content) {
						line.content.forEach((el, i) => {
							if (el.type === "hole" && el.attrs.holeid > index + 1) {
								el.attrs.holeid--
							}
						})
						let hole = document.querySelector(`[data-id="${index+1}"]`);
						hole.remove()
						this.$emit("update-question", 0, {
							text: this.$refs['editor-content'].$el.innerHTML
						})
					}
				})
			}
		}
	},
	watch: {
		holes(newVal, oldVal) {
			if (newVal !== oldVal) {
				//update answer list
				let tmp = [...newVal]
				let sortedList = tmp.sort((a, b) => a - b)

				for (let i = 0; i < this.answers.length; i++) {
					if (i !== sortedList[i] - 1) {
						this.DeleteAnswer(i)
						return
					}
				}
			}
		}
	}
}
</script>
<style lang="scss">
.top-block{
	.editor {
		cursor: text;
		border-width: 1px;
		.editor__content {
			.ProseMirror {
				min-height: 100px;
				@apply p-2;
				mark.hole {
					@apply rounded-full px-1 py-0 bg-principal-selected;
				}
			}
		}
	}
}

.gap-fill {
	.words {
		border-width: 1px;
	}

	.style-chooser {
		border: 1px solid #e1e1e1;
		border-radius: 3px;
	}
}
</style>
