import utils from "@/utils/trackingReportTemplate"

export default {
	name: 'Promotions',
	content: [
		{
			table: 'groups',
			headers: [
				'Nom de promotion',
				'Parcours associé',
				'Produit (SOGI)',
				'Type',
				'Date de début',
				'date de fin (par défaut = début + 12 mois)',
				'Nb d\'inscrits',
				'statut',
				'Nb de comptes actifs',
				'Taux de compte confirmé',
				'Durée moyenne de connexion par utilisateur',
				'Taux de complétion moyen',
				'Taux de réussite moyen',
				// 'Taux d\'assiduité moyen',
			],
			row: (group, data) => {
				const row = [
					{ value: group.title },
				]

				if (group.course) {
					row.push({ value: group.course.name })

					// Get Salesforce product from metas
					const product = group.course.metas.find((meta) => data.metaTypesById[meta.meta_type_id].slug === 'sf_product')
					row.push({ value: (product ? product.value : '-') })
				} else {
					row.push({ value: '-' })
					row.push({ value: '-' })
				}

				// Get group users
				const groupUsers = data.users.filter((user) => {
					return user.groupsById[group.id]
				})

				const groupConfirmedUsers = groupUsers.filter((user) => {
					return user.cognito_confirmed
				})

				// Get group status
				const now = new Date()
				const start = new Date(group.start_date)
				const end = new Date(group.end_date)

				const status = (now < start ? 'À venir' : (now > end ? 'Passé' : 'En cours'))

				const userProgressList = groupConfirmedUsers.reduce((list, user) => {
					user.groups.forEach((userGroup) => {
						if (user.progressPerGroup[userGroup.id]) {
							list.push(user.progressPerGroup[userGroup.id])
						}
					})
					return list
				}, [])

				return row.concat([
					{ value: (group.salesforce_id ? 'SOGI' : 'LMS') },
					{ value: (new Date(group.start_date)).getTime(), type: 'date' },
					{ value: (new Date(group.end_date)).getTime(), type: 'date' },
					{ value: groupUsers.length },
					{ value: status },
					{ value: utils.countMatch(groupUsers, (user) => user.state === 'active') },
					{ value: (groupConfirmedUsers.length / (groupUsers.length || 1)), type: 'percentage' },
					{ value: utils.averageField(groupConfirmedUsers, 'activeTimeData', 'average', true), type: 'duration' },
					// Make an averrage of user progress
					{ value: utils.averageValues(userProgressList, null, true), type: 'percentage' },
					{ value: utils.averageField(groupConfirmedUsers, 'successRate', null, true), type: 'percentage' },
					// { value: 'Taux d\'assiduité moyen' },
				])
			},
		}
	]
}