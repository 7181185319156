import {GC_GET_ASSET_LIST, GC_GET_TYPE_LIST, GC_INSERT_MEDIA_ONE, GC_DELETE_MEDIA_ONE} from '@/graphql/assets'
import apollo from '@/apolloClient'
import axios from 'axios'

const dataURLtoFile = (dataurl, filename) => {
 
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
    
}

const sanitizeFileName = (filename) => {
    return filename.replace(/\s/g, '_').replace(/[^a-zA-Z0-9-_.]/g, '')
}

export default {
    namespaced: true,
    state: {
        assets: [],
        types: [],
        loading: true
    },
    getters:{
        assetList(state){
            return state.assets;
        },
        typesList(state){
            return state.types;
        }
    },
    mutations: {
        SET_ASSET_LIST(state, assetList){
            // Generate asset urls
            assetList.map(asset => {
                if (asset.path){
                    asset.url = process.env.VUE_APP_AWS_S3_URL + '/' + asset.path
                }
                if (asset.thumbnail_path){
                    asset.thumbnail_url = process.env.VUE_APP_AWS_S3_URL + '/' + asset.thumbnail_path;
                }
            })

            state.assets = (assetList || []);
        },
        ADD_ASSET(state, asset){
            // Generate asset urls
            if (asset.path){
                asset.url = process.env.VUE_APP_AWS_S3_URL + '/' + asset.path
            }
            if (asset.thumbnail_path){
                asset.thumbnail_url = process.env.VUE_APP_AWS_S3_URL + '/' + asset.thumbnail_path;
            }

            state.assets.unshift(asset)
        },
        SET_TYPES_LIST(state, types){
            state.types = types;
        }
    },
    actions: {
        async getAssetList({commit}){
            const response = await apollo.query({query: GC_GET_ASSET_LIST})
            commit('SET_ASSET_LIST', response.data.media)
            return true
        },
        addAsset({ commit }, asset){
            commit('ADD_ASSET', asset)
        },
        async getTypesList({commit}) {
            const response = await apollo.query({query: GC_GET_TYPE_LIST})
            commit('SET_TYPES_LIST', response.data.media_type)
            return true
        },
        getSignedUrl(context, file) {
            if (!file.basePath) {
                file.basePath = 'assets'
            }

            let endpoint = process.env.VUE_APP_API + 'upload'
            const filePath = `${file.basePath}/${sanitizeFileName(file.name)}`
            let payload = {
                filePath,
                contentType: file.type
            }

            return axios.post(endpoint, payload)
                .then((res) => {
                    return Promise.resolve(res.data.url || '/')
                })
                .catch((err) => {
                    console.error(err)
                    return Promise.reject('/')
                })
        },
        deleteFile({dispatch}, asset){

            //delete from db

            const resp = apollo.mutate({
                mutation: GC_DELETE_MEDIA_ONE,
                variables: {id: asset.id}
            })

            return resp.then(res => {
                if (res.data.delete_media && res.data.delete_media.affected_rows != 0){
                    dispatch('Logs/ContentLog', {
                        id: asset.id,
                        action: 'content_delete'
                    }, {root:true})
                    return dispatch('deleteFileFromAWS', asset);
                }
            })

        },
        async deleteFileFromAWS(context, asset){
            // todo: not done in api yet :)
            // try {
            //     await awsAxios.getAWSDeleteRequestForFilePath(asset.path)

            //     if (asset.thumbnail_path && asset.thumbnail_path.trim() != '') {
            //         await awsAxios.getAWSDeleteRequestForFilePath(asset.thumbnail_path)
            //     }
            // } catch (error) {
            //     return false
            // }

            return true
        },
        getThumbnail({state, commit}, asset){
            if (asset.thumbnail_path){
                asset.thumbnail_url = process.env.VUE_APP_AWS_S3_URL + '/' + asset.thumbnail_path;
            }
        },
        getAssetFromPath({commit}, assetPath){
            if (assetPath){
                return process.env.VUE_APP_AWS_S3_URL + '/' + assetPath;
            }
        },
        getAssetFromID({state}, id){
            if (id && state.assets){
                return state.assets.find(asset => asset.id === id)
            }
        },
        async sendFileToAWS({dispatch}, file){
            let url = await dispatch('getSignedUrl', file)

            return await axios.put(url, file, {
                headers: {
                    'Content-Type': (file.type || 'text/plain')
                }
            })
        },
        setFileThumbnail(context, data){
            let file
            let filePath
            if (data.thumbnail){
                file = dataURLtoFile(data.thumbnail, 'thumbnail_' + sanitizeFileName(data.file.name));
                file.basePath = 'thumbnails'
            } else {
                file = data
                var blob = file.slice(0, file.size, data.type);
                file = new File([blob], 'thumbnail_' + sanitizeFileName(data.name), {type: data.type});
                file.basePath = 'thumbnails'
            }

            context.dispatch('getSignedUrl', file)
                .then(url => {
                    if (!url)
                        return Promise.reject(new Error('No signed URL found'))

                    return axios.put(url, file, {
                        headers: {
                            'Content-Type': file.type
                        }
                    })
                        .then(() => {
                            return Promise.resolve();
                        })
                        .catch(err => {
                            return Promise.reject(err);
                        })
                })
                .catch(err => {
                    return Promise.reject(err);
                })

            return `thumbnails/${sanitizeFileName(file.name)}`;
        },
        async sendFileToHasura({dispatch}, file){
            let res = await apollo.mutate({
                mutation: GC_INSERT_MEDIA_ONE,
                variables: {
                    name: file.name,
                    path: `assets/${sanitizeFileName(file.name)}`,
                    tPath: file.thumbnail_path || '',
                    type: file.assetType.id
                }
            })
            if (res.data.insert_media_one.id){
                dispatch('Logs/ContentLog', {
                    id: res.data.insert_media_one.id,
                    action: 'content_create'
                }, {root:true})
            }
            return res;
        }
    }
}