<template>
    <div>
        <cooc-list 
            listing="users" 
            :fields="fields"
            :where="whereClause"
            :filters="filters" 
            searchText="Rechercher un utilisateur"
            :with-delete-button="false"
            modify-button-text="Éditer la fiche Utilisateur"
        >
            <template v-slot:buttons>
                <div class="md:flex flex-row justify-around hidden user-buttons w-1/3">
                    <button v-if="userInfo.role !== 'instructor'" @click="addUser" type="button" class="hidden md:block twn-button whitespace-no-wrap overflow-hidden text-xs mr-2">Ajouter un utilisateur</button>
                    <button @click="importUserList" type="button" class="twn-button hidden md:block whitespace-no-wrap overflow-hidden text-xs">Importer des utilisateurs</button>
                </div>
            </template>
            <template v-slot:fab>
                <vue-fab
                    class="md:hidden"
                    fabItemAnimate="alive"
                    fabAliveAnimateBezier="ease-in-out"
                    mainBtnColor="#fff"
                    size="big"
                >
                    <fab-item class="twn-fab import" @clickItem="importUserList" color="#888888" :idx="2" title="Importer des utilisateurs" icon="add" />
                    <fab-item class="twn-fab add" @clickItem="addUser" color="#DC9799" :idx="0.5" title="Ajouter un utilisateur" icon="add" />
                </vue-fab>
            </template>
        </cooc-list>
    </div>
</template>
<script>

import { mapActions, mapState } from 'vuex';
import List from '@/components/List'
export default {
    name: 'UserList',
    data() {
        return {
            fields: [
                {
                    key: 'name',
                    label: 'Nom',
                    sortable: true
                },
                {
                    key: 'first_name',
                    label: 'Prénom',
                    sortable: true,
                },
                {
                    key: 'email',
                    label: 'Email',
                    sortable: true,
                },
                {
                    key: 'role',
                    sortable: true,
                    formatter: (value) => {
                        switch (value){
                            case 'user':
                                return "Utilisateur"
                            case 'organisationadmin':
                                return "OfficeAdmin"
                            case 'superadmin':
                                return "SuperAdmin"
                            case 'afpols':
                                return "Gestionnaire"
                            case 'instructor':
                                return "Formateur"
                        }
                    }
                },
                {
                    key: 'salesforce_id',
                    label: 'Type',
                    sortable: true,
                    formatter: (value) => {
                        if(value) return 'SOGI'
                        else return 'LMS'
                    }
                },
                {
                    key: 'logs_aggregate',
                    label: 'Statut',
                    sortable: true,
                    db: `logs_aggregate(where: {
                        logType: {
                            slug: {
                                _neq: "mail"
                            }
                        }
                    }) {
                        aggregate {
                            count
                        }
                    }`,
                    nested: 'count',
                    formatter: (value) => {
                        if (value > 0) {
                            return 'actif'
                        }
                        else {
                            return 'inactif'
                        }
                    },
                },
                {
                    key: 'promotion_users_aggregate',
                    db: `promotion_users_aggregate{
                        aggregate {
                            count
                        }
                    }`,
                    label: 'Promotion(s)',
                    nested: 'count',
                    sortable: true,
                },
                // {
                //     key: 'organization',
                //     db: `organization:userOrganisations{
                //         organisations{
                //             name
                //         }
                //     }`,
                //     nested: 'name',
                //     label: 'Office(s)',
                //     sortable: true,
                //     formatter: (value) => {
                //         return Array.isArray(value) ? value.join(' | ') : value
                //     }
                // },

            ],
            filters: [                
                {
                    key:'role',
                    label: 'Rôle',
                    list: [
                        {value: 'user', role: "Utilisateur"},
                        {value: 'afpols', role: "Gestionnaire"},
                        {value: 'instructor', role: "Formateur"},
                        {value: 'superadmin', role: "Super-Admin"},
                    ]
                },
            ]
        }
    },
    props:{
        previousPage: {
            type: Number,
            required: false,
            default: null
        }
    },
    computed: {
        ...mapState('Auth', [ 'userInfo' ]),
        whereClause() {
            let where = null
            if (this.userInfo.role === 'instructor') where = `promotion_users: { promotion: { course: { course_instructors: { instructor_id: { _eq: "${this.userInfo.id}" } } } } }`
            return where
        }
        
    },
    components: {'cooc-list':List},
    methods:{
        ...mapActions('EditItem', ['clearCurrentID']),
        addUser(){
            this.clearCurrentID();
            this.$router.push({name:'users-create'});
        },
        importUserList(){
            // console.log("IMPORT USER LIST");
        }
    }
}
</script>
<style lang="scss">
// .user-buttons{
//     flex-basis: 33%;
// }
</style>