<template>
	<div class="course-detail" :key="refresh">
		<div class="buttons flex flex-row justify-around md:justify-between">
			<button type="button" class="twn-button text-xs" @click="goBack">Retour</button>
			<div class="ml-auto">
				<button
					type="button"
					class="twn-button text-xs danger mr-4"
					@click="showDeleteModal"
					v-if="!creating"
				>Supprimer le parcours</button>
				<button class="twn-button text-xs" @click="save">Enregistrer</button>
				<button class="twn-button text-xs" @click="save(true)">Dupliquer</button>
			</div>
		</div>

		<div class="my-10">
			<div class="flex">
				<div
					v-if="creating"
					class="uppercase font-principal-bold text-sm"
				>Création d'un nouveau parcours</div>
				<div v-else class="uppercase font-principal-bold text-sm">Éditer le parcours</div>
			</div>

			<div class="mt-8">
				<div class="flex items-center">
					<div class="flex-1 mr-8">
						<label>Nom du parcours MyAfpols-Quiz</label>
						<b-form-input v-model="fields.name" type="text" />
					</div>

					<!-- <div class="flex-1 mr-8">
						<label class="mr-8">Type de parcours</label>
						<v-select
							class="flex-1 mr-auto"
							label="name"
							:options="types"
							placeholder="Type du parcours"
							v-model="fields.type"
							@input="changeType"
							:reduce="type => type.id"
							:selectable="type => type.slug != 'custom_course'"
						/>
					</div>-->
				</div>
				<div class="mt-2">
					<div class="flex items-center">
						<div class="flex-1 mr-8">
							<label>Formateurs :</label>
							<v-select
								:disabled="currentRole === 'instructor'"
								:reduce="instructor => instructor.id"
								class="flex-1 mr-auto"
								multiple
								:options="getInstructors"
								v-model="fields.course_instructors"
								:getOptionLabel="getInstructorLabel"
							></v-select>
							<!-- <b-form-input v-model="fields.name" type="text" /> -->
						</div>
					</div>
				</div>

				<div class="mt-2">
					<div class="flex-1 flex-col mr-8">
						<label for="product-list">Produit associé dans Saleforce</label>
						<v-select
							id="product-list"
							placeholder="Aucun produit Salesforce"
							:options="paginatedProductList"
							:getOptionLabel="getOptionLabel"
							:reduce="product => product.id"
							:value="getMetaValue('sf_product')"
							:filterable="false" 
							@input="confirmUpdateProduct($event)"
							@search="onProductListSearch"
						>
							<li
								slot="list-footer"
								class="flex"
								:class="{ hidden: (!hasPrevPage && !hasNextPage) }"
							>
								<button
									class="twn-button twn-button-small"
									:disabled="!hasPrevPage"
									@click="productListOffset -= productListLimit"
								>
									Page précédente
								</button>
								<button
									class="twn-button twn-button-small"
									:disabled="!hasNextPage"
									@click="productListOffset += productListLimit">
									Page suivante
								</button>
							</li>
						</v-select>
					</div>
				</div>

				<div class="mt-8" v-if="courseType">
					<!-- module choice -->
					<div v-if="courseType != 'bloc_course' && fields.modules[activeModule]">
						<div class="flex flex-row w-full my-4">
							<div class="w-1/4 pr-8">
								<label for="module-name">Identifiant</label>
								<input
									type="text"
									id="module-name"
									class="d-block twn-input w-full"
									v-model="fields.modules[activeModule].name"
								/>
							</div>
						</div>

						<div v-if="courseType == 'complete_course'" class="w-full">
							<label for="module-scenario" class>Scénarios disponible dans ce niveau</label>
							<v-select
								id="module-scenario"
								:options="scenarioOptions"
								@search="fetchScenariosByName"
								multiple
								label="name"
								class
								v-model="fields.modules[activeModule].scenarios"
							></v-select>
						</div>

						<!-- <button class="twn-button danger py-2 my-4 ml-auto block" @click="showDeleteModuleModal">Supprimer le {{ (courseType == 'complete_course' ? 'niveau' : 'module') }}</button> -->
					</div>

					<span class="font-principal-medium">Rappels associées</span>
					<div
						v-if="
							fields.modules[activeModule].sequences && fields.modules[activeModule].sequences.length > 0
						"
					>
						<!-- <draggable v-model="fields.modules[activeModule].sequences" @input="changeSequenceOrder"> -->
						<SequenceForm
							v-for="(sequence, j) in fields.modules[activeModule].sequences"
							:key="j"
							:sequence="sequence"
							:course-type="courseType"
							:scenario-list="scenarioList"
							:media-list="mediaList"
							@remove-sequence="removeSequence(j)"
							@add-sequence="addSequence(j)"
						/>
						<b-icon
							icon="plus"
							@click="addSequence"
							color="white"
							class="add-sequence cursor-pointer h1 mx-auto d-flex flex-center pa-8"
						></b-icon>
						<!-- </draggable> -->
					</div>
					<div v-else></div>
				</div>
			</div>
		</div>
		<div v-if="fields.promotions && fields.promotions.length">
			<p class="font-principal-medium mb-4">Promotions associées</p>
			<div class="promotions d-flex flex-wrap">
				<div class="promotion p-4 mr-4 mb-4" v-for="promotion in fields.promotions" :key="promotion.id">
					<p class="promotion-title">{{ promotion.title }}</p>
					<p
						class="promotion-start"
					>Date d'ouverture : {{ promotion.start_date.split('-').reverse().join('/') }}</p>
					<p class="promotion-start">Statut : {{ getPromotionStatus(promotion) }}</p>
				</div>
			</div>
		</div>

		<div class="my-10" v-if="fields.id">
			<CourseDashboard :id="fields.id" />
		</div>

		<!-- Modals -->
		<b-modal
			ref="delete-modal-course"
			class="bootstrap"
			centered
			hide-footer
			id="delete-modal-course"
			hide-header
		>
			<div class="d-block text-center my-6 uppercase font-semibold">
				<h3>Confirmer la suppression {{ isDeleteModule ? "du " + (courseType == 'complete_course' ? 'niveau' : 'module') : "" }}</h3>
			</div>

			<div v-if="fields.promotions && fields.promotions.length" class="text-center text-red-500 mb-4">
				<p>Attention ! Une ou des promotions sont liées à ce parcours. <br/> Supprimer quand même ?</p>
			</div>

			<div class="flex flex-row justify-evenly items-center">
				<button type="button" class="mt-4 twn-button" @click="hideModal">Retour</button>
				<button type="button" class="mt-4 twn-button danger" @click="confirmDelete">Supprimer</button>
			</div>
		</b-modal>
	</div>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import { debounce } from "@/utils/utils"
// import Draggable from "vuedraggable"
import { SEARCH_SCENARIO } from '@/graphql/course'

import SequenceForm from "@/components/common/course/SequenceFormLight"
import CourseDashboard from '@/components/dashboard/Course'

export default {
	props: {
		itemID: {
			type: String,
			required: false
		},
		previousPage: {
			type: Number,
			required: false
		},
		showLink: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	components: {
		// Draggable,
		SequenceForm,
		CourseDashboard,
	},
	data() {
		return {
			creating: false,
			isDeleteModule: false,
			nameTaken: false,
			fields: {
				id: null,
				course_instructors: [],
				name: null,
				type: null,
				metas: [],
				modules: [
					{
						id: null,
						name: "",
						title: "",
						order: 0,
						scenarios: [],
						sequences: [
							{
								name: "",
								title: "",
								module_scenario_limit: 3,
								order: 0,
								scenarios: [],
								revisions: []
							}
						]
					}
				]
			},
			activeModule: 0,
			scenarioOptions: [],
			revisionOptions: [],
			refresh: 0,
			instructors: [],
			productListOffset: 0,
			productListLimit: 400,
			productListSearch: '',
			initialSFProductValue: null,
		}
	},

	computed: {
		...mapGetters("EditItem", ["getCurrentID"]),
		...mapGetters("Utils", ["getInstructors", "metaTypes"]),
		...mapState("Course", ["types"]),
		...mapState({
			productList: state => state.SFProduct.list,
			scenarioList: state => state.Scenarios.list,
			mediaList: state => state.Media.list,
			currentRole: state => state.Auth.userInfo.role,
		}),
		...mapGetters("Course", ["typeListFormat"]),
		courseType() {
			if (!this.typeListFormat || !this.fields.type || !this.typeListFormat[this.fields.type])
				return null

			return this.typeListFormat[this.fields.type].slug
		},
		filteredProductList() {
			const words = this.productListSearch.trim().toLowerCase().split(' ')

			return this.productList.filter((product) => {
				const productTitle = this.getOptionLabel(product).toLowerCase()

				return words.every((word) => {
					return productTitle.includes(word)
				})
			})
		},
		paginatedProductList() {
			const list = this.filteredProductList.slice(this.productListOffset, this.productListLimit + this.productListOffset)

			const selectedProductId = this.getMetaValue('sf_product')

			// Add selected product to list to avoid display label bug
			if (!list.find((product) => product.id === selectedProductId)) {
				const product = this.productList.find((product) => product.id === selectedProductId)

				if (product) {
					list.unshift(product)
				}
			}

			return list
		},
		hasNextPage() {
			const nextOffset = this.productListOffset + this.productListLimit

			return Boolean(this.filteredProductList.slice(nextOffset, this.productListLimit + nextOffset).length)
		},
		hasPrevPage() {
			const prevOffset = this.productListOffset - this.productListLimit

			return Boolean(this.filteredProductList.slice(prevOffset, this.productListLimit + prevOffset).length)
		},
	},

	created() {
		this.initCourse()
	},

	methods: {
		onProductListSearch(query) {
			this.productListSearch = query
			this.productListOffset = 0
		},
		getOptionLabel(option) {
			if (!option)
				return '~ Élément supprimé ~'

			return [option.identifier, option.name, option.title].filter(str => str || false).join(' - ') || '~ Élément sans titre ou supprimé ~'
		},
		getInstructorLabel(option) {
			if (!option)
				return '~ Utilisateur supprimé ~'

			return [option.first_name, option.name].filter(str => str || false).join(' ') || '~ Utilisateur sans nom ou supprimé ~'
		},
		getMeta(slug) {
			for (var i = 0; i < this.fields.metas.length; i++) {
				if (this.fields.metas[i].meta_type_id == this.metaTypes[slug].id) {
					return { meta: this.fields.metas[i], index: i }
				}
			}

			return { meta: null, index: -1 }
		},
		getMetaValue(slug) {
			const { meta } = this.getMeta(slug)

			return ((meta && meta.value) || null)
		},
		setMetaValue(slug, value) {
			let { meta, index } = this.getMeta(slug)

			if (meta) {
				if (value) {
					meta.value = value
				} else {
					this.fields.metas.splice(index, 1)
				}
			} else if (value) {
				this.fields.metas.push({
					value,
					meta_type_id: this.metaTypes[slug].id
				})
			}
		},
		confirmUpdateProduct(event) {
			// Update value first to be able to restore initial value if needed
			this.setMetaValue('sf_product', event)

			if (this.initialSFProductValue) {
				if (confirm('La modification du produit peux empécher la synchronisation de nouvelle promotion associées à l\'ancien produit.\n\nEst ce que vous voulez vraiment modifier le produit associé ?')) {
					// Disable initial value check if warning has been shown and confirmed
					this.initialSFProductValue = null
				} else {
					// Restore initial value
					this.setMetaValue('sf_product', this.initialSFProductValue)
				}
			}
		},
		async confirmDelete() {
			if (this.isDeleteModule) {
				this.deleteModule()
			} else {
				await this.$store.dispatch("Course/Delete", this.itemID)
				this.goBack()
			}

			this.hideModal()
		},
		fetchScenariosByName(name, loading) {
			loading(true)
			this.searchScenario(loading, name, false, this)
		},

		fetchRevisionByName(name, loading) {
			loading(true)
			this.searchScenario(loading, name, true, this)
		},

		searchScenario: debounce((loading, name, isRevision, vm) => {
			vm.getScenario(name, isRevision).then(() => loading(false));
		}, 350),

		getScenario(name, isRevision) {
			return new Promise((resolve) => {
				this.$apollo.query({
					query: SEARCH_SCENARIO,
					variables: {
						name: `%${name}%`,
						slug: isRevision ? 'revision' : 'scenario' //todo: not revision instead of only scenario
					}
				}).then((res) => {
					if (res.data) {
						if (isRevision) {
							this.revisionOptions = res.data.scenario
							resolve()
						} else {
							this.scenarioOptions = res.data.scenario
							resolve()
						}
					}
				})
			})
		},

		goBack() {
			this.$router.push({
				name: 'course-list', params: {
					previousPage: this.previousPage
				}
			})
		},

		async mapFields() {
			const fields = await this.$store.dispatch("Course/GetCourse", this.itemID)

			fields.modules = fields.modules.map(module => {
				let moduleret = module

				moduleret.scenarios = module.scenarios.map(sc => {
					return {
						id: sc.scenario.id,
						name: sc.scenario.name
					}
				})
				moduleret.sequences = module.sequences.map(sequence => {
					let sequenceret = sequence;
					const scenarios = sequence.scenarios.map(sc => {
						return {
							id: sc.scenario.id,
							name: sc.scenario.name,
							order: sc.order,
							type: sc.scenario.type,
						}
					}).sort((a, b) => {
						return (a.order > b.order ? 1 : (a.order < b.order ? -1 : 0))
					})

					sequenceret.scenarios = scenarios.filter((sc) => {
						return (sc.type.slug != 'revision')
					})

					sequenceret.revisions = scenarios.filter((sc) => {
						return (sc.type.slug == 'revision')
					})

					sequenceret.medias = sequence.medias.map(assoc => {
						return {
							id: assoc.media.id,
							name: assoc.media.name,
							order: assoc.order,
							type: assoc.media.type,
						}
					}).sort((a, b) => {
						return (a.order > b.order ? 1 : (a.order < b.order ? -1 : 0))
					})

					return sequenceret
				})
				return moduleret;
			})

			fields.course_instructors = fields.course_instructors.map(ci => ci.instructor_id)

			this.fields = fields
			this.initialSFProductValue = this.getMetaValue('sf_product')

			this.changeType(this.fields.type);
		},

		showDeleteModal() {
			this.$refs["delete-modal-course"].show()
		},
		showDeleteModuleModal() {
			this.isDeleteModule = true
			this.$refs["delete-modal-course"].show()
		},
		removeSequence(index) {
			//remove
			if (this.fields.modules[this.activeModule].sequences.length > 1) {
				this.fields.modules[this.activeModule].sequences.splice(index, 1)
			}

			this.fields.modules[this.activeModule].sequences.forEach((sequence, i) => {
				if (i >= index) {
					//
					sequence.order--
				}
			})
		},
		addSequence() {
			//Add
			this.fields.modules[this.activeModule].sequences.push({
				id: null,
				name: "",
				module_scenario_limit: 2,
				order: this.fields.modules[this.activeModule].sequences.length,
				scenarios: [],
				revisions: []
			})
		},
		changeType(typeID) {
			if (this.typeListFormat[typeID] && this.typeListFormat[typeID].slug == "bloc_course") {
				this.activeModule = 0
			}
		},
		addModule() {
			this.fields.modules.push(this.defaultModule(this.fields.modules.length))
			this.activeModule = this.fields.modules.length - 1
		},
		defaultModule(order = 0) {
			return {
				id: null,
				name: "",
				title: "",
				scenarios: [],
				order,
				sequences: [
					{
						name: "",
						title: "",
						order: 0,
						module_scenario_limit: 2,
						scenarios: [],
						revisions: [],
						medias: [],
					}
				]
			}
		},
		deleteModule() {
			if (this.fields.modules.length > 1) {
				this.fields.modules.splice(this.activeModule, 1)
				this.fields.modules.forEach((module, i) => {
					if (i >= this.activeModule) {
						module.order--
					}
				})
				if (this.activeModule - 1 >= 0) {
					this.activeModule -= 1
				}

				this.$forceUpdate();
			}
		},
		async save(duplicate = false) {
			if (this.fields.name && this.fields.name !== "") {
				if (duplicate === true) {
					delete this.fields.id
					this.fields.name += ' (copie)'
					this.fields.modules = this.fields.modules.map(mod => {
						delete mod.id
						mod.name += ' (copie)'
						if (mod.sequences.length) {
							mod.sequences = mod.sequences.map(seq => {
								delete seq.id
								seq.name += ' (copie)'
								return seq
							})
						}
						return mod
					})

				}
				const response = await this.$store.dispatch('Course/saveCourse', this.fields)
				if (response && response.error) {
					this.$bvToast.toast('Une erreur est survenue, l\'un des champs requis doit être manquant', { title: `Erreur` })
					return;
				}

				if (response == false) {
					this.nameTaken = true
					this.$bvToast.toast('Le nom est déjà pris !', { title: `Erreur` })
					return;
				}

				if (this.creating && response.id) {
					this.fields.id = response.id
					this.creating = false
					this.$router.push({
						name: 'course-edit',
						params: {
							itemID: response.id,
							previousPage: this.previousPage
						}
					})
				}

				this.$bvToast.toast('Vos modifications ont bien été enregistrés !', { title: `Succès !` })
			}
		},
		changeSequenceOrder(sequences) {
			this.fields.modules[this.activeModule].sequences = sequences.map((sq, i) => {
				let sequence = sq
				sequence.order = i
				return sequence
			})
		},
		hideModal() {
			this.$bvModal.hide('delete-modal-course')
			this.isDeleteModule = false
		},
		async initCourse() {
			if (!this.itemID) {
				this.creating = true
				this.initialSFProductValue = null
			} else this.creating = false
			await this.$store.dispatch("Course/GetCourseTypes")
			this.$store.dispatch("Scenarios/getList")
			this.$store.dispatch("Media/getList")
			this.$store.dispatch("SFProduct/getList")
			this.getScenario("", false)
			this.getScenario("", true)
			this.fields.type = this.types[0].id
			if (this.itemID) {
				this.mapFields()
			}
		},
		getPromotionStatus({ start_date, end_date }) {
			const now = new Date()
			const start = new Date(start_date)
			const end = new Date(end_date)

			return (now < start ? 'À venir' : (now > end ? 'Passé' : 'En cours'))
		}
	},
	watch: {
		itemID(id) {
			if (id) this.initCourse()
		}
	},
}
</script>
<style lang="scss" scoped>
.course-detail {
	.shadowed {
		box-shadow: $cardShadow;
	}
	.module-container {
		// border: 1px solid #DC9799;
		box-shadow: $cardShadow;
		padding: 1rem;
	}
	.module-selector {
		@apply flex flex-no-wrap flex-row mb-8;
	}
	.module-select-container {
		@apply w-full font-principal-medium flex justify-center items-center;

		&.active {
			@apply bg-principal-selected text-white;
		}
	}
	// .content{
	//     border-top: 1px solid $textMainColor;
	//     border-bottom: 1px solid $textMainColor;
	// }

	.add-sequence {
		background: #dc9799;
		border-radius: 50%;
	}
	.promotions {
		.promotion {
			min-width: 200px;
			width: 20%;
			border: 1px solid black;
			border-radius: 5px;
		}
	}
}
</style>
