import apollo from '@/apolloClient'

import {
    GC_GET_PROMOTION_TYPE_LIST,
    GC_GET_PROMOTION_LIST,
    GC_GET_PROMOTION_BY_ID,
    GC_ADD_PROMOTION_ONE,
    GC_UPDATE_PROMOTION_BY_ID,
    GC_DELETE_PROMOTION_BY_ID,
} from '@/graphql/promotion'

export default {
    namespaced: true,
    state: {
        list: [],
        items: {},
        typeList: [],
    },
    mutations: {
        SET_PROMOTION_LIST(state, promotions) {
            state.list = (promotions || []);
        },
        SET_PROMOTION_TYPE_LIST(state, types) {
            state.typeList = types;
        },
        SET_PROMOTION(state, promotion) {
            if (!promotion || !promotion.id)
                return

            state.items[promotion.id] = promotion
        },
    },
    actions: {
        async getTypeList({ commit }) {
            const response = await apollo.query({ query: GC_GET_PROMOTION_TYPE_LIST })

            commit('SET_PROMOTION_TYPE_LIST', response.data.promotion_type)
        },
        async getList({ commit }) {
            const response = await apollo.query({ query: GC_GET_PROMOTION_LIST })

            commit('SET_PROMOTION_LIST', response.data.promotion)
        },
        async getByID({ commit }, id) {
            let response = await apollo.query({
                query: GC_GET_PROMOTION_BY_ID,
                variables: { id }
            })

            if (!response.data.promotion_by_pk) {
                return
            }

            commit('SET_PROMOTION', response.data.promotion_by_pk)
        },
        async save(context, data) {
            let response = null
            let result = {}

            let promotion = {
                title: data.title,
                start_date: data.startDate,
                end_date: data.endDate,
                isOpen: data.isOpen,
                course_id: data.course,
                promotion_type_id: data.type,
            }

            // Update or add the promotion
            if (data.id) {
                // Set id
                promotion.id = data.id

                // Get current sequences association and their ids
                promotion.sequences = []
                // promotion.sequences_ids = []

                // todo/note : shouldn't this be done in the caller ? (ie I don't have to know/don't care how you structure your data... I need MY format :p)
                // todo: compare to old db data and uniquIFY whith course sequences data ?? (or in the caller ?)
                data.sequences.forEach((sequence) => {
                    // promotion.sequences_ids.push(sequence.id)

                    promotion.sequences.push({
                        promotion_id: data.id,
                        sequence_id: sequence.id,
                        user_id: (sequence.userID || null),
                        start_date: sequence.startDate,
                        end_date: sequence.endDate,
                    })
                })

                // Get current users association and their ids
                promotion.users = []
                promotion.users_ids = []

                data.users.forEach((user) => {
                    promotion.users_ids.push(user.id)

                    promotion.users.push({
                        promotion_id: data.id,
                        user_id: user.id,
                    })
                })

                // Update remote data
                response = await apollo.mutate({
                    mutation: GC_UPDATE_PROMOTION_BY_ID,
                    variables: promotion
                })

                result.success = true
            } else {
                // Get current sequences association
                promotion.sequences = data.sequences.map((sequence) => {
                    return {
                        sequence_id: sequence.id,
                        user_id: (sequence.userID || null),
                        start_date: sequence.startDate,
                        end_date: sequence.endDate,
                    }
                })

                // Get current users association
                promotion.users = data.users.map((user) => {
                    return {
                        user_id: user.id,
                    }
                })

                // Add remote data
                if(!promotion.isOpen) promotion.isOpen = false
                response = await apollo.mutate({
                    mutation: GC_ADD_PROMOTION_ONE,
                    variables: promotion
                })

                result.id = response.data.insert_promotion_one.id
            }

            return result
        },
        async delete(context, id){
            const response = await apollo.mutate({
                mutation: GC_DELETE_PROMOTION_BY_ID,
                variables: { id }
            })

            return response.data.delete_promotion_by_pk
        },
    }
}