import utils from "@/utils/trackingReportTemplate"

export default {
	mailingLogs(user) {
		// Create a map of type => log from the user mailer logs
		return user.logs.reduce((dict, log) => {
			if (log.type.slug == 'mail' && log.data.type) {
				dict[log.data.type] = log
			}

			return dict
		}, {})
	},
	activeTimeData(user) {
		// Init computed data
		let computedTimeData = {
			times: [],
			sum: 0,
			max: 0,
			average: 0,
			weekIds: {},
			monthIds: {},
			yearIds: {},
			firstSessionTimestamp: 0,
			lastSessionTimestamp: 0,
			timesBySequence: {},
			timeBySequence: {},
		}

		const sessionStops = {}

		// Get all event date times
		const times = user.logs.reduce((times, log) => {
			// Exclude "passive" logs
			if (['mail'].indexOf(log.type.slug) > -1) {
				return times
			}
			
			// Add log creation time
			const logTime = (new Date(log.created_at)).getTime()
			times.push({
				logTime,
				sequenceId: log.data && log.data.sequence_id,
			})

			// Register all log time who should always trigger a new session
			if (['login', 'logout', 'sequence_end'].indexOf(log.type.slug) > -1) {
				sessionStops[logTime] = true
			}

			return times
		}, []).sort()

		// Get all data by spliting user logs in sessions
		const oneHour = (1 * 60 * 60 * 1000) // in 
		const minimalSessionTime = (1 * 60 * 1000) // 1 min

		let lastLogTime = 0
		let currentSession = null

		computedTimeData.firstSessionTimestamp = (times && times.length > 0 && times[0].logTime)

		times.forEach(({ logTime, sequenceId }) => {
			const deltaTime = (logTime - lastLogTime)

			// Check if we need to create a new session
			if (
				!currentSession // Initial state
				|| deltaTime > oneHour // Two logs are too far apart to be of the same session
				|| sessionStops[logTime] // This log should always trigger a new session
				|| (sequenceId && currentSession.sequenceId && currentSession.sequenceId != sequenceId) // The log sequence id doesn't match the current one
			) {
				// Start a new session with the minimal time (1 min)
				currentSession = {
					startTime: logTime,
					duration: minimalSessionTime,
					sequenceId,
				}
				computedTimeData.times.push(currentSession)
			} else {
				// Increment current session time
				currentSession.duration += deltaTime

				// Update session sequence id, if possible/needed
				if (!currentSession.sequenceId) {
					currentSession.sequenceId = sequenceId
				}
			}

			// Update last session timestamp
			computedTimeData.lastSessionTimestamp = logTime

			// Register log week, month and year as active
			const logDate = new Date(logTime)
			
			const weekId = utils.weekId(logDate)
			const monthId = utils.monthId(logDate)
			const yearId = utils.yearId(logDate)

			if (computedTimeData.weekIds[weekId] === undefined) {
				computedTimeData.weekIds[weekId] = true
			}
			if (computedTimeData.monthIds[monthId] === undefined) {
				computedTimeData.monthIds[monthId] = true
			}
			if (computedTimeData.yearIds[yearId] === undefined) {
				computedTimeData.yearIds[yearId] = true
			}

			lastLogTime = logTime
		})

		// Compute sum, max and other data
		computedTimeData.times.forEach((session) => {
			// Update sum
			computedTimeData.sum += session.duration

			// Update max
			if (session.duration > computedTimeData.max) {
				computedTimeData.max = session.duration
			}

			// Update sequence data
			if (session.sequenceId) {
				if (!computedTimeData.timesBySequence[session.sequenceId]) {
					computedTimeData.timesBySequence[session.sequenceId] = []
				}

				computedTimeData.timesBySequence[session.sequenceId].push(session)

				if (!computedTimeData.timeBySequence[session.sequenceId]) {
					computedTimeData.timeBySequence[session.sequenceId] = 0
				}

				computedTimeData.timeBySequence[session.sequenceId] += session.duration
			}
		})

		// Compute average time
		if (computedTimeData.times.length > 0) {
			computedTimeData.average = (computedTimeData.sum / computedTimeData.times.length)
		}

		return computedTimeData
	},
	logByTypeSlug(user) {
		return utils.groupBy(user.logs, 'type', 'slug')
	},
	gameLogsBySequenceId(user) {
		return utils.groupBy((user.logByTypeSlug['game_end'] || []), 'data', 'sequence_id')
	},
	firstSuccessRatePerSequence(user, data) {
		const courseSequenceIds = data.courses.reduce((list, course) => {
			course.sequences.forEach((sequence) => {
				list.push(sequence.id)
			})
			return list
		}, [])

		// Create a dictionary of average games first attempt success rate by sequence id
		return courseSequenceIds.reduce((dict, sequenceId) => {
			// Get the first success rate per game id in this sequence
			const successRatePerGameId = (user.gameLogsBySequenceId[sequenceId] || []).reduce((dict, log) => {
				if (log.data && dict[log.data_key] === undefined) {
					dict[log.data_key] = log.data.value
				}

				return dict
			}, {})

			// Get average game success rate for this sequence, if possible
			if (Object.keys(successRatePerGameId).length > 0) {
				dict[sequenceId] = utils.groupAverage(successRatePerGameId, (successRate) => successRate)
			}

			return dict
		}, {})
	},
	successRatePerSequence(user, data) {
		const courseSequenceIds = data.courses.reduce((list, course) => {
			course.sequences.forEach((sequence) => {
				list.push(sequence.id)
			})
			return list
		}, [])

		// Create a dictionary of average games success rate by sequence id
		return courseSequenceIds.reduce((dict, sequenceId) => {
			// Get best success rate per game id in this sequence
			const successRatePerGameId = (user.gameLogsBySequenceId[sequenceId] || []).reduce((dict, log) => {
				if (log.data) {
					const successRate = (dict[log.data_key] || -1)

					// Only keep best success rate from all attempt
					if (log.data.value > successRate) {
						dict[log.data_key] = log.data.value
					}
				}

				return dict
			}, {})

			// Get average game success rate for this sequence, if possible
			if (Object.keys(successRatePerGameId).length > 0) {
				dict[sequenceId] = utils.groupAverage(successRatePerGameId, (successRate) => successRate)
			}

			return dict
		}, {})
	},
	groupsById(user, data) {
		return user.promotions.reduce((dict, userPromotion) => {
			if (!userPromotion.promotion || !userPromotion.promotion.course_id) {
				return dict
			}

			const group = userPromotion.promotion
			const course = data.coursesById[group.course_id]

			if (!course) {
				return dict
			}

			// Compute time data for the group course
			const courseTimes = course.modules.reduce((list, module) => {
				module.sequences.forEach((sequence) => {
					list = list.concat((user.activeTimeData.timesBySequence[sequence.id] || []))
				})
				return list
			}, [])
			const activeTimeSum = utils.sumValues(courseTimes, (time) => {
				return time.duration
			})

			// Get sequence done in this course
			const courseSequenceDoneById = (user.logByTypeSlug['sequence_end'] || []).reduce((dict, log) => {
				if (course.sequenceById[log.data_key]) {
					dict[log.data_key] = true
				}
				return dict
			}, {})

			// Get success rate in this course
			const courseSuccessRate = utils.groupAverage(courseSequenceDoneById, (done, id) => user.successRatePerSequence[id])

			dict[group.id] = {
				id: group.id,
				title: group.title,
				startDate: new Date(group.start_date),
				endDate: new Date(group.end_date),
				firstActiveDate: (courseTimes.length > 0 && courseTimes[0].startTime && new Date(courseTimes[0].startTime)) || null,
				lastActiveDate: (courseTimes.length > 0 && courseTimes[courseTimes.length - 1].startTime && new Date(courseTimes[courseTimes.length - 1].startTime)) || null,
				activeCount: courseTimes.length,
				activeTime: {
					sum: activeTimeSum,
					average: activeTimeSum / (courseTimes.length || 1),
				},
				sequenceDoneCount: Object.keys(courseSequenceDoneById).length,
				sequenceCount: utils.sumValues(course.modules, (module) => {
					return module.sequences.length
				}),
				successRate: courseSuccessRate,
				sequencesData: course.sequences.reduce((dict, sequence) => {
					// Get and format sequence status
					const sequenceStartDate = new Date(group.start_date)
					sequenceStartDate.setDate(sequenceStartDate.getDate() + sequence.offsetDays)
					const sequenceEndDate = (group.end_date && new Date(group.end_date))
					const sequenceStatus = {
						text: 'en cours',
						class: 'text-dashboard-green',
					}

					if (Date.now() < sequenceStartDate.getTime()) {
						sequenceStatus.text = 'à venir'
						sequenceStatus.class = ''
					}

					if (sequenceEndDate && Date.now() > sequenceEndDate.getTime()) {
						sequenceStatus.text = 'terminé'
						sequenceStatus.class = ''
					}

					dict[sequence.id] = {
						id: sequence.id,
						offsetDays: sequence.offsetDays,
						status: sequenceStatus,
						sessionCount: user.activeTimeData.timesBySequence[sequence.id]?.length || 0,
						sessionAverageTime: user.activeTimeData.timeBySequence[sequence.id] / (user.activeTimeData.timesBySequence[sequence.id]?.length || 1),
						sessionFirstAverageSuccessRate: user.firstSuccessRatePerSequence[sequence.id],
						sessionAverageSuccessRate: user.successRatePerSequence[sequence.id],
					}

					return dict
				}, {}),
			}

			return dict
		}, {})
	},
}