import utils from "@/utils/trackingReportTemplate"

const now = Date.now()
// const oneDay = (1 * 60 * 60 * 1000 * 24)
// const oneMonth = (oneDay * 30)
// const threeMonth = (oneMonth * 3)
// const oneYear = (oneDay * 365)

export default {
	createdDate(user) {
		return new Date(user.created_at)
	},
	logByTypeSlug(user) {
		return utils.groupBy(user.logs, 'type', 'slug')
	},
	// mailingLogs(user) {
	// 	// Create a map of type => log from the user mailer logs
	// 	return user.logs.reduce((dict, log) => {
	// 		if (log.type.slug == 'mail' && log.data.type) {
	// 			dict[log.data.type] = log
	// 		}

	// 		return dict
	// 	}, {})
	// },
	// progressionData(user, data) {
	// 	// Init computed data
	// 	let computedProgressionData = {
	// 		hasStarted: false,
	// 		hasDoneLevel1: false,
	// 		hasDoneLevel2: false,
	// 		hasFinishedInOneMonth: false,
	// 		hasFinishedInThreeMonth: false,
	// 		hasFinishedInOneYear: false,
	// 		missionLog: {},
	// 		investigationLog: {},
	// 	}

	// 	let firstLogTime = null
	// 	let level2FinishTimestamp = null

	// 	user.logs.forEach((log) => {
	// 		const logTime = (new Date(log.created_at)).getTime()

	// 		if (!firstLogTime || logTime < firstLogTime) {
	// 			firstLogTime = logTime //todo: use group start ?? (if login before that)
	// 		}

	// 		if (!computedProgressionData.hasStarted && log.type.slug == 'activity') {
	// 			computedProgressionData.hasStarted = true
	// 		} else if (log.type.slug == 'module') {
	// 			const missionModule = data.coursesByType.mission.moduleById[log.data_key]

	// 			if (missionModule && !computedProgressionData.missionLog[missionModule.order]) {
	// 				computedProgressionData.missionLog[missionModule.order] = log
	// 			} else {
	// 				const investigationModule = data.coursesByType.investigation.moduleById[log.data_key]

	// 				if (investigationModule && !computedProgressionData.investigationLog[investigationModule.order]) {
	// 					computedProgressionData.investigationLog[investigationModule.order] = log
	// 				}
	// 			}
	// 		} else if (log.type.slug == 'course') {
	// 			// Mark course has done and save timestamp
	// 			if (log.data_key == data.coursesByType.mission.id && !computedProgressionData.level1DoneTimestamp) {
	// 				computedProgressionData.hasDoneLevel1 = true
	// 			} else if (log.data_key == data.coursesByType.investigation.id && !computedProgressionData.level2DoneTimestamp) {
	// 				computedProgressionData.hasDoneLevel2 = true
	// 				level2FinishTimestamp = logTime
	// 			}
	// 		}
	// 	})

	// 	// Check if the user as finished all level, and check when this happened
	// 	if (computedProgressionData.hasDoneLevel1 && 
	// 		computedProgressionData.hasDoneLevel2 && 
	// 		firstLogTime && level2FinishTimestamp) {
	// 		const timeToFinish = (level2FinishTimestamp - firstLogTime)

	// 		if (timeToFinish <= oneMonth) {
	// 			computedProgressionData.hasFinishedInOneMonth = true
	// 		} else if (timeToFinish <= threeMonth) {
	// 			computedProgressionData.hasFinishedInThreeMonth = true
	// 		} else if (timeToFinish <= oneYear) {
	// 			computedProgressionData.hasFinishedInOneYear = true
	// 		}
	// 	}

	// 	return computedProgressionData
	// },
	// progressionPerChapter(user, data) {
	// 	// Create a dictionary of chapter progression by their id
	// 	return data.chapters.reduce((dict, chapter) => {
	// 		// Get order of the last visited zone in this chapter
	// 		const lastZoneOrder = user.planche_zone_visiteds.reduce((lastOrder, log) => {
	// 			const order = chapter.zoneOrders[log.planche_zone_id]

	// 			if (order > -1 && lastOrder < order) {
	// 				lastOrder = order
	// 			}

	// 			return lastOrder
	// 		}, -1)

	// 		// Get progression percentage
	// 		dict[chapter.id] = ((lastZoneOrder + 1) / (chapter.zoneOrders.count || 1))

	// 		return dict
	// 	}, {})
	// },
	// progression(user) {
	// 	// Make an average of user progression per chapter
	// 	return utils.groupAverage(user.progressionPerChapter, (progression) => progression)
	// },
	// createdWeekId(user) {
	// 	// Generate a uniq week id for the user subscription date
	// 	return utils.weekId(uer.createdDate)
	// },
	activeTimeData(user) {
		// Init computed data
		let computedTimeData = {
			times: [],
			sum: 0,
			max: 0,
			average: 0,
			weekIds: {},
			monthIds: {},
			yearIds: {},
			lastSessionTimestamp: 0,
			timesBySequence: {},
			timeBySequence: {},
		}

		const sessionStops = {}

		// Get all event date times
		const times = user.logs.reduce((times, log) => {
			// Exclude "passive" logs
			if (['mail'].indexOf(log.type.slug) > -1) {
				return times
			}
			
			// Add log creation time
			const logTime = (new Date(log.created_at)).getTime()
			times.push({
				logTime,
				sequenceId: log.data && log.data.sequence_id,
			})

			// Register all log time who should always trigger a new session
			if (['login', 'logout', 'sequence_end'].indexOf(log.type.slug) > -1) {
				sessionStops[logTime] = true
			}

			return times
		}, []).sort()

		// Get all data by spliting user logs in sessions
		const oneHour = (1 * 60 * 60 * 1000) // in 
		const minimalSessionTime = (1 * 60 * 1000) // 1 min

		let lastLogTime = 0
		let currentSession = null

		times.forEach(({ logTime, sequenceId }) => {
			const deltaTime = (logTime - lastLogTime)

			// Check if we need to create a new session
			if (
				!currentSession // Initial state
				|| deltaTime > oneHour // Two logs are too far apart to be of the same session
				|| sessionStops[logTime] // This log should always trigger a new session
				|| (sequenceId && currentSession.sequenceId && currentSession.sequenceId != sequenceId) // The log sequence id doesn't match the current one
			) {
				// Start a new session with the minimal time (1 min)
				currentSession = {
					startTime: logTime,
					duration: minimalSessionTime,
					sequenceId,
				}
				computedTimeData.times.push(currentSession)
			} else {
				// Increment current session time
				currentSession.duration += deltaTime

				// Update session sequence id, if possible/needed
				if (!currentSession.sequenceId) {
					currentSession.sequenceId = sequenceId
				}
			}

			// Update last session timestamp
			computedTimeData.lastSessionTimestamp = logTime

			// Register log week, month and year as active
			const logDate = new Date(logTime)
			
			const weekId = utils.weekId(logDate)
			const monthId = utils.monthId(logDate)
			const yearId = utils.yearId(logDate)

			if (computedTimeData.weekIds[weekId] === undefined) {
				computedTimeData.weekIds[weekId] = true
			}
			if (computedTimeData.monthIds[monthId] === undefined) {
				computedTimeData.monthIds[monthId] = true
			}
			if (computedTimeData.yearIds[yearId] === undefined) {
				computedTimeData.yearIds[yearId] = true
			}

			lastLogTime = logTime
		})

		// Compute sum, max and other data
		computedTimeData.times.forEach((session) => {
			// Update sum
			computedTimeData.sum += session.duration

			// Update max
			if (session.duration > computedTimeData.max) {
				computedTimeData.max = session.duration
			}

			// Update sequence data
			if (session.sequenceId) {
				if (!computedTimeData.timesBySequence[session.sequenceId]) {
					computedTimeData.timesBySequence[session.sequenceId] = []
				}

				computedTimeData.timesBySequence[session.sequenceId].push(session)

				if (!computedTimeData.timeBySequence[session.sequenceId]) {
					computedTimeData.timeBySequence[session.sequenceId] = 0
				}

				computedTimeData.timeBySequence[session.sequenceId] += session.duration
			}
		})

		// Compute average time
		if (computedTimeData.times.length > 0) {
			computedTimeData.average = (computedTimeData.sum / computedTimeData.times.length)
		}

		return computedTimeData
	},
	state(user) {
		// Check if the user group is not disabled and still active (start/end date)
		const hasGroup = (user.groups.length > 0)
		let isGroupActive = (hasGroup && user.groups[0].data.isOpen)

		if (isGroupActive) {
			const startDate = new Date(user.groups[0].data.start_date)
			const endDate = new Date(user.groups[0].data.end_date)

			isGroupActive = (now > startDate.getTime() && now < endDate.getTime())
		}

		if (user.activeTimeData.lastSessionTimestamp > 0) {
			return 'active'
		} else if (user.disabled || (hasGroup && !isGroupActive)) {
			return 'disabled'
		} else if (hasGroup && isGroupActive && (!user.cognito_id || !user.cognito_confirmed)) {
			return 'inactive'
		} else if (user.cognito_id) {
			if (user.cognito_confirmed) {
				return (user.activeTimeData.lastSessionTimestamp > 0 ? 'active' : 'pending')
			}

			return 'pending'
		}

		return 'exist'
	},
	progress(user, data) {
		// Get sequence done in this course
		const courseSequenceDoneById = (user.logByTypeSlug['sequence_end'] || []).reduce((dict, log) => {
			if (data.course.sequenceById[log.data_key]) {
				dict[log.data_key] = true
			}
			return dict
		}, {})

		return Object.keys(courseSequenceDoneById).length / (data.course.modules[0].sequences.length || 1)
	},
	gameLogsBySequenceId(user) {
		return utils.groupBy((user.logByTypeSlug['game_end'] || []), 'data', 'sequence_id')
	},
	firstSuccessRatePerSequence(user, data) {
		const courseSequenceIds = Object.keys(data.course.sequenceById)

		// Create a dictionary of average games first attempt success rate by sequence id
		return courseSequenceIds.reduce((dict, sequenceId) => {
			// Get the first success rate per game id in this sequence
			const successRatePerGameId = (user.gameLogsBySequenceId[sequenceId] || []).reduce((dict, log) => {
				if (log.data && dict[log.data_key] === undefined) {
					dict[log.data_key] = log.data.value
				}

				return dict
			}, {})

			// Get average game success rate for this sequence, if possible
			if (Object.keys(successRatePerGameId).length > 0) {
				dict[sequenceId] = utils.groupAverage(successRatePerGameId, (successRate) => successRate)
			}

			return dict
		}, {})
	},
	successRatePerSequence(user, data) {
		const courseSequenceIds = Object.keys(data.course.sequenceById)

		// Create a dictionary of average games success rate by sequence id
		return courseSequenceIds.reduce((dict, sequenceId) => {
			// Get best success rate per game id in this sequence
			const successRatePerGameId = (user.gameLogsBySequenceId[sequenceId] || []).reduce((dict, log) => {
				if (log.data) {
					const successRate = (dict[log.data_key] || -1)

					// Only keep best success rate from all attempt
					if (log.data.value > successRate) {
						dict[log.data_key] = log.data.value
					}
				}

				return dict
			}, {})

			// Get average game success rate for this sequence, if possible
			if (Object.keys(successRatePerGameId).length > 0) {
				dict[sequenceId] = utils.groupAverage(successRatePerGameId, (successRate) => successRate)
			}

			return dict
		}, {})
	},
	successRate(user) {
		// If we don't have any data we must not consider this data has valid when computing users average
		if (Object.keys(user.successRatePerSequence).length <= 0)
			return undefined

		// Make an average of user success rate per game
		return utils.groupAverage(user.successRatePerSequence, (successRate) => successRate)
	},
}