import gql from 'graphql-tag'

const GC_GET_PRODUCT_LIST = gql`
query GetProductList {
	sf_product {
		id
		identifier
		name
	}
  }
`

export {
	GC_GET_PRODUCT_LIST,
}