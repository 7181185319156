export default function activityDashboardData(data) {
	return {
		firstActiveDate: (data.user.activeTimeData.firstSessionTimestamp ? new Date(data.user.activeTimeData.firstSessionTimestamp) : null),
		lastActiveDate: (data.user.activeTimeData.lastSessionTimestamp ? new Date(data.user.activeTimeData.lastSessionTimestamp) : null),
		activeCount: data.user.activeTimeData.times.length,
		activeTime: {
			sum: data.user.activeTimeData.sum,
			average: data.user.activeTimeData.average
		}
	}
}