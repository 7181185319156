import gql from 'graphql-tag'

const GC_GET_USER_LIST = gql`
query GetUserList {
    users {
      id
      name
      first_name
      email
      role
    }
  }
`

const GC_GET_USER_BY_ID = gql`
query UserByID($id: uuid!) {
  users_by_pk(id: $id) {
    id
    name
    first_name
    email
    role
    gender
    age
    cognito_id
    salesforce_id
    content_logs(order_by: {created_at: desc}) {
      created_at
      user {
        name
        first_name
      }
    }
    promotion_users {
      promotion{
        title
        course {
          name
        }
      }
    }
  }
}
`

const GC_ADD_USER_ONE = gql`
mutation AddUser(
    # $id: uuid!,
    $name: String!,
    $first_name: String!,
    $email: String!,
    $role: String!,
    $gender: String!,
    $age: String!,
  ) {
  insert_users_one(object: {
    # id: $id,
    name: $name,
    first_name: $first_name,
    email: $email,
    role: $role,
    gender: $gender,
    age: $age,
  }) {
    id
  }
}
`

const GC_UPDATE_USER_BY_ID = gql`
mutation UpdateUser(
		$id: uuid!,
		$name: String!,
    $first_name: String!,
    $role: String!,
    $gender: String!,
    $age: String!,
	) {
  update_users_by_pk(pk_columns: { id: $id },
  _set: {
    name: $name,
    first_name: $first_name,
    role: $role,
    gender: $gender,
    age: $age,
  }) {
    id
  }
}
`

const GC_DELETE_USER_BY_ID = gql`
mutation DeleteUser($id: uuid!) {
  delete_users_by_pk(id: $id) {
    id
  }
}
`

export {
  GC_GET_USER_LIST,
  GC_GET_USER_BY_ID,
  GC_ADD_USER_ONE,
  GC_UPDATE_USER_BY_ID,
  GC_DELETE_USER_BY_ID,
}