import 'regenerator-runtime'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

//import layouts
import Default from '@/layouts/Default.vue'
import NoSidebar from '@/layouts/NoSidebar.vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import './utils/veeValidate'

import vSelect from 'vue-select'

//Import tailwind
import '@/assets/scss/_index.scss'

import apollo from './apolloClient'

import VueFab from 'vue-float-action-button'

import vClickOutside from 'v-click-outside'

import VueApollo from 'vue-apollo'

import VueChartist from 'vue-chartist'

import '@/bootstrap/importBootstrapComponents'

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  Vue,
  dsn: "https://0082fe25183a4eb2a88e5ac9fc271eb6@o566450.ingest.sentry.io/5741414",
  integrations: [new Integrations.BrowserTracing()],
  // to keep logs in the console 
  logErrors: true,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});


Vue.component('default-layout', Default)
Vue.component('no-sidebar-layout', NoSidebar)

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

Vue.component('v-select', vSelect)

Vue.use(vClickOutside)
Vue.use(VueFab)
Vue.use(VueApollo);
Vue.use(VueChartist);

const apolloProvider = new VueApollo({
  defaultClient: apollo,
})


Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  apolloProvider,
  store,
  render: h => h(App)
}).$mount('#app')
