import extraUsersData from "./extraUsersData"

import usersDashboardData from "./usersDashboardData"
import userActivityDashboardData from "./userActivityDashboardData"

import commonSheet from "./commonSheet"
import groupsSheet from "./groupsSheet"
import usersSheet from "./usersSheet"

// One day in ms
const oneDay = (1 * 60 * 60 * 1000 * 24)

export default {
	id: 'main',
	types: {
		float: '#0.00',
		percentage: '0.00%',
		duration: {
			format: '[h]"h" mm"min"',
			transform: (time) => (time / oneDay)
		},
		date: {
			format: 'dd/MM/yyyy "à" HH"h"mm',
			transform: (time) => (time ? ((time / (86400 * 1000)) + 25569) : false) // Excel datetime value format
		},
	},
	remoteData: {
		users: {
			module: 'Logs',
			action: 'GetUsersTrackingData',
			payload: {
				roles: ['user'],
				logTypes: ['login', 'logout', 'mail', 'activity', 'scenario', 'complete_scenario', 'course', 'module', 'sequence_end', 'game_end']
			},
			groupByUniq: 'id'
		},
		groups: {
			module: 'Logs',
			action: 'GetGroupsTrackingData',
			groupByUniq: 'id'
		},
		contentData: {
			module: 'Logs',
			action: 'GetContentTrackingData',
		},
		metaTypes: {
			module: 'Utils',
			action: 'initAll',
			state: 'metaTypeList',
			groupByUniq: 'id'
		},
	},
	extraData: {
		rangeStartDate(_, payload) {
			return (payload && payload.startDate) || new Date()
		},
		rangeEndDate(_, payload) {
			return (payload && payload.endDate) || new Date()
		},
		firstCreatedUserDate(data) {
			if (!data.users || data.users.length <= 0)
				return (new Date())

			let firstCreatedTime = (new Date(data.users[0].created_at)).getTime()

			data.users.forEach((user) => {
				const createdTime = (new Date(user.created_at)).getTime()

				if (createdTime < firstCreatedTime) {
					firstCreatedTime = createdTime
				}
			})

			return (new Date(firstCreatedTime))
		},
		users: extraUsersData,
		groups: {
			startDate(group) {
				return new Date(group.start_date)
			},
			endDate(group) {
				return new Date(group.end_date)
			},
		},
		confirmedUsers(data) {
			return data.users.filter((user) => {
				return user.cognito_confirmed
			})
		},
		groupsData(data) {
			const activeGroups = data.groups.filter((group) => {
				if (!group.isOpen) {
					return false
				}

				return (group.startDate <= data.rangeEndDate && group.endDate >= data.rangeStartDate)
			})

			return {
				counts: {
					active: activeGroups.length,
					inactive: (data.groups.length - activeGroups.length),
				},
			}
		},
		usersDashboardData,
		userActivityDashboardData,
	},
	sheets: [
		commonSheet,
		groupsSheet,
		usersSheet,
	]
}