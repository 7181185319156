import gql from "graphql-tag"

//-----------------Initialization-----------------//
const GC_INITALIZE_DASHBOARD_DATA = gql`
	query GetScenarioInfo {
		scenario(
			distinct_on: name
			where: { scenarioType: { slug: { _eq: "scenario" } }}
		) {
			name
			id
			nodes_aggregate {
				aggregate {
					count
				}
			}
			scenarioLocations {
				location {
					name
				}
			}
		}
		revisions:scenario(distinct_on: name
			where: { scenarioType: { slug: { _eq: "revision" } }} ) {
			name
			id
		}
		game {
			id
			title
		}
	}
`

const GC_GET_TOTAL = gql`
	query Total(
		$from: timestamptz
		$to: timestamptz
		$xp: Boolean
		$status: Int
		$company: String
		$seminar: Boolean
		$course: Boolean
		$other: Boolean
		$mailing: Boolean
		$seniority: Int
	) {
		users(
			where: {
				role: { _eq: "user" }
				status: { _eq: $status }
				seniority: { _eq: $seniority }
				experience_seminar: { _eq: $seminar }
				experience_course: { _eq: $course }
				experience_other: { _eq: $other }
				mailing: { _eq: $mailing }
				logs: { _and: [{ created_at: { _gte: $from } }, { created_at: { _lte: $to } }] }
				experienced: { _eq: $xp }
				company: {
					_nin: ["afpols", "AFPOLS", "twn", "TWN", "Afpols", "Twn", "foph", "FOPH"]
					_eq: $company
				}
			}
		) {
			id
			name
			first_name
			email
			mailing
			userOrganisations {
				organisations {
					name
				}
			}
		}
		user_organisations(
			where: {
				users: {
					role: { _eq: "user" }
					status: { _eq: $status }
					seniority: { _eq: $seniority }
					experience_seminar: { _eq: $seminar }
					experience_course: { _eq: $course }
					experience_other: { _eq: $other }
					mailing: { _eq: $mailing }
					experienced: { _eq: $xp }
					company: {
						_nin: ["afpols", "AFPOLS", "twn", "TWN", "Afpols", "Twn", "foph", "FOPH"]
						_eq: $company
					}
				}
				organisations: { _and: [{ created_at: { _gte: $from } }, { created_at: { _lte: $to } }] }
			}
		) {
			organisations {
				address
				address2
				city
				country
				name
				post_code
				id
				userCount:userOrganisations_aggregate {
					aggregate {
						count(columns: user)
					}
				}
			}
		}
	}
`

//-----------------Game results-----------------//
const GC_GET_GAME_RESULTS = gql`
	query GameResults(
		$from: timestamptz
		$to: timestamptz
		$id: uuid
		$xp: Boolean
		$status: Int
		$company: String
		$seminar: Boolean
		$course: Boolean
		$other: Boolean
		$mailing: Boolean
		$seniority: Int
	) {
		users(
			where: {
				role: { _eq: "user" }
				status: { _eq: $status }
				seniority: { _eq: $seniority }
				experience_seminar: { _eq: $seminar }
				experience_course: { _eq: $course }
				experience_other: { _eq: $other }
				mailing: { _eq: $mailing }
				company: {
					_nin: ["afpols", "AFPOLS", "twn", "TWN", "Afpols", "Twn", "foph", "FOPH"]
					_eq: $company
				}
				logs: {
					logType: { slug: { _eq: "game_results" } }
					_and: [{ created_at: { _gte: $from } }, { created_at: { _lte: $to } }]
				}
				experienced: { _eq: $xp }
				userOrganisations: { organisation: { _eq: $id } }
			}
		) {
			id
			logs(
				where: {
					logType: { slug: { _eq: "game_results" } }
					_and: [{ created_at: { _gte: $from } }, { created_at: { _lte: $to } }]
				}
			) {
				data
				data_key
			}
		}
	}
`

const GC_GET_GAME_RESULTS_BY_USERID = gql`
	query GameResultByUser($id: uuid!, $from: timestamptz, $to: timestamptz) {
		users(
			where: {
				logs: {
					logType: { slug: { _eq: "game_results" } }
					_and: [{ created_at: { _gte: $from } }, { created_at: { _lte: $to } }]
				}
				id: { _eq: $id }
			}
		) {
			id
			logs(where: { logType: { slug: { _eq: "game_results" } } }) {
				data
				data_key
			}
		}
	}
`

//-----------------Scenario Complétion-----------------/
const GC_GET_SCENARIO_COMPLETION = gql`
	query ScenarioCompletion(
		$id: uuid
		$from: timestamptz
		$to: timestamptz
		$xp: Boolean
		$status: Int
		$company: String
		$seminar: Boolean
		$course: Boolean
		$other: Boolean
		$mailing: Boolean
		$seniority: Int
	) {
		users(
			where: {
				role: { _eq: "user" }
				status: { _eq: $status }
				seniority: { _eq: $seniority }
				experience_seminar: { _eq: $seminar }
				experience_course: { _eq: $course }
				experience_other: { _eq: $other }
				mailing: { _eq: $mailing }
				company: {
					_nin: ["afpols", "AFPOLS", "twn", "TWN", "Afpols", "Twn", "foph", "FOPH"]
					_eq: $company
				}
				logs: {
					logType: { slug: { _eq: "scenario_nodes" } }
					_and: [{ created_at: { _gte: $from } }, { created_at: { _lte: $to } }]
				}
				experienced: { _eq: $xp }
				userOrganisations: { organisation: { _eq: $id } }
			}
		) {
			id
			logs(
				where: {
					logType: { slug: { _eq: "scenario_nodes" } }
					_and: [{ created_at: { _gte: $from } }, { created_at: { _lte: $to } }]
				}
			) {
				data_key
				data
			}
		}
	}
`

const GC_GET_SCENARIO_COMPLETION_BY_USERID = gql`
	query ScenarioCompletionByID($id: uuid!, $from: timestamptz, $to: timestamptz) {
		users(
			where: {
				logs: {
					logType: { slug: { _eq: "scenario_nodes" } }
					_and: [{ created_at: { _gte: $from } }, { created_at: { _lte: $to } }]
				}
				id: { _eq: $id }
			}
		) {
			logs(where: { logType: { slug: { _eq: "scenario_nodes" } } }) {
				created_at
				data
				data_key
			}
		}
	}
`

//-----------------Average Connexion time-----------------//
const GC_GET_AVERAGE_CO_TIME = gql`
	query AverageCoTime(
		$id: uuid
		$from: timestamptz
		$to: timestamptz
		$status: Int
		$xp: Boolean
		$company: String
		$seminar: Boolean
		$course: Boolean
		$other: Boolean
		$mailing: Boolean
		$seniority: Int
	) {
		users(
			where: {
				role: { _eq: "user" }
				status: { _eq: $status }
				seniority: { _eq: $seniority }
				experience_seminar: { _eq: $seminar }
				experience_course: { _eq: $course }
				experience_other: { _eq: $other }
				mailing: { _eq: $mailing }
				logs: {
					_or: [
						{ logType: { slug: { _eq: "visited_media_library" } } }
						{ logType: { slug: { _eq: "scenario_nodes" } } }
					]
					_and: [{ created_at: { _gte: $from } }, { created_at: { _lte: $to } }]
				}
				experienced: { _eq: $xp }
				userOrganisations: { organisation: { _eq: $id } }
				company: {
					_nin: ["afpols", "AFPOLS", "twn", "TWN", "Afpols", "Twn", "foph", "FOPH"]
					_eq: $company
				}
			}
		) {
			id
			logs(
				order_by: { created_at: asc }
				where: {
					_or: [
						{ logType: { slug: { _eq: "visited_media_library" } } }
						{ logType: { slug: { _eq: "scenario_nodes" } } }
					]
					_and: [{ created_at: { _gte: $from } }, { created_at: { _lte: $to } }]
				}
			) {
				logType {
					slug
				}
				created_at
				updated_at
			}
		}
	}
`

//-----------------Active Users-----------------//
const GC_GET_USER_ACTIVITY = gql`
	query ActiveUsersPerWeek(
		$from: timestamptz!
		$to: timestamptz!
		$id: uuid
		$xp: Boolean
		$status: Int
		$company: String
		$seminar: Boolean
		$course: Boolean
		$other: Boolean
		$mailing: Boolean
		$seniority: Int
	) {
		users(
			where: {
				role: { _eq: "user" }
				status: { _eq: $status }
				seniority: { _eq: $seniority }
				experience_seminar: { _eq: $seminar }
				experience_course: { _eq: $course }
				experience_other: { _eq: $other }
				mailing: { _eq: $mailing }
				logs: {
					_or: [
						{ logType: { slug: { _eq: "visited_media_library" } } }
						{ logType: { slug: { _eq: "scenario_nodes" } } }
					]
					_and: [{ created_at: { _gte: $from } }, { created_at: { _lte: $to } }]
				}
				userOrganisations: { organisation: { _eq: $id } }
				experienced: { _eq: $xp }
				company: {
					_nin: ["afpols", "AFPOLS", "twn", "TWN", "Afpols", "Twn", "foph", "FOPH"]
					_eq: $company
				}
			}
		) {
			id
			logs(
				where: {
					_or: [
						{ logType: { slug: { _eq: "visited_media_library" } } }
						{ logType: { slug: { _eq: "scenario_nodes" } } }
					]
					_and: [{ created_at: { _gte: $from } }, { created_at: { _lte: $to } }]
				}
				order_by: { created_at: asc }
			) {
				created_at
			}
		}
	}
`

const GC_GET_USER_ACTIVITY_BY_ID = gql`
	query UserActivityByID($from: timestamptz, $to: timestamptz, $id: uuid!) {
		users(
			where: {
				logs: {
					_or: [
						{ logType: { slug: { _eq: "visited_media_library" } } }
						{ logType: { slug: { _eq: "scenario_nodes" } } }
					]
					_and: [{ created_at: { _gte: $from } }, { created_at: { _lte: $to } }]
				}
				id: { _eq: $id }
			}
		) {
			logs(
				where: {
					_or: [
						{ logType: { slug: { _eq: "visited_media_library" } } }
						{ logType: { slug: { _eq: "scenario_nodes" } } }
					]
					_and: [{ created_at: { _gte: $from } }, { created_at: { _lte: $to } }]
				}
				order_by: { created_at: asc }
			) {
				logType {
					slug
				}
				created_at
				updated_at
			}
		}
	}
`

//-----------------New Users-----------------//
const GC_GET_NEW_USERS_PER_WEEK = gql`
	query NewUsersPerWeek(
		$from: timestamptz!
		$to: timestamptz!
		$xp: Boolean
		$status: Int
		$company: String
		$seminar: Boolean
		$course: Boolean
		$other: Boolean
		$mailing: Boolean
		$seniority: Int
		$id: uuid
	) {
		users(
			where: {
				_and: [{ created_at: { _gte: $from } }, { created_at: { _lte: $to } }]
				role: { _eq: "user" }
				status: { _eq: $status }
				seniority: { _eq: $seniority }
				experience_seminar: { _eq: $seminar }
				experience_course: { _eq: $course }
				experience_other: { _eq: $other }
				mailing: { _eq: $mailing }
				experienced: { _eq: $xp }
				userOrganisations: { organisation: { _eq: $id } }
				company: {
					_nin: ["afpols", "AFPOLS", "twn", "TWN", "Afpols", "Twn", "foph", "FOPH"]
					_eq: $company
				}
			}
			order_by: { created_at: asc }
		) {
			id
			created_at
		}
	}
`

//-----------------Finished users-----------------//
const GC_GET_FINISHED_USERS = gql`
	query GetFinishedUsers(
		$id: uuid
		$from: timestamptz
		$to: timestamptz
		$xp: Boolean
		$status: Int
		$company: String
		$seminar: Boolean
		$course: Boolean
		$other: Boolean
		$mailing: Boolean
		$seniority: Int
	) {
		users(
			where: {
				logs: {
					logType: { slug: { _eq: "scenario_end" } }
					_and: [{ created_at: { _gte: $from } }, { created_at: { _lte: $to } }]
				}
				role: { _eq: "user" }
				experienced: { _eq: $xp }
				experience_seminar: { _eq: $seminar }
				experience_course: { _eq: $course }
				experience_other: { _eq: $other }
				mailing: { _eq: $mailing }
				status: { _eq: $status }
				seniority: { _eq: $seniority }
				userOrganisations: { organisation: { _eq: $id } }
				company: {
					_nin: ["afpols", "AFPOLS", "twn", "TWN", "Afpols", "Twn", "foph", "FOPH"]
					_eq: $company
				}
			}
		) {
			logs(where: { logType: { slug: { _eq: "scenario_end" } } }) {
				data_key
			}
			id
		}
	}
`

const GC_GET_ORGA_ONE = gql`
	query GetOrga(
		$id: uuid!
		$from: timestamptz
		$to: timestamptz
		$status: Int
		$xp: Boolean
		$company: String
		$seminar: Boolean
		$course: Boolean
		$other: Boolean
		$mailing: Boolean
		$seniority: Int
	) {
		organisations_by_pk(id: $id) {
			name
			id
			userOrganisations(
				distinct_on: user
				where: {
					users: {
						company: {
							_nin: ["afpols", "AFPOLS", "twn", "TWN", "Afpols", "Twn", "foph", "FOPH"]
							_eq: $company
						}
						role: { _eq: "user" }
						experience_seminar: { _eq: $seminar }
						experience_course: { _eq: $course }
						experience_other: { _eq: $other }
						mailing: { _eq: $mailing }
						status: { _eq: $status }
						seniority: { _eq: $seniority }
						experienced: { _eq: $xp }
						_and: [{ created_at: { _gte: $from } }, { created_at: { _lte: $to } }]
					}
				}
			) {
				users {
					id
					name
					first_name
					email
				}
			}
		}
	}
`

const GC_GET_USERS_PROMOTION = gql`
query GetPromotionUserList($ids:[uuid!]!, $from: timestamptz, $to: timestamptz){
	users( where: { id: { _in: $ids }} ){
		id
		name
		first_name
		email
		connexion:logs( where: {
			_or: [
				{ logType: { slug: { _eq: "login" } } }
				{ logType: { slug: { _eq: "logout" } } }
			]
			_and: [
				{ created_at: { _gte: $from } },
				{ created_at: { _lte: $to } }
			]
		} order_by: {created_at: asc}) {
			created_at
			logType {
				slug
			}
		}
		scenario:logs(where: {
			logType: { slug: { _eq: "scenario" } }
			_and: [{ created_at: { _gte: $from } }, { created_at: { _lte: $to } }]
		}){
			created_at
			updated_at
			data_key
			data
		}
		sc_start:logs(where: {
			 logType: { slug: { _eq: "scenario_start" } }
			_and: [{ created_at: { _gte: $from } }, { created_at: { _lte: $to } }]
		}){
			created_at
			data_key
		}
		sc_end:logs(where: {
			 logType: { slug: { _eq: "scenario_end" } }
			_and: [{ created_at: { _gte: $from } }, { created_at: { _lte: $to } }]
		}){
			created_at
			data_key
		}
		game:logs(where: {
			 logType: { slug: { _eq: "game" } }
			_and: [{ created_at: { _gte: $from } }, { created_at: { _lte: $to } }]
		}){
			data_key
			data
			created_at
		}
	}
}
`

const GC_INSERT_CONTENT_LOG = gql`
mutation InsertContentLog($obj:content_log_insert_input!) {
  insert_content_log_one(object: $obj) {
    id
  }
}
`

const GC_INSERT_USER_LOG = gql`
mutation InsertUserLog($data: user_log_insert_input!) {
  insert_users_log_one(object: $data) {
    id
  }
}
`

const GC_GET_LOG_TYPES = gql`
query GetLogTypes{
	log_type {
		description
		id
		name
		slug
  }
}
`

const GC_GET_USERS_TRACKING_DATA = gql`
query GetUsersTrackingData($roles: [String!]!, $logTypes: [String!]!, $createdAtWhere: timestamptz_comparison_exp!, $logCreatedAtWhere: timestamptz_comparison_exp!) {
	users(where: { role: { _in: $roles }, created_at: $createdAtWhere }) {
    id
    first_name
    name
    email
    created_at
    cognito_id
    cognito_confirmed
    role
    salesforce_id
    groups: promotion_users {
      id: promotion_id
      data: promotion {
      	start_date
      	end_date
      	isOpen
      }
    }
    logs(where: {logType: {slug: {_in: $logTypes}}, created_at: $logCreatedAtWhere }, order_by: {created_at: asc}) {
      data_key
      created_at
      data
      type: logType {
        slug
      }
    }
  }
}
`

const GC_GET_COURSES_TRACKING_DATA = gql`
	query GetCourseTrackingData {
		course {
			id
			type: courseType {
				slug
			}
			modules(order_by: {order: asc}) {
				id
				order
				scenarios {
					id: scenario_id
				}
				sequences(order_by: {offsetDays: asc}) {
					id
					offsetDays
					scenarios {
						id: scenario_id
					}
				}
			}
		}
	}
`

const GC_GET_COURSE_TRACKING_DATA = gql`
	query GetCourseTrackingData($id: uuid!) {
		course(where: { id: { _eq: $id } }) {
			modules(order_by: {order: asc}) {
				sequences(order_by: {offsetDays: asc}) {
					id
					order
					offsetDays
					scenarios(order_by: {order: asc}) {
						scenario {
							nodes {
								node_content
							}
						}
					}
				}
			}
		}
	}
`

const GC_GET_COURSE_USERS_TRACKING_DATA = gql`
query GetCourseUsersTrackingData($id: uuid!, $roles: [String!]!, $logTypes: [String!]!) {
	course(where: { id: { _eq: $id } }) {
		promotions {
			users(where: {user: {role: {_in: $roles}}}) {
				user {
					id
					first_name
					name
					created_at
					cognito_id
					cognito_confirmed
					groups: promotion_users {
						id: promotion_id
						data: promotion {
							start_date
							end_date
							isOpen
						}
					}
					logs(where: {logType: {slug: {_in: $logTypes}} }, order_by: {created_at: asc}) {
						data_key
						created_at
						data
						type: logType {
							slug
						}
					}
				}
			}
		}
	}
}
`

const GC_GET_PROMOTIONS_TRACKING_DATA = gql`
query GetPromotionsTrackingData {
  promotion {
    id
    title
    course {
      modules {
        sequences {
          scenarios {
            id: scenario_id
          }
        }
      }
    }
  }
}

`

const GC_GET_USER_TRACKING_DATA = gql`
query GetUserTrackingData($id: uuid!, $logTypes: [String!]!) {
	users_by_pk(id: $id) {
    id
    created_at
    logs(where: {logType: {slug: {_in: $logTypes}}}, order_by: {created_at: asc}) {
      data_key
      created_at
      data
      type: logType {
        slug
      }
    }
    promotions: promotion_users(order_by: { promotion: { start_date: asc }}) {
    	promotion {
    		id
    		title
    		start_date
    		end_date
    		type {
    			slug
    		}
    		course_id
    	}
    }
  }
}
`

const GC_GET_GROUP_USERS_TRACKING_DATA = gql`
query GetGroupUsersTrackingData($id: uuid!, $roles: [String!]!, $logTypes: [String!]!) {
	group_by_pk: promotion_by_pk(id: $id) {
		users(where: { user: { role: { _in: $roles } } }) {
			user {
				id
				name
				first_name
				email
				created_at
				cognito_id
				cognito_confirmed
				groups: promotion_users {
					id: promotion_id
					data: promotion {
						start_date
						end_date
						isOpen
					}
				}
				logs(where: {logType: {slug: {_in: $logTypes}}}, order_by: {created_at: asc}) {
					data_key
					created_at
					data
					type: logType {
						slug
					}
				}
			}
		}
	}
}
`

const GC_GET_GROUP_CUSTOMER_TRACKING_DATA = gql`
query GetGroupCustomerTrackingData($id: uuid!, $roles: [String!]!) {
	group_by_pk: promotion_by_pk(id: $id) {
		id
		identifier
		name
		users(where: { user: { role: { _in: $roles } } }) {
			id: user_id
		}
		customer {
			id
			identifier
			name
		}
	}
}
`

const GC_GET_CUSTOMER_USERS_TRACKING_DATA = gql`
query GetCustomerUsersTrackingData($id: uuid!, $roles: [String!]!, $logTypes: [String!]!) {
	customer_by_pk(id: $id) {
		groups: promotions {
			users(where: { user: { role: { _in: $roles } } }) {
				user {
					id
					name
					first_name
					email
					created_at
					cognito_id
					cognito_confirmed
					groups: promotion_users {
						id: promotion_id
						data: promotion {
							start_date
							end_date
							isOpen
						}
					}
					logs(where: {logType: {slug: {_in: $logTypes}}}, order_by: {created_at: asc}) {
						data_key
						created_at
						data
						type: logType {
							slug
						}
					}
				}
			}
		}
	}
}
`

const GC_GET_CUSTOMERS_TRACKING_DATA = gql`
query GetCustomersTrackingData($roles: [String!]!) {
	customer(order_by: {identifier: asc, name: asc}) {
		id
		identifier
		name
		groups: promotions(order_by: {identifier: asc, name: asc}) {
			id
			identifier
			name
			users(where: { user: { role: { _in: $roles } } }) {
				id: user_id
			}
		}
	}
}
`

const GC_GET_CUSTOMER_TRACKING_DATA = gql`
query GetCustomersTrackingData($id: uuid!, $roles: [String!]!) {
	customer_by_pk(id: $id) {
		id
		identifier
		name
		groups: promotions(order_by: {identifier: asc, name: asc}) {
			id
			identifier
			name
			users(where: { user: { role: { _in: $roles } } }) {
				id: user_id
			}
		}
	}
}
`

const GC_GET_GROUPS_TRACKING_DATA = gql`
query GetGroupsTrackingData {
	group: promotion {
		id
		title
		start_date
		end_date
		isOpen
		salesforce_id
		course {
			name
			metas {
				value
				meta_type_id
			}
			modules(order_by: {order: asc}) {
				id
				scenarios {
					id: scenario_id
				}
				sequences(order_by: {offsetDays: asc}) {
					id
					scenarios {
						id: scenario_id
					}
				}
			}
		}
	}
}
`

const GC_GET_CONTENT_TRACKING_DATA = gql`
query GetContentTrackingData {
	course_aggregate {
    aggregate {
      count
    }
  }
  question_aggregate {
    aggregate {
      count
    }
  }
}
`

export {
	GC_GET_LOG_TYPES,
	GC_INSERT_CONTENT_LOG,
	GC_INSERT_USER_LOG,
	GC_GET_GAME_RESULTS,
	GC_GET_ORGA_ONE,
	GC_GET_TOTAL,
	GC_GET_SCENARIO_COMPLETION,
	GC_GET_USER_ACTIVITY,
	GC_GET_NEW_USERS_PER_WEEK,
	GC_GET_FINISHED_USERS,
	GC_GET_USER_ACTIVITY_BY_ID,
	GC_GET_GAME_RESULTS_BY_USERID,
	GC_GET_AVERAGE_CO_TIME,
	GC_GET_SCENARIO_COMPLETION_BY_USERID,
	GC_INITALIZE_DASHBOARD_DATA,
	GC_GET_USERS_PROMOTION,
	GC_GET_USERS_TRACKING_DATA,
	GC_GET_COURSES_TRACKING_DATA,
	GC_GET_PROMOTIONS_TRACKING_DATA,
	GC_GET_USER_TRACKING_DATA,
	GC_GET_GROUP_USERS_TRACKING_DATA,
	GC_GET_GROUP_CUSTOMER_TRACKING_DATA,
	GC_GET_CUSTOMER_USERS_TRACKING_DATA,
	GC_GET_CUSTOMERS_TRACKING_DATA,
	GC_GET_CUSTOMER_TRACKING_DATA,
	GC_GET_GROUPS_TRACKING_DATA,
	GC_GET_COURSE_TRACKING_DATA,
	GC_GET_COURSE_USERS_TRACKING_DATA,
	GC_GET_CONTENT_TRACKING_DATA,
}
