<template>
    <div class="scenarios">
        <List
        listing="scenario" 
        :fields="fields" 
        :filters="filters"
        :previousPage="previousPage"
        :default-sort-by="'location'"
        :default-sort-desc="false"
        modify-button-text="Éditer le Scénario"
        :with-delete-button="false"
        searchText="Rechercher un scénario"
        editRouteName="scenario-edit">
            <template v-slot:buttons>
                <div class="md:flex flex-row justify-around hidden md:w-1/3">
                    <button type="button" class="twn-button whitespace-no-wrap overflow-hidden text-xs mr-2" @click="addScenario">Ajouter un scénario</button>
                </div>
            </template>
            <template v-slot:fab>
                <vue-fab
                    class="md:hidden"
                    fabItemAnimate="alive"
                    fabAliveAnimateBezier="ease-in-out"
                    mainBtnColor="#fff"
                    size="big"
                >
                    <fab-item class="twn-fab add" @clickItem="addScenario" color="#DC9799" :idx="0.5" title="Ajouter un scénario" icon="add" />
                </vue-fab>
            </template>
        </List>
    </div>
</template>

<script>
import List from '@/components/List'

import dispatchStoreRequest from "@/mixins/dispatchStoreRequest"

export default {
    name:'ScenarioList',
    components: { List },
    mixins: [ dispatchStoreRequest ],
    props:{
        previousPage: {
            type: Number,
            required: false,
            default: null
        }
    },
    data() {
        return {
            fields: [
                {
                    key:'name',
                    label: 'Identifiant',
                    sortable: true
                },
                {
                    key: 'title',
                    label: 'Nom',
                    sortable: true,
                },
                // {
                //     key: 'nb_use',
                //     db: `nb_use: sequence_scenarios_aggregate {
                //         aggregate {
                //             count
                //         }
                //     }`,
                //     nested: 'count',
                //     label: "Utilisations",
                // },
            ],
            filters: []
        }
    },
    async created(){
        await this.dispatchStoreRequest('Scenarios/getList')
    },
    methods: {
      addScenario() {
        this.$router.push({ name:'scenario-edit' })
      }
    }
}
</script>

<style lang="scss" scoped></style>