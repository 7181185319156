<template>
	<div class="flex flex-row my-4 shadowed">
		<!-- Left column -->
		<div
			class="index w-10 flex-center bg-principal-selected rounded-tl-md rounded-bl-md text-white"
		>
			{{ sequence.order + 1 }}
		</div>

		<!-- Center column -->
		<div class="content flex flex-wrap p-4 w-full">
			<!-- <div class="top flex w-full mr-4 mb-4">
				<div class="flex items-center w-1/4 mr-8">
					<label class="mr-4 mb-0" for="twn-id">Identifiant</label>
					<input type="text" class="twn-input flex-grow" v-model="sequence.name" />
				</div>
				<div class="flex items-center w-3/4">
					<label class="mr-4 mb-0" for="twn-id">Titre</label>
					<input type="text" class="twn-input flex-grow" v-model="sequence.title" />
				</div>
			</div> -->
			
			<!-- if bloc course -->
			<div
				class="bloc-content w-full"
				v-if="courseType == 'bloc_course'"
			>
				<div class="w-full flex justify-between mb-4">
					<label for="twn-id" class="w-1/6">Scénarios</label>
					<v-select
						:options="scenarioList"
						multiple
						label="name"
						class="w-5/6 mr-4"
						v-model="sequence.scenarios"
					>
					</v-select>
				</div>
				<div class="w-full flex">
					<label for="twn-id" class="w-1/6">Révision</label>
					<v-select
						:options="revisionOptions"
						@search="fetchRevisionByName"
						multiple
						label="name"
						class="w-5/6 mr-4"
						v-model="sequence.revisions"
					>
					</v-select>
				</div>
			</div>

			<!-- if complet course -->
			<div class="w-full" v-else-if="courseType == 'complete_course'">
				<div class="w-full mb-4 justify-start flex">
					<label for="nb-scenario"
						>Nombre de scénario à faire lors de cette séquence</label
					>
					<input
						type="number"
						id="nb-scenario"
						class="ml-4 twn-input pr-1"
						v-model="sequence.module_scenario_limit"
						min="0"
					/>
				</div>
				<div class="w-full flex items-center">
					<label for="twn-id" class="mr-4 mb-0">Scénarios additionnels</label>
					<v-select
						:options="scenarioList"
						multiple
						label="name"
						class="w-5/6 mr-4"
						v-model="sequence.scenarios" 
					>
					
					<!-- <template #selected-option="data">
						{{data.order}}
					</template> -->
					</v-select>
				</div>
			</div>

			<div class="w-full" v-else-if="courseType == 'module_course'">
				<!-- <div class="w-full flex items-center mb-4">
					<label for="twn-id" class="mr-4 mb-0">Medias</label>
					<v-select
						:options="mediaList"
						multiple
						label="name"
						class="twn-select w-5/6 mr-4"
						v-model="sequence.medias"
					>
						<template #selected-option-container="{ option, deselect }">
							<span
							:draggable="true"
							@mousedown.stop
							@dragstart.stop="dragStart($event, option, sequence.medias)"
							@dragenter.stop="dragEnter(option, sequence.medias)"
							@dragend.stop="dragEnd()"
							@dragover.prevent
							class="vs__selected cursor-move"
							:class="{ 'border-red': draggedOption == option }">
								{{option.order}} | {{option.name}}
								<button type="button" title="Deselect Test dev 2" aria-label="Deselect Test dev 2" class="vs__deselect" @click="deselect(option)"><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10"><path d="M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z"></path></svg></button>
							</span>
						</template>
					</v-select>
				</div> -->

				<div class="w-full flex items-center flex-wrap">
					
					<label for="offset" class="w-full">Ouverture du rappel a J+
						<input
							type="number"
							id="offset"
							class="ml-4 w-16 twn-input pr-1"
							v-model="sequence.offsetDays "
							min="0"
						/>
					</label>
					<label for="twn-id" class="mr-4 mb-0">Scénario	</label>
					<div>
						<v-select
						:options="scenarioList"
						label="name"
						class="twn-select"
						:value="sequence.scenarios"
						@input="sequence.scenarios = [$event]"
						/>
					</div>
						<!-- <template #selected-option-container="{ option, deselect }">
							<span
							:draggable="true"
							@mousedown.stop
							@dragstart.stop="dragStart($event, option, sequence.scenarios)"
							@dragenter.stop="dragEnter(option, sequence.scenarios)"
							@dragend.stop="dragEnd()"
							@dragover.prevent
							class="vs__selected cursor-move"
							:class="{ 'border-red': draggedOption == option }">
								{{option.order}} | {{option.name}}
								<button type="button" title="Deselect Test dev 2" aria-label="Deselect Test dev 2" class="vs__deselect" @click="deselect(option)"><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10"><path d="M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z"></path></svg></button>
							</span>
						</template> -->
				
				</div>
			</div>

		</div>

		<!-- Right column -->
		<div
			class="buttons w-1/12 bg-principal-selected flex flex-col justify-around items-center rounded-tr-md rounded-br-md text-white"
		>
		<b-icon-x @click="$emit('remove-sequence')"
				class="cursor-pointer text-xl font-principal-bold flex-center"></b-icon-x>
			<!-- <div
				@click="$emit('remove-sequence')"
				class="cursor-pointer text-xl font-principal-bold border-2 w-10 h-10 rounded-md flex-center"
			>
				-
			</div>
			<div
				@click="$emit('add-sequence')"
				class="cursor-pointer text-xl font-principal-bold border-2 w-10 h-10 rounded-md flex-center"
			>
				+
			</div> -->
		</div>
	</div>
</template>

<script>
	export default {
		name: 'SequenceForm',
		props: {
			sequence: {
				type: Object,
				required: true
			},
			courseType: {
				type: String,
				required: true,
			},
			scenarioList: {
				type: Array,
				required: true,
			},
			mediaList: {
				type: Array,
				required: true,
			},
		},
		data() {
			return {
				draggedOptionList: null,
				draggedOption: null,
			}
		},
		mounted() {
			// Create transparent drag image if needed
			if (!window.transparentDragImage) {
				let img = document.createElement('img')
				img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
				document.body.appendChild(img)
				window.transparentDragImage = img
			}
		},
		// watch: {
		// 	'sequence.scenarios': {
		// 		handler() {
		// 			this.updateListItemOrder(this.sequence.scenarios)
		// 		},
		// 	},
		// 	'sequence.medias': {
		// 		handler() {
		// 			this.updateListItemOrder(this.sequence.medias)
		// 		},
		// 	},
		// },
		methods: {
			dragStart(event, option, list) {
				event.dataTransfer.effectAllowed = "move"
				event.dataTransfer.dropEffect = "move"
				event.dataTransfer.setDragImage(window.transparentDragImage, 0, 0)
				// event.dataTransfer.setData("application/json", JSON.stringify(asset))
				
				this.draggedOption = option
				this.draggedOptionList = list
			},
			dragEnter(option, list) {
				if (option == this.draggedOption || list != this.draggedOptionList)
					return

				this.draggedOptionList.splice(this.draggedOption.order, 1, option)
				this.draggedOptionList.splice(option.order, 1, this.draggedOption)
			},
			dragEnd() {
				this.draggedOption = null
				this.draggedOptionList = null
			},
			updateListItemOrder(list){
				console.log('list:', list)
				if (list) {
					list.forEach((item, index) => {
						item.order = index
					})
				}
			},
		},
	}
</script>

<style lang="scss" scoped>
</style>