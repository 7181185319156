import utils from "@/utils/trackingReportTemplate"

import commonTypes from '../common/commonTypes'

import extraUserData from "./extraUserData"

import mailingDashboardData from "./mailingDashboardData"
import activityDashboardData from "./activityDashboardData"

export default {
	id: 'userTrackingReportData',
	types: commonTypes,
	remoteData: {
		user: {
			module: 'Logs',
			action: 'GetUserTrackingData',
			payload: {				
				logTypes: ['login', 'logout', 'mail', 'activity', 'scenario', 'complete_scenario', 'course', 'module', 'sequence_end', 'game_end'],
			},
		},
		courses: {
			module: 'Logs',
			action: 'GetCoursesTrackingData',
			groupByUniq: 'id'
		},
	},
	extraData: {
		courses: {
			sequences(course) {
				return course.modules.reduce((list, module) => {
					module.sequences.forEach((sequence) => {
						list.push(sequence)
					})
					return list
				}, [])
			},
			sequenceById(course) {
				return utils.groupByUniq(course.sequences, 'id')
			},
		},
		user: extraUserData,
		mailingDashboardData,
		activityDashboardData,
	},
	sheets: []
}