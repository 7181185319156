<template>
	<div class="dashboard-main">
		<!-- Header -->
		<div class="flex items-center justify-between">
			<div class="flex items-center">
				<p>Du</p>
				<b-form-datepicker
					class="w-40 mx-2"
					v-bind="{
						dateFormatOptions: {
							year: 'numeric',
							month: '2-digit',
							day: '2-digit',
						},
						startWeekDay: 1,
						labelNoDateSelected: 'Aucune date',
						labelHelp: 'Utilisez les touches directionels pour changer de date'
					}"
					:min="filterMinDate"
					:max="today"
					v-model="filterStartDate"
					:disabled="isLoading"
				/>
				<p>au</p>
				<b-form-datepicker
					class="w-40 mx-2"
					v-bind="{
						dateFormatOptions: {
							year: 'numeric',
							month: '2-digit',
							day: '2-digit',
						},
						startWeekDay: 1,
						labelNoDateSelected: 'Aucune date',
						labelHelp: 'Utilisez les touches directionels pour changer de date'
					}"
					:min="filterStartDate || filterMinDate"
					:max="today"
					v-model="filterEndDate"
					:disabled="isLoading"
				/>
				<button
					v-if="filterStartDate || filterEndDate"
					type="button"
					class="twn-button twn-button-small" 
					:disabled="isLoading"
					@click="onApplyFilter"
				>
					Appliquer
				</button>
				<button
					v-if="filterStartDate || filterEndDate"
					type="button"
					class="twn-button twn-button-small" 
					:disabled="isLoading"
					@click="onResetFilter"
				>
					Réinitialiser
				</button>
				<!-- <Toggle
				v-if="isSuperAdmin"
				:checked="includeOtherRoles"
				:onInput="() => includeOtherRoles = !includeOtherRoles"></Toggle>
				<span v-if="isSuperAdmin" class="ml-2">Inclure les admins/responsables clients</span>

				<template v-if="isUserManager && managedCustomer">
					<span>Client : {{ managedCustomer.name }}</span>
					<br>
					<span>Référent : {{ userInfo.first_name }} {{ userInfo.last_name }} ({{ userInfo.email }})</span>
				</template> -->
			</div>
			<button type="button" class="mb-4 twn-button" @click="exportReport" v-bind:disabled="isLoading">
				<span v-if="!isLoading">
					Exporter le rapport
				</span>
				<span v-else>
					Chargement...
				</span>
			</button>
		</div>

		<!-- Dashboard -->
		<div>
			<!-- Users count and state data -->
			<CategoryBlock title="Statistiques">
				<!-- KPIs -->
				<div class="inline-block w-full align-top">
					<div class="dashboard-main-kpis">
						<KPIBlock
							:isLoading="isLoading"
							title="Promotions en cours"
							:number="dashboardData && dashboardData.groupsData.counts.active"
							help="Promotions en cours, débutant ou terminant pendant la période sélectionnée"
						/>
						<KPIBlock
							:isLoading="isLoading"
							title="Stagiaires inscrits"
							:number="dashboardData && dashboardData.usersDashboardData.total"
							help="Total de tous les comptes stagiaires créés"
						/>
						<KPIBlock
							:isLoading="isLoading"
							title="Stagiaires actifs"
							:number="dashboardData && dashboardData.usersDashboardData.active"
							help="Utilisateurs qui ce sont logués au moins une fois au cours de la période sélectionnée"
							backgroundColor="bg-dashboard-green"
						/>
						<KPIBlock
							:isLoading="isLoading"
							title="Stagiaires inactifs"
							:number="dashboardData && dashboardData.usersDashboardData.inactive"
							help="Utilisateurs dont le compte n'a jamais été activé"
							backgroundColor="bg-dashboard-gray"
						/>
					</div>
				</div>
				
				<!-- Active users -->
				<SubCategoryBlock class="dashboard-main-active-user mb-8" title="Nombre d'utilisateurs actifs par">
					<div class="inline-block ml-6">
						<span class="mr-2">Mois</span>
						<Toggle
						:checked="!showUserActivityByMonth"
						:onInput="() => showUserActivityByMonth = !showUserActivityByMonth"></Toggle>
						<span class="ml-2">Années</span>
					</div>

					<LineChart
						:isLoading="isLoading"
						:data="dashboardData && dashboardData.userActivityDashboardData.activeCount[(showUserActivityByMonth ? 'months' : 'years')]"
					/>
				</SubCategoryBlock>

				<!-- KPIs -->
				<div class="inline-block w-full align-top">
					<div class="dashboard-main-kpis">
						<KPIBlock
							:isLoading="isLoading"
							title="Nombre de sessions sur l’application"
							:number="dashboardData && dashboardData.userActivityDashboardData.activeTime.count"
						/>
						<KPIBlock
							:isLoading="isLoading"
							title="Durée totale de connexion"
							:time="dashboardData && dashboardData.userActivityDashboardData.activeTime.sum"
						/>
						<KPIBlock
							:isLoading="isLoading"
							title="Durée moyenne de session"
							:time="dashboardData && dashboardData.userActivityDashboardData.activeTime.average"
						/>
					</div>
				</div>
			</CategoryBlock>
		</div>
	</div>
</template>

<script>
	import { mapState } from 'vuex'

	import { debounce } from '@/utils/utils'
	import trackingReport from '@/utils/trackingReport'

	import template from "@/constants/mainTrackingReportTemplate"

	import Toggle from '@/components/Toggle'

	import CategoryBlock from "./block/Category"
	import SubCategoryBlock from "./block/SubCategory"
	import KPIBlock from "./block/KPI"
	import LineChart from "./chart/Line"

	export default {
		name: 'MainDashboard',
		components: {
			Toggle,
			CategoryBlock,
			SubCategoryBlock,
			KPIBlock,
			LineChart,
		},
		data() {
			return {
				today: new Date(),
				isLoading: false,
				filterMinDate: new Date(1),
				filterStartDate: null,
				filterEndDate: null,
				dashboardData: null,
				// includeOtherRoles: false,
				showUserActivityByMonth: true,
			}
		},
		computed: {
			...mapState('Auth', [ 'userInfo' ]),
			isSuperAdmin() {
				return (this.userInfo && this.userInfo.role == 'superadmin')
			},
			isUserManager() {
				return (this.userInfo && this.userInfo.role == 'customer_manager')
			},
			managedCustomer() {
				if (!this.isUserManager) {
					return null
				}

				return (this.userInfo && this.userInfo.managed_groups?.[0]?.customer) || null
			},
		},
		watch: {
			// async includeOtherRoles(includeOtherRoles) {
			// 	this.isLoading = true

			// 	// Load tracking data
			// 	let payload = null

			// 	if (includeOtherRoles) {
			// 		payload = { roles: ['user', 'superadmin', 'customer_manager'] }
			// 	}

			// 	this.dashboardData = await trackingReport.loadData(this.$store, template, payload)

			// 	this.isLoading = false
			// },
			filterMinDate(newDate) {
				if (newDate > this.filterStartDate) {
					this.filterStartDate = newDate
				}

				if (newDate > this.filterEndDate) {
					this.filterEndDate = newDate
				}
			},
			filterStartDate(newDate) {
				if (newDate > this.filterEndDate) {
					this.filterEndDate = newDate
				}

				// this.loadData(this)
			},
			// filterEndDate() {
			// 	this.loadData(this)
			// },
		},
		mounted() {
			this.loadData(this)
		},
		methods: {
			onApplyFilter() {
				this.loadData(this)
			},
			onResetFilter() {
				this.filterStartDate = null
				this.filterEndDate = null
				this.onApplyFilter()
			},
			loadData: debounce(async (self) => {
				self.isLoading = true

				// Load tracking data
				let payload = null

				// if (self.includeOtherRoles) {
				// 	payload = { roles: ['user', 'superadmin', 'customer_manager'] }
				// }

				if (self.filterStartDate || self.filterEndDate) {
					payload = {
						startDate: (self.filterStartDate ? new Date(self.filterStartDate) : self.filterMinDate),
						endDate: (self.filterEndDate ? new Date(self.filterEndDate) : self.today),
					}
				}

				self.dashboardData = await trackingReport.loadData(self.$store, template, payload)

				self.isLoading = false
			}, 250),
			async exportReport() {
				this.isLoading = true

				try {
					await trackingReport.export('My_Afpols_QUIZ', this.$store, template, null, this.dashboardData)
				} catch (err) {
					// console.error(err)
				}

				this.isLoading = false
			}
		}
	}
</script>

<style lang="scss">
	.dashboard-main {
		@apply max-w-screen-lg mx-auto;

		&-kpis {
			@apply flex flex-wrap justify-between;
		}

		.dashboard-category {
			@apply w-full mb-5;

			.dashboard-kpi {
				@apply flex-1 mx-4;

				&:first-child {
					@apply ml-0;
				}

				&:last-child {
					@apply mr-0;
				}
			}
		}

		&-active-user {
			& > p {
				display: inline-block;
			}
		}

		&-finish-by-job {
			@apply flex;
			padding-left: 100px;

			.dashboard-chart {
				@apply pr-8 mb-3;
			}

			& > div:first-child {
				margin-left: -100px;

				p.ct-label {
					margin-left: 100px;
				}
			}

			p.ct-label {
				@apply text-dashboard-blue-dark;

				font-style: italic;
			}
		}
	}
</style>