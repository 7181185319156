import moment from 'moment'

import utils from "@/utils/trackingReportTemplate"

export default {
	name: 'Global',
	content: [
		{ text: `Rapport  My Afpols QUIZ - date - ${moment(new Date()).locale('fr').format('L')}` },
		{ text: 'Rapport GLOBAL' },
		{},
		{
			text: 'Base de contenus',
			textStyle: { font: { bold: true } },
		},
		{
			text: 'Nombre de parcours',
			value: (data) => data.contentData.course_aggregate.aggregate.count,
		},
		{
			text: 'Nombre de questions',
			value: (data) => data.contentData.question_aggregate.aggregate.count,
		},
		{},
		{},
		{
			text: 'Données d\'inscription',
			textStyle: { font: { bold: true } },
		},
		{
			text: 'Nombre d\'inscrits',
			value: (data) => data.users.length,
		},
		{
			text: 'Nombre de promos',
			value: (data) => data.groups.length,
		},
		{},
		{
			text: 'Données de connexion',
			textStyle: { font: { bold: true } },
		},
		{
			text: 'Nb d\'actifs',
			value: (data) => utils.countMatch(data.confirmedUsers, (user) => {
				return (user.state === 'active')
			}),
		},
		{
			text: 'Durée de connexion totale',
			value: (data) => utils.sumField(data.confirmedUsers, 'activeTimeData', 'sum'),
			type: 'duration',
		},
		// {
		// 	text: 'Nb de téléchargement de l\'appli',
		// 	value: (data) => 0,
		// },
		{
			text: 'Nb de connexion sur l\'appli',
			value: (data) => utils.sumValues(data.confirmedUsers, (user) => {
				return user.activeTimeData.times.length
			}),
		},
		{
			text: 'Taux de compte confirmé',
			value: (data) => data.confirmedUsers.length / (data.users.length || 1),
			type: 'percentage',
		},
		{
			text: 'Durée moyenne de connexion par utilisateur',
			value: (data) => utils.averageField(data.confirmedUsers, 'activeTimeData', 'average', true),
			type: 'duration',
		},
		{},
		{
			text: 'Données pédagogiques',
			textStyle: { font: { bold: true } },
		},
		// {
		// 	text: 'Taux d\'assiduité moyen',
		// 	value: (data) => data.confirmedUsers.length,
		// 	type: 'percentage',
		// },
		{
			text: 'Taux de réussite moyen',
			value: (data) => utils.averageField(data.confirmedUsers, 'successRate', null, true),
			type: 'percentage',
		},
		{
			text: 'Taux de complétion moyen',
			value: (data) => {
				const userProgressList = data.confirmedUsers.reduce((list, user) => {
					user.groups.forEach((userGroup) => {
						if (user.progressPerGroup[userGroup.id]) {
							list.push(user.progressPerGroup[userGroup.id])
						}
					})
					return list
				}, [])

				// Make an averrage of user progress
				return utils.averageValues(userProgressList, null, true)
			},
			type: 'percentage',
		},
	]
}