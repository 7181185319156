<template>
	<div class="true-false">
		<Draggable v-model="questions" handle=".handle" v-bind="dragOptions">
			<div class="" v-for="(question, i) in questions" :key="i">
				<QuestionSimple
					:question="question"
					:number="i"
					class="my-5"
					@open-panel="OpenPanel"
					@delete-question="deleteQuestion"
					@update-question="updateQuestion"
					:options="options"
					@remove-media="removeMedia"
				>
				</QuestionSimple>
			</div>
		</Draggable>
		<div
			@click="addQuestion"
			class="plus m-auto rounded-full w-12 h-12 flex justify-center items-center cursor-pointer text-xl "
		>
			+
		</div>
		<RightPanel ref="rightPanel" title="Choix de média">
            <Medias
            :select-only="true"
            :draggable-asset="true"
            @select-media="SelectMedia"
            ></Medias>
        </RightPanel>
	</div>
</template>
<script>
import Draggable from "vuedraggable"
import QuestionSimple from "@/components/Games/Question_simple"
import RightPanel from '@/components/RightPanel'
import Medias from '@/views/Medias'

export default {
	name: "QuizSimple",
	components: {
		QuestionSimple,
		Draggable,
		RightPanel,
		Medias
	},
	props: {
		game: {
			type: Object,
			required: true,
			default: null
		}
	},
	data() {
		return {
			options: {
				fixedAnswers: true,
				onlyOneAnswer: true
			}
		}
	},
	computed: {
		questions: {
			get() {
				if (!this.game) return []
				return JSON.parse(JSON.stringify(this.game.questions))
			},
			set(value) {
				this.$emit("update-all-questions", value)
			}
		},
		dragOptions() {
			return {
				animation: 200,
				group: "description",
				disabled: false,
				ghostClass: "ghost"
			}
		}
	},
	methods: {
		addQuestion() {
			let question = {
				answers: [
					{
						text: "Vrai",
						is_correct: false
					},
					{
						text: "Faux",
						is_correct: false
					}
				],
				text: "",
				correction: ""
			}
			this.$emit("add-questions", [question])
		},
		updateQuestion(i, data) {
			this.$emit("update-question", i, data)
		},
		deleteQuestion(i) {
			this.$emit("delete-questions", [i])
		},
		OpenPanel(index){
					this.currentQuestionIndex = index
					this.$refs['rightPanel'].show()
			},
			Medialoaded(){
					this.mediaLoaded = true
			},
			SelectMedia(asset) {
				this.questions[this.currentQuestionIndex].media_id = asset.id
				this.questions = [...this.questions]
				this.$refs['rightPanel'].hide()

			},
			removeMedia(index) {
				this.questions[index].media_id = null
				this.questions = [...this.questions]
			}
	}
}
</script>

<style lang="scss"></style>
