import utils from "@/utils/trackingReportTemplate"

const userRoleBySlug = {
	user: 'Stagiaire',
	superadmin: 'SuperAdmin',
	afpols: 'Gestionnaire',
	instructor: 'Formateur',
}

export default {
	name: 'Utilisateurs',
	content: [
		{
			table: (data) => {
				return data.users.reduce((list, user) => {
					user.groups.forEach((group) => {
						list.push({
							user,
							group: data.groupsById[group.id],
						})
					})
					return list
				}, [])
			},
			headers: [
				'Nom',
				'Prénom',
				'Email',
				'Promotion associée',
				'Parcours associé',
				'Role',
				'Type',
				'Actif/Inactif',
				'Mail d\'invitation le',
				'Nb de connexion',
				'Première connexion le',
				'Dernière connexion le',
				// 'A téléchargé l\'appli',
				'Durée totale de connexion',
				'Temps moyen passé par connexion',
				// 'Taux d\'assiduité',
				// 'Taux de complétion',
				'Taux de réussite',
			],
			row: ({ user, group }) => {
				const timeData = user.activeTimeData

				return [
					{ value: user.name },
					{ value: user.first_name },
					{ value: user.email },
					{ value: group.title },
					{ value: (group.course && group.course.name) || '-' },
					{ value: userRoleBySlug[user.role] || '-' },
					{ value: (user.salesforce_id ? 'SOGI' : 'LMS') },
					{ value: (user.state === 'active' ? 'Actif' : 'Inactif') },
					{ value: (user.accessMailDate && user.accessMailDate.getTime()) || 0, type: 'date' },
					{ value: (user.timesPerGroup[group.id] && user.timesPerGroup[group.id].length) || '0' },
					{ value: timeData.firstSessionTimestamp, type: 'date' },
					{ value: timeData.lastSessionTimestamp, type: 'date' },
					// { value: 'A téléchargé l\'appli' },
					{ value: utils.sumField(user.timesPerGroup[group.id] || [], 'duration'), type: 'duration' },
					{ value: utils.averageField(user.timesPerGroup[group.id] || [], 'duration'), type: 'duration' },
					// { value: 'Taux d\'assiduité' },
					// { value: 'Taux de complétion' },
					{ value: user.successRatePerGroup[group.id], type: 'percentage' },
				]
			},
		}
	]
}