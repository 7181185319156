<template>
  <div class="game" v-if="game">
    <!-- Header -->
    <div class="game-header">
      <div class="buttons flex flex-row justify-between">
        <router-link class="twn-button text-xs" :to="{ name: 'games-list' }">
          Retour
        </router-link>
        <div class="buttons-right">
          <button
            @click="deleteGame"
            class="twn-button text-xs danger mr-4"
            type="button"
            :disabled="!itemID || hasPendingStoreRequest"
          >
            Supprimer le minijeu
          </button>

          <button
            @click="duplicateGame"
            class="twn-button text-xs mr-4"
            type="button"
            :disabled="!itemID || hasPendingStoreRequest"
          >
            Dupliquer
          </button>

          <button
            v-if="canPreviewGame"
            @click="previewGame"
            :disabled="!itemID || hasPendingStoreRequest"
            class="twn-button text-xs mr-4"
            type="button"
          >
            Aperçu
          </button>

          <button
            @click="saveGame"
            class="twn-button text-xs"
            type="button"
            :disabled="hasPendingStoreRequest"
          >
            Enregistrer le minijeu
          </button>
        </div>
      </div>
    </div>

    <!-- Game common fields (identifier, title and instructions) -->
    <div class="form-group mt-12 mb-5">
      <label for="game-name" class="uppercase font-principal-medium text-sm"
        >Identifiant</label
      >
      <b-form-input
        v-model="game.name"
        :state="!nameTaken"
        type="text"
        id="game-name"
        class="w-full"
      ></b-form-input>
    </div>

    <div class="form-group mt-0 mb-5">
      <label for="game-title" class="uppercase font-principal-bold text-sm"
        >Titre du {{ game.type ? game.type.name : "" }}</label
      >
      <b-form-input
        v-model="game.title"
        type="text"
        id="game-title"
        class="w-full"
      ></b-form-input>
    </div>
    <div class="form-group mt-0 mb-10">
          <label
            for="game-instruction"
            class="uppercase font-principal-medium text-sm"
            >Consigne</label
          >
          <b-form-textarea
            v-model="game.instruction"
            type="text"
            id="game-instruction"
            class="w-full"
          ></b-form-textarea>
          <label
            v-if="gameTypeSlug === 'reorder' || gameTypeSlug === 'gap_fill'"
            for="game-enonce"
            class="uppercase font-principal-medium text-sm"
            >Enoncé</label
          >
          <b-form-textarea
            v-if="gameTypeSlug === 'reorder' || gameTypeSlug === 'gap_fill'"
            v-model="game.enonce"
            type="text"
            id="game-enonce"
            class="w-full"
            maxlength="100"
          ></b-form-textarea>
        </div>
        <div class="d-flex justify-between mb-4">
          <button v-if="gameTypeSlug !== 'true_false' && gameTypeSlug !== 'quiz_simple'" type="button" class="twn-button h-12" @click="AddDefaultMedia">
            Ajouter un media au jeu
          </button>
          <div class="flex flex-col w-3/5">
            <div v-for="(media, i) in game.medias" :key="i" >
              <div
                class="flex flex-row mb-2"
                :class="{ 'border-b': i !== game.medias.length - 1 }"
              >
                <div class="w-4/5">
                  <div class="mt-0 mb-10" v-if="!media.media_id">
                    <div
                      class="drop-zone p-5 w-full border-dashed border-2"
                      @dragover="allowDrop"
                      @drop.prevent.stop="DropAssetInGame($event, i)"
                      @click="ShowRightPanel(i)"
                    >
                      <div class="m-auto w-1/3 break-words text-center text-xs">
                        Ajouter un media depuis la bibliothèque de média
                      </div>
                    </div>
                  </div>
                  <div v-else-if="assets[media.media_id]">
                    <div class="preview flex flex-start items-center">
                      <img
                        v-if="assets[media.media_id].thumbnail_url"
                        class="img_thumbnail"
                        :src="assets[media.media_id].thumbnail_url"
                        alt=""
                      />
                      {{ assets[media.media_id].name }}
                    </div>
                  </div>
                </div>
                <div class="w-1/5 flex justify-center items-center">
                  <div
                    class="text-center cursor-pointer pt-1"
                    @click="DeleteMedia(i)"
                  >
                    <img class="w-6 h-6" src="@/assets/images/NODE_CROSS.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group mt-1 mb-4 text-center">
          <label for="show-answers">Cacher les réponses: </label>
          <label class="switch ml-2">
            <input type="checkbox" v-model="game.hideAnswers" />
            <span class="slider round"></span>
          </label>
          <span v-if="!game.hideAnswers"> (affichées)</span>
					<span v-else> (cachées)</span>
        </div>

    <!-- <button
      class="twn-button w-1/4 block m-auto font-bold my-8 danger"
      type="button"
      @click="ShowParameterPanel"
    >
      <img class="inline" src="@/assets/images/SETTINGS.svg" alt="">
      Ouvrir les parametres globaux
      <img class="inline" src="@/assets/images/SETTINGS.svg" alt="">
    </button> -->

    <!-- Type specific game editing component -->
    <component
      :is="gameComponent"
      :game="game"
      @add-headers="addHeaders"
      @add-questions="addQuestions"
      @update-exec-time="updateExecuteTime"
      @update-prep-time="updatePrepareTime"
      @update-header="updateHeader"
      @update-question="updateQuestion"
      @delete-headers="deleteHeaders"
      @delete-questions="deleteQuestions"
      @update-all-questions="updateAllQuestions"
      @update-correction="updateCorrection"
      @update-game-media="updateGameMedia"
    />

    <!-- Footer -->
    <div
      class="mt-20 w-full md:w-1/2 lg:w-1/3 mb-4"
      v-if="modifRecord.length > 0"
    >
      <div
        class="cursor-pointer select-none"
        @click="modifRecordOpen = !modifRecordOpen"
      >
        <svg
          class="inline-block mr-2"
          :class="{ 'transform rotate-180': modifRecordOpen }"
          height="15px"
          id="Layer_1"
          version="1.1"
          viewBox="0 0 26 15"
          width="26px"
          xml:space="preserve"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <polygon
            fill="#231F20"
            points="23.303,-0.002 12.467,10.834 1.63,-0.002 -0.454,2.082 12.467,15.002 14.551,12.918 25.387,2.082   "
          />
        </svg>
        <span v-if="modifRecordOpen">Cacher</span>
        <span v-else>Afficher</span> l'historique des modifications
      </div>

      <div v-if="modifRecordOpen" class="mt-4">
        <div
          class="my-4 flex flex-row text-sm justify-between w-full pr-4"
          v-for="record in modifRecord"
          :key="record.created_at"
        >
          <div>{{ formatDate(record.created_at) }}</div>
          <div>{{ record.user.first_name }} {{ record.user.name }}</div>
        </div>
      </div>
    </div>

    <!-- Modals -->
    <b-modal
      ref="delete-modal-game"
      class="bootstrap"
      centered
      hide-footer
      id="delete-modal-game"
      hide-header
    >
      <div class="d-block text-center my-6 uppercase font-semibold">
        <h3>Confirmer la suppression</h3>
      </div>
      <div class="flex flex-row justify-evenly items-center">
        <button
          type="button"
          class="mt-4 twn-button"
          @click="$bvModal.hide('delete-modal-game')"
        >
          Retour
        </button>
        <button
          type="button"
          class="mt-4 twn-button danger"
          @click="confirmDelete"
        >
          Supprimer
        </button>
      </div>
    </b-modal>

    <RightPanel ref="pdfPanel" title="Choix de média">
      <Medias
        @select-media="AssignMediaToGame"
        :selectOnly="true"
        :draggableAsset="true"
      >
      </Medias>
    </RightPanel>
  </div>
</template>

<script>
import dispatchStoreRequest from "@/mixins/dispatchStoreRequest";
import TrueFalse from "@/components/common/game/TrueFalse";
import DragDrop from "@/components/common/game/DragDropLight";
import QuizSimple from "@/components/common/game/QuizSimple";
import QuizTable from "@/components/common/game/QuizTable";
import CellByCell from "@/components/common/game/CellByCell";
import Reorder from "@/components/common/game/Reorder";
// import Memory from "@/components/common/game/Memory";
import Vocabulary from "@/components/common/game/Vocabulary";
import GapFill from "@/components/common/game/GapFill";
import Medias from "@/views/Medias";
import RightPanel from "@/components/RightPanel";
import MiddlePanel from "@/components/MiddlePanel";
import { mapState } from "vuex";
import moment from "moment";

// import gql from "graphql-tag"

export default {
  name: "GameCreation",
  mixins: [dispatchStoreRequest],
  props: {
    gameTypeSlug: {
      type: String,
      required: true,
      default: null,
    },
    itemID: {
      type: String,
      required: false,
      default: null,
    },
  },
  components: {
    TrueFalse,
    DragDrop,
    QuizSimple,
    QuizTable,
    CellByCell,
    Reorder,
    Vocabulary,
    GapFill,
    RightPanel,
    MiddlePanel,
    Medias,
    // Memory,
  },
  data() {
    return {
      modifRecord: [],
      modifRecordOpen: false,
      currentComponent: null,
      game: null,
      nameTaken: null,
      type: null,
      selectedMedia: null,
      tabOptions: [
        {
          title: "Instructions",
          slot: "instructions",
        },
        // {
        //   title: 'Médias',
        //   slot: 'medias'
        // },
        // {
        // 	title: 'Score et Jauges',
        // 	slot: 'score-jauge'
        // },
      ],
    };
  },
  async created() {
    await this.dispatchStoreRequest("Games/getTypeList");
    await this.dispatchStoreRequest('Media/getList')

    this.type = this.$store.state.Games.type_list.find((type) => {
      return type.slug == this.gameTypeSlug;
    });
    if (!this.type) return;

    await this.dispatchStoreRequest("Games/getJaugeList");

    if (!this.itemID) {
      // Create a new game of the specified game type

      this.game = {
        name: "",
        title: "Nouveau jeu",
        instruction: "",
        correction: "",
        media_id: null,
        headers: [],
        type: this.type,
        medias: [],
        scores: [],
        questions: [],
      };

      switch (this.type.slug) {
        case 'quiz_simple':
          this.game.instruction = "Répondez à la question\n(plusieurs réponses possibles)"
          break;
        case 'reorder':
          this.game.instruction = "Déplacez les éléments de la liste afin de les remettre dans l'ordre"
          break;
        case 'true_false':
          this.game.instruction = "Cochez la bonne réponse"
          break;
        case 'drag_drop':
          this.game.instruction = "Associez chaque terme à la proposition correspondante\n(cliquer sur un terme puis sur son emplacement de destination)"
          break;
        case 'gap_fill':
          this.game.instruction = "Complétez la phrase suivante\n(cliquer sur un terme puis sur son emplacement de destination)"
          break;

        default:
          break;
      }
    }
  },
  computed: {
    ...mapState("Assets", {
      assets(state) {
        if (!state.assets || state.assets.length <= 0) return {};

        return state.assets.reduce((dict, asset) => {
          dict[asset.id] = asset;

          return dict;
        }, {});
      },
    }),
    // ...mapState("Games", {
    // 	jauges(state) {if (!state.jauges || state.jauges.length <= 0) return {}
    // 		return state.jauges.reduce((dict, jauge) => {
    // 			dict[jauge.id] = jauge

    // 			return dict
    // 		}, {})
    // 	}
    // }),
    ...mapState("Games", ["jauges"]),
    gameComponent() {
      if (this.game) {
        switch (this.gameTypeSlug) {
          case "true_false":
            return TrueFalse;

          case "drag_drop":
            return DragDrop;

          case "quiz_simple":
            return QuizSimple;

          case "quiz_table":
            return QuizTable;

          case "cell_by_cell":
            return CellByCell;

          case "reorder":
            return Reorder;

          case "vocabulary":
            return Vocabulary;

          case "gap_fill":
            return GapFill;

          // case "memory":
          //   return Memory;
        }
      }

      return null;
    },
    canPreviewGame() {
      return process.env.VUE_APP_FRONT_URL ? true : false;
    },
  },
  watch: {
    itemID: {
      async handler(id) {
        // Load current game data, if needed
        if (id) {
          // Get game from store
          // todo: handle invalid uuid response
          await this.dispatchStoreRequest("Games/getByID", id);

          // Create a deep local copy of the store data
          this.game = JSON.parse(
            JSON.stringify(this.$store.state.Games.items[id])
          );
          this.modifRecord = this.game.contentLogs;
          delete this.game.contentLogs;

          // todo: common/cleaner system
          document.title = this.game.name + " " + this.game.title;
        }
      },
      immediate: true,
    },
  },
  methods: {
    formatDate(dt) {
      return moment(new Date(dt)).locale("fr").format("LLLL");
    },
    async duplicateGame() {
      delete this.game.id;
      this.$router.push({
        name: "game-edit",
      });
    },
    async saveGame() {
      if(!this.game.name || !this.game.instruction) {
        this.$bvToast.toast("Identifiant et Consigne", {
          title: `Veuillez remplir les champs obligatoire !`,
        });
        return
      }
        this.nameTaken = null;
        const response = await this.dispatchStoreRequest(
          "Games/save",
          this.game
        );
        if (response.error) {
          this.nameTaken = true;
          return;
        }
        this.$bvToast.toast("Vos modifications ont bien été enregistrés !", {
          title: `Succès !`,
        });
        if (!this.itemID && response.id) {
          this.$router.push({
            name: "game-edit",
            params: {
              itemID: response.id,
            },
          });
        }
    },
    deleteGame() {
      if (this.itemID) {
        this.$refs["delete-modal-game"].show();
      }
    },
    async confirmDelete() {
      await this.dispatchStoreRequest("Games/deleteGameByID", this.game.id);
      this.$router.push({ name: "games-list" });
    },
    previewGame() {
      window.open(
        process.env.VUE_APP_FRONT_URL + "game/preview/" + this.itemID,
        "_blank"
      );
    },
    addHeaders(headers) {
      for (var i = 0; i < headers.length; i++) {
        this.game.headers.push(headers[i]);
      }
    },
    addQuestions(questions) {
      for (var i = 0; i < questions.length; i++) {
        this.game.questions.push(questions[i]);
      }
    },
    updateHeader(index, headerData) {
      const dataKeys = Object.keys(headerData);

      for (var i = 0; i < dataKeys.length; i++) {
        this.game.headers[index][dataKeys[i]] = headerData[dataKeys[i]];
      }
    },
    updateQuestion(index, questionData) {
      const dataKeys = Object.keys(questionData);

      for (var i = 0; i < dataKeys.length; i++) {
        this.game.questions[index][dataKeys[i]] = questionData[dataKeys[i]];
      }
    },
    updateAllQuestions(questions) {
      this.game.questions = questions;
    },
    updateCorrection(correction) {
      this.game.correction = correction;
    },
    updateGameMedia(media_id) {
      this.game.media_id = media_id;
    },
    deleteHeaders(headerIndexes) {
      for (var i = 0; i < headerIndexes.length; i++) {
        this.game.headers.splice(headerIndexes[i], 1);
      }
    },
    deleteQuestions(questionIndexes) {
      let sorted = questionIndexes.sort((a, b) => a - b);
      for (var i = sorted.length - 1; i >= 0; i--) {
        this.game.questions.splice(sorted[i], 1);
      }
    },
    updatePrepareTime(time) {
      this.game.prepareTime = time;
    },
    updateExecuteTime(time) {
      this.game.executeTime = time;
    },
    DropAssetInGame(e, i) {
      if (e.dataTransfer) {
        const assetJSON = e.dataTransfer.getData("application/json");
        const asset = assetJSON ? JSON.parse(assetJSON) : null;
        if (
          asset.type.slug === "file_pdf" ||
          asset.type.slug === "file_image"
        ) {
          this.game.medias[i].media_id = asset.id;
          this.$refs["pdfPanel"].hide();
        }
      }
    },
    allowDrop: function (event) {
      event.preventDefault();
    },
    ShowRightPanel(i) {
      if (i !== undefined && i !== null) {
        this.selectedMedia = i;
      }
      this.$refs["pdfPanel"].show();
    },
    AssignMediaToGame(asset) {
      if (
        asset &&
        this.selectedMedia !== undefined &&
        this.selectedMedia !== null
      ) {
        this.game.medias[this.selectedMedia].media_id = asset.id;
      }
    },
    DeleteGamePDF(id) {
      let index = this.game.medias.indexOf(id);
      this.game.medias.splice(index, 1);
    },
    SelectJauge(val) {
      this.game.jauge_id = val;
    },
    AddDefaultScore() {
      let score = {
        score: 0,
        jauge_id: null,
      };
      this.game.scores.push(score);
    },
    DeleteJauge(i) {
      this.game.scores.splice(i, 1);
    },
    DeleteMedia(i) {
      this.selectedMedia = null;
      this.game.medias.splice(i, 1);
    },
    AddDefaultMedia() {
      let media = {
        media_id: null,
      };
      this.game.medias.push(media);
      this.selectedMedia = this.game.medias.length - 1;
    },
    ShowParameterPanel() {
      this.$refs["gameOptions"].show();
    },
  },
};
</script>
<style lang="scss" scoped>
.game {
  .jauges-score,
  .pdf {
    width: 40%;
  }
}
</style>
<style lang="scss">
.preview {
  .pdf_thumbnail {
    @apply w-8 mr-4;
  }
  .img_thumbnail {
    @apply w-16 mr-4;
  }
}
</style>