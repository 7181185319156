<template>
	<div class="dashboard dashboard-course">
		<!-- Header -->
		<!-- <div class="flex items-center justify-end">
			<button type="button" class="mb-4 twn-button" @click="exportReport" v-bind:disabled="isLoading">
				<span v-if="!isLoading">
					Exporter le rapport
				</span>
				<span v-else>
					Chargement...
				</span>
			</button>
		</div> -->

		<!-- Dashboard -->
		<div>
			<!-- Users count and state data -->
			<CategoryBlock title="Statistiques du parcours">
				<!-- KPIs -->
				<div class="inline-block w-full align-top">
					<div class="dashboard-course-kpis">
						<KPIBlock
							:isLoading="isLoading"
							title="Stagiaires actifs"
							:number="dashboardData && dashboardData.usersDashboardData.active"
							help="Utilisateurs qui ce sont logués au moins une fois"
							backgroundColor="bg-dashboard-green"
						/>
						<KPIBlock
							:isLoading="isLoading"
							title="Nombre de sessions"
							:number="dashboardData && dashboardData.userActivityDashboardData.activeTime.count"
						/>
						<KPIBlock
							:isLoading="isLoading"
							title="Durée totale de connexion"
							:time="dashboardData && dashboardData.userActivityDashboardData.activeTime.sum"
						/>
						<KPIBlock
							:isLoading="isLoading"
							title="Complétion moyenne"
							:percentage="dashboardData && dashboardData.userProgressData.average"
							help="Taux de réalisation des rappels moyen"
						/>
						<KPIBlock
							:isLoading="isLoading"
							title="Réussite moyenne"
							:percentage="dashboardData && dashboardData.userSuccessRateData.average"
							help="Score moyen obtenu par tous les stagiaires sur tous les rappels du parcours"
						/>
					</div>
				</div>

				<!-- Sequence KPIs table -->
				<SubCategoryBlock class="dashboard-course-sequences">
					<table>
						<thead>
							<tr>
								<th>Rappel</th>
								<th>J+</th>
								<th>Sessions</th>
								<th>Session moy.</th>
								<th>Moy. 1er score</th>
								<th>Moy. meill. score</th>
							</tr>
						</thead>
						<tbody>
							<tr
								v-if="isLoading || !dashboardData || !dashboardData.course"
								class="animate-dashboard-pulse"
							>
								<td>-</td>
								<td>-</td>
								<td>-</td>
								<td>-</td>
								<td>-</td>
								<td>-</td>
							</tr>
							<tr
								v-for="(sequence, key, index) in (!isLoading && dashboardData && dashboardData.course && dashboardData.course.sequenceById || [])"
								:key="sequence.id"
							>
								<td>
									{{ index + 1 }}
								</td>
								<td>
									{{ sequence.offsetDays }}
								</td>
								<td>
									{{ dashboardData.sequenceData[sequence.id].activeTime.count }}
								</td>
								<td>
									{{ formatTime(dashboardData.sequenceData[sequence.id].activeTime.average) }}
								</td>
								<td>
									{{ formatPercentage(dashboardData.sequenceData[sequence.id].firstSuccessRate.average) }}
								</td>
								<td>
									{{ formatPercentage(dashboardData.sequenceData[sequence.id].successRate.average) }}
								</td>
							</tr>
						</tbody>
					</table>
				</SubCategoryBlock>
			</CategoryBlock>
		</div>
	</div>
</template>

<script>
	import { mapState } from 'vuex'

	import { debounce } from '@/utils/utils'
	import trackingReport from '@/utils/trackingReport'

	import template from '@/constants/courseTrackingReportTemplate'

	import CategoryBlock from './block/Category'
	import SubCategoryBlock from './block/SubCategory'
	import KPIBlock from './block/KPI'

	const oneHour = (1 * 60 * 60 * 1000)

	function leadingZero(number, extended = false) {
		return (number < 10 && (extended || number > 0) ? '0' + number : number)
	}

	function getTimeParts(time) {
		let remainingTime = (time / oneHour)
		const hours = Math.floor(remainingTime)

		remainingTime = (remainingTime - hours) * 60
		const minutes = Math.floor(remainingTime)

		remainingTime = (remainingTime - minutes) * 60
		const seconds = Math.round(remainingTime)

		return {
			hours,
			minutes,
			seconds,
		}
	}

	export default {
		name: 'CourseDashboard',
		components: {
			CategoryBlock,
			SubCategoryBlock,
			KPIBlock,
		},
		props: {
			id: {
				type: String,
				required: true
			},
		},
		data() {
			return {
				today: new Date(),
				isLoading: false,
				dashboardData: null,
			}
		},
		computed: {
			...mapState('Auth', [ 'userInfo' ]),
			isSuperAdmin() {
				return (this.userInfo && this.userInfo.role == 'superadmin')
			},
			isUserManager() {
				return (this.userInfo && this.userInfo.role == 'customer_manager')
			},
			managedCustomer() {
				if (!this.isUserManager) {
					return null
				}

				return (this.userInfo && this.userInfo.managed_groups?.[0]?.customer) || null
			},
		},
		mounted() {
			this.loadData(this)
		},
		methods: {
			onApplyFilter() {
				this.loadData(this)
			},
			loadData: debounce(async (self) => {
				self.isLoading = true

				// Load tracking data
				let payload = {
					id: self.id,
				}

				self.dashboardData = await trackingReport.loadData(self.$store, template, payload)

				self.isLoading = false
			}, 250),
			async exportReport() {
				this.isLoading = true

				try {
					await trackingReport.export('Parcours_', this.$store, template, null, this.dashboardData)
				} catch (err) {
					// console.error(err)
				}

				this.isLoading = false
			},
			formatTime(time) {
				if (time != null) {
					const timeParts = getTimeParts(time)

					return (timeParts.hours > 0 ? timeParts.hours + 'h ' : '') + (timeParts.minutes > 0 || timeParts.seconds <= 0 ? leadingZero(timeParts.minutes) + ' min ' : '') + (timeParts.hours <= 0 && timeParts.seconds > 0 ? leadingZero(timeParts.seconds) + 's' :  '')
				}

				return '-'
			},
			formatPercentage(percentage) {
				if (percentage != null) {
					const value = (percentage * 100)

					if (value > 0 && value < 1) {
						return value.toFixed(2) + ' %'
					}

					return Math.round(value) + ' %'
				}

				return '-'
			},
		}
	}
</script>

<style lang="scss">
	.dashboard-course {
		@apply max-w-screen-lg mx-auto;

		&-kpis {
			@apply flex flex-wrap justify-between;
		}

		.dashboard-category {
			@apply w-full mb-5;

			.dashboard-kpi {
				@apply flex-1 mx-4;

				&:first-child {
					@apply ml-0;
				}

				&:last-child {
					@apply mr-0;
				}
			}
		}

		&-active-user {
			& > p {
				display: inline-block;
			}
		}

		&-finish-by-job {
			@apply flex;
			padding-left: 100px;

			.dashboard-chart {
				@apply pr-8 mb-3;
			}

			& > div:first-child {
				margin-left: -100px;

				p.ct-label {
					margin-left: 100px;
				}
			}

			p.ct-label {
				@apply text-dashboard-blue-dark;

				font-style: italic;
			}
		}

		table {
            @apply w-full;

            tr {
                th, td {
                    @apply pl-5;
                }
            }

            th {
                @apply bg-dashboard-blue-dark text-white pt-3 pb-2;
            }

            td {
                @apply pt-2 pb-1;
                border-bottom: 1px solid #EEEEEE;

                &:first-child {
                    @apply font-principal-bold;
                }
            }
        }
	}
</style>